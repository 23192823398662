import { Carousel, Col, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import Gallery, { PhotoProps } from 'react-photo-gallery';
import '../../../styles/modules/photo-gallery.less';

interface SliderBlock {
  image: { url: string };
  title: string;
  button: { text: string; url: string };
  subtitle: string;
}

export const PhotoGalleryModule = (section: PageSection) => {
  const generateRandomInteger = (min: number, max: number) => {
    return Math.floor(min + Math.random() * (max + 1 - min));
  };

  const photos: Array<PhotoProps> = section.settings.images.map((image: any, index: number) => {
    const photo = {
      key: index.toString(),
      width: generateRandomInteger(2, 5),
      height: 3,
      src: process.env.REACT_APP_API_URL + image.image.url
    };
    return photo;
  });

  const slide1 = Math.ceil(photos.length / 2);

  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-photo-gallery'}
    >
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }}>
        {section.settings.title && (
          <>
            <h1>{section.settings.title[section.language]}</h1>
          </>
        )}
        {section.settings.description && (
          <p dangerouslySetInnerHTML={{ __html: section.settings.description[section.language] }} />
        )}
        {section.settings.type === 'one_page' ? (
          <Gallery photos={photos} targetRowHeight={300} />
        ) : (
          <Carousel autoplay={true}>
            <div>
              <Gallery photos={photos.slice(0, slide1)} targetRowHeight={450} />
            </div>
            <div>
              <Gallery photos={photos.slice(slide1, photos.length)} targetRowHeight={450} />
            </div>
          </Carousel>
        )}
      </Col>
    </Row>
  );
};
