import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import '../../../styles/modules/newsletter-signup.less';
import Swal from 'sweetalert2';
import { FormInstance } from 'antd/lib/form';
import { useWindowWidth } from '../../../helpers/use-window-width.helper';
import { Parallax } from 'react-parallax';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
}

const NewsletterSignUpModule = (props: Props) => {
  const formRef = React.createRef<FormInstance>();
  const windowWidth = useWindowWidth();

  const section = props.section;
  const styles = section.settings.background_color
    ? { backgroundColor: section.settings.background_color }
    : {};

  let bgClass: any = '';
  if (section.settings.background_image) {
    bgClass = ' has-bg-image';
  }

  const { t } = useTranslation();

  const handleSubmit = () => {
    const t: any = props.i18n.t.bind(props.i18n);
    if (formRef && formRef.current) {
      const formFields = formRef.current;
      const data = {
        ...formFields.getFieldsValue(),
        language: props.app.language.code
      };
      formFields.validateFields().then((res) => {
        new LoopBack()
          .post('/newsletter', data)
          .then((res) => {
            Swal.fire({
              title: t('app:footer.popup.signup.success.title'),
              html: t('app:footer.popup.signup.success.description'),
              icon: 'success'
            });
          })
          .catch((err) => {
            Swal.fire({
              title: t('app:footer.popup.signup.warning.title'),
              html: t('app:footer.popup.signup.warning.description'),
              icon: 'warning'
            });
          });
      });
    }
  };

  const getRow = () => {
    return (



      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-newsletter-signup${bgClass}`}
        style={styles}
      >
        <Col xs={24} sm={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
          <Row gutter={[30, 30]} justify={'center'}>
            <Col xs={24} lg={21} xl={12}>
              <h2>{section.settings.title[props.app.language.code]}</h2>
              <div
                id={'cms-module-newsletter-signup-description'}
                dangerouslySetInnerHTML={{
                  __html: section.settings.description[props.app.language.code]
                }}
              />
            </Col>
          </Row>
          <Row justify={'center'}>
            <Col xs={24} lg={21} xl={12}>
              <Form name='basic' layout={'vertical'} ref={formRef} onFinish={handleSubmit}>
                <Form.Item
                  style={windowWidth < 768 ? {} : {display: 'inline-block', width: 'calc(100% - 210px)', marginRight: 10}}
                  name={'email'}
                  rules={[
                    { required: true, message: t('pages:newsletter.form.email.warning') }
                  ]}
                >
                  {windowWidth < 768 ?
                    <Input
                        // style={{}}
                        size={'large'}
                        type={'text'}
                        placeholder={t('pages:newsletter.form.email.placeholder')}
                      />
                    :

                    // <Input.Group compact={true}>
                      <Input
                        // style={{ paddingRight: 10 }}
                        size={'large'}
                        type={'text'}
                        placeholder={t('pages:newsletter.form.email.placeholder')}
                      />

                  }

                </Form.Item>


                <Form.Item
                  style={windowWidth < 768 ? {} : {display: 'inline-block'}}
                >
                  <Button
                    style={windowWidth < 768 ? {} : {width: 200}}
                    block={windowWidth < 768}
                    size={'large'}
                    type='primary'
                    htmlType='submit'
                  >
                    <span>{t('pages:newsletter.form.button.submit.text')}</span>
                  </Button>
                </Form.Item>

              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {section.settings.background_image ? (
        <Parallax
          bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
          bgImageAlt=''
          strength={200}
        >
          {getRow()}
        </Parallax>
      ) : (
        getRow()
      )}
    </>
  );


}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect<any, any, any>(mapStateToProps)(withTranslation()(NewsletterSignUpModule));
