import React from 'react';
import { Button, Checkbox, Col, Drawer, Row, Slider } from 'antd';
import { Color, PageSection, Product, ProductType, Size } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { Link } from 'react-router-dom';
import { calculatePrice, formatPrice } from '../../../helpers/price.helper';
import { connect } from 'react-redux';
import { AuthState } from '../../../redux/states/user';
import Swal from 'sweetalert2';
import { addItemToWishList, removeItemFromWishList } from '../../../redux/actions/wishlist';
import {
  ArrowRightOutlined,
  FilterOutlined,
  HeartFilled,
  HeartOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { IWishListState } from '../../../redux/states/wishlist';
import { IAppState } from '../../../redux/states/app';
import { productSchema } from '../../../helpers/structured-data.helper';
import { prerenderIsReady } from '../../../helpers/prerender-ready.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import '../../../styles/products.less';
import { hideCategoriesBar, showCategoriesBar } from '../../../redux/actions/app';

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  wishlist: IWishListState;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
  showCategoriesBar: typeof showCategoriesBar;
  hideCategoriesBar: typeof hideCategoriesBar;
  section: PageSection;
}

interface State {
  products: Product[];
  colors: Color[];
  types: ProductType[];
  productTypes: number[];
  productSizes: number[];
  productColors: number[];
  sizesFilters: number[];
  colorFilters: number[];
  productTypeFilters: number[];
  priceRange: [number, number];
  highestPrice: number;
  loadingProducts: boolean;
  sizes: Size[];
  measurement_unit: string;
  filterDrawer: boolean;
  path: string;
}

class WebshopModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      colors: [],
      productSizes: [],
      productColors: [],
      productTypes: [],
      types: [],
      sizesFilters: [],
      colorFilters: [],
      productTypeFilters: [],
      priceRange: [0, -1],
      highestPrice: -1,
      loadingProducts: true,
      filterDrawer: false,
      sizes: [],
      path: window.location.pathname,
      measurement_unit: this.props.app.country
        ? this.props.app.country.preferred_measurement_unit
        : 'imperial'
    };

    window.scrollTo(0, 0);

    new LoopBack()
      .get('/product-types')
      .then((res) => {
        this.setState({ types: res });
      })
      .catch((err) => {
        this.setState({ types: [] });
      });

    new LoopBack()
      .get('/sizes')
      .then((res) => {
        this.setState({ sizes: res });
      })
      .catch((err) => {
        this.setState({ sizes: [] });
      });

    this.props.showCategoriesBar();
  }

  componentWillUnmount(): void {
    this.props.hideCategoriesBar();
  }

  initializeShop = () => {
    let filter: any = {
      where: {
        product_type_id: {
          and: [
            {
              inq: this.props.section?.settings.product_types
            },
            {
              inq: this.props.section?.settings.product_categories
            }
          ]
        },
        active: true
      }
    };

    this.getProducts(filter, true);
  };

  componentDidMount(): void {
    this.initializeShop();
    new LoopBack(this.props.auth.token).get('/colors').then((res) => {
      this.setState({ colors: res });
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (window.location.pathname !== this.state.path) {
      this.initializeShop();
      this.setState({ path: window.location.pathname });
    }
  }

  getProducts = (filter: any, init = false) => {
    new LoopBack()
      .get(`/products?filter=${JSON.stringify(filter)}`)
      .then((res) => {
        let sizes: number[] = [];
        let colors: number[] = [];
        let types: number[] = [];

        const highestPrice = Math.max.apply(
          Math,
          res.map((product: Product) => {
            sizes = [...product.sizes, ...sizes];
            colors = [product.color_id, ...colors];
            types = [product.product_type_id, ...types];
            return calculatePrice(product.price, this.props.app.currency, this.props.app.country);
          })
        );
        const roundUpHighestPrice = Math.pow(10, Math.ceil(Math.log10(highestPrice)));
        this.setState(
          {
            products: res,
            highestPrice: roundUpHighestPrice,
            loadingProducts: false,
            productColors: colors,
            productSizes: sizes,
            productTypes: types
          },
          () => {
            prerenderIsReady();
          }
        );
      })
      .catch((err) => {
        this.setState({ loadingProducts: false });
        prerenderIsReady();
      });
  };

  handleAddItemToWishListClick = (product: Product) => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    this.props.addItemToWishList(product);
    Swal.fire({
      title: t('products:detail.popup.wishlist.success.title'),
      html: t('products:detail.popup.wishlist.success.description', {
        text: `<strong>${product.name[this.props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  gtagView = (products: Product[]) => {
    try {
    // @ts-ignore
    window.gtag('event', 'view_item_list', {
      items: products.map((product, index) => {
        return [
          {
            id: product.id,
            name: `${product.name[this.props.app.language.code]}${
              product.subtitle ? ` - ${product.subtitle[this.props.app.language.code]}` : ''
            }`,
            list_name: 'Webshop',
            brand: settings.appName,
            category: product.product_type_id,
            variant: product.color_id,
            list_position: index + 1,
            price: product.price
          }
        ];
      })
    });
    } catch (e) {

    }
  };

  productIsInWishList = (product: Product) => {
    return this.props.wishlist.items.some((item) => item.id === product.id);
  };

  onSizesSelect = (values: any) => {
    this.setState({ sizesFilters: values });
  };

  onColorsSelect = (values: any) => {
    this.setState({ colorFilters: values });
  };

  onProductTypesSelect = (values: any) => {
    this.setState({ productTypeFilters: values });
  };

  onPriceRangeSelect = (values: any) => {
    this.setState({ priceRange: values });
  };

  isProductSoldOut = (product: Product) => {
    return product.manage_stock && product.stock <= 0;
  };

  getStockMessage = (product: Product) => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    if (product.manage_stock) {
      if (product.stock <= 0) {
        return <div className={'stock-message sold-out'}>{t('products:stock.out_of_stock')}</div>;
      } else if (product.stock === 1) {
        return <div className={'stock-message'}>{t('products:stock.one_left')}</div>;
      } else if (product.stock > 1 && product.stock < 10) {
        return (
          <div className={'stock-message'}>
            {t('products:stock.few_left', { text: product.stock })}
          </div>
        );
      }
    }
    return undefined;
  };

  clearFilters = () => {
    this.setState({
      colorFilters: [],
      productTypeFilters: [],
      sizesFilters: [],
      priceRange: [0, this.state.highestPrice]
    });
  };

  filteredProducts = () => {
    let products = this.state.products;

    if (this.state.sizesFilters.length > 0) {
      products = products.filter((product) =>
        product.sizes.some((item) => this.state.sizesFilters.includes(item))
      );
    }

    if (this.state.colorFilters.length > 0) {
      products = products.filter((product) => this.state.colorFilters.includes(product.color_id));
    }

    if (this.state.productTypeFilters.length > 0) {
      products = products.filter((product) =>
        this.state.productTypeFilters.includes(product.product_type_id)
      );
    }

    const priceRange = this.state.priceRange;
    if (this.state.priceRange[1] !== -1) {
      products = products.filter(
        (product) => product.price >= priceRange[0] && product.price <= priceRange[1]
      );
    }
    this.gtagView(products);
    return products;
  };

  showDrawer = () => {
    this.setState({ filterDrawer: true });
  };
  onCloseDrawer = () => {
    this.setState({ filterDrawer: false });
  };

  getFilters = () => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    const marks: any = {
      0: `${this.props.app.currency?.symbol} 0`,
      [this.state.highestPrice]: `${this.props.app.currency?.symbol} ${this.state.highestPrice}`
    };

    const selectableColors = this.state.colors.filter((color) =>
      this.state.productColors.includes(color.id)
    );
    const selectableSizes = this.state.sizes.filter((size) =>
      this.state.productSizes.includes(size.id)
    );
    const selectableTypes = this.state.types.filter((type) =>
      this.state.productTypes.includes(type.id)
    );

    return (
      <div className={'products-filters'}>
        <div className={'products-filter'}>
          <div className={'products-filter-label'}>
            {t('products:filters.sizes')}
            {this.state.measurement_unit === 'imperial' ? (
              <div
                className={'unit-link'}
                onClick={() => this.setState({ measurement_unit: 'metric' })}
              >
                {t('products:filters.show_in_cm')} <SwapOutlined />
              </div>
            ) : (
              <div
                className={'unit-link'}
                onClick={() => this.setState({ measurement_unit: 'imperial' })}
              >
                {t('products:filters.show_in_inches')} <SwapOutlined />
              </div>
            )}
          </div>
          <Checkbox.Group
            style={{ width: '100%' }}
            value={this.state.sizesFilters}
            onChange={this.onSizesSelect}
          >
            <Row>
              {selectableSizes.map((size) => {
                return (
                  <Col key={size.id} xs={12} span={size.id}>
                    {this.state.measurement_unit === 'imperial' ? (
                      <Checkbox value={size.id}>{size.imperial_dimensions}</Checkbox>
                    ) : (
                      <Checkbox value={size.id}>{size.metric_dimensions}</Checkbox>
                    )}
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </div>

        {selectableColors.length > 1 && (
          <div className={'products-filter'}>
            <div className={'products-filter-label'}>{t('products:filters.colors')}</div>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={this.state.colorFilters}
              onChange={this.onColorsSelect}
            >
              <Row>
                {selectableColors.map((color, cindex) => {
                  return (
                    <Col key={cindex} span={12}>
                      <Checkbox value={color.id}>
                        {color.name[this.props.app.language.code]}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>
        )}

        {selectableTypes.length > 1 && (
          <div className={'products-filter'}>
            <div className={'products-filter-label'}>{t('products:filters.product_types')}</div>
            <Checkbox.Group
              value={this.state.productTypeFilters}
              style={{ width: '100%' }}
              onChange={this.onProductTypesSelect}
            >
              <Row>
                {selectableTypes.map((productType, pindex) => {
                  return (
                    <Col key={pindex} span={12}>
                      <Checkbox value={productType.id}>
                        {productType.name[this.props.app.language.code]}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>
        )}

        <div className={'products-filter'}>
          <div className={'products-filter-label'}>{t('products:filters.price_range')}</div>
          {this.state.highestPrice !== -1 && (
            <Slider
              max={this.state.highestPrice}
              range={true}
              onAfterChange={this.onPriceRangeSelect}
              marks={marks}
              defaultValue={[0, this.state.highestPrice]}
            />
          )}
        </div>

        <Button block={true} onClick={this.clearFilters}>
          {t('products:filters.button.clear_filters.text')}
        </Button>

        <Row>
          <Col xs={24} lg={0}>
            <Button
              style={{ marginTop: 10 }}
              block={true}
              type={'primary'}
              onClick={this.onCloseDrawer}
            >
              {t('products:filters.button.hide_filters.text')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const t: any = this.props.i18n.t.bind(this.props.i18n);
    const products = this.filteredProducts();

    return (
      <Row className={'products-container'}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
          <Row>
            <Col xs={24}>
              <h2>{this.props.section?.settings.title[this.props.app.language.code]}</h2>
              <Link to={'/cart'} style={{ float: 'right', marginTop: 15 }}>
                {t('products:button.go_to_cart')} <ArrowRightOutlined />
              </Link>
            </Col>
          </Row>

          <Drawer
            width={window.innerWidth - 80}
            title={t('products:filters.title')}
            placement='left'
            closable={true}
            onClose={this.onCloseDrawer}
            open={this.state.filterDrawer}
          >
            {this.getFilters()}
          </Drawer>

          <Row gutter={20}>
            <Col xs={24} lg={0}>
              <Button
                icon={<FilterOutlined />}
                style={{ marginBottom: 20 }}
                type={'primary'}
                onClick={this.showDrawer}
              >
                {t('products:button.show_filters')}
              </Button>
            </Col>
            <Col xs={0} lg={6}>
              {this.getFilters()}
            </Col>
            <Col xs={24} lg={18}>
              {products.length > 0 ? (
                <Row gutter={[20, 20]}>
                  {products.map((product, pindex) => {
                    const image =
                      product.pictures && product.pictures.length > 0 ? product.pictures[0] : null;
                    const productIsWished = this.productIsInWishList(product);
                    return (
                      <Col key={pindex} xs={12} xl={8} className={'product-col'}>
                        <script type='application/ld+json'>
                          {this.props.app.currency &&
                            JSON.stringify(
                              productSchema(
                                product,
                                this.props.app.currency.code,
                                this.props.app.language.code
                              )
                            )}
                        </script>
                        <div className={'product'}>
                          <Row className={'product-image'}>
                            <Col xs={24}>
                              {this.getStockMessage(product)}
                              <Link to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${product.slug}`}>
                                {image && (
                                  <img
                                    alt={product.summary[this.props.app.language.code].replace(
                                      /<\/?.+?>/gi,
                                      ''
                                    )}
                                    src={process.env.REACT_APP_API_URL + image}
                                  />
                                )}
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={24} className={'product-details'}>
                              <div>
                                <strong>{product.name[this.props.app.language.code]}</strong>
                                &nbsp;
                                <span>{product.subtitle[this.props.app.language.code]}</span>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formatPrice(
                                    product.price,
                                    this.props.app.currency,
                                    this.props.app.country,
                                    product.sale,
                                  )
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={24} className={'product-actions'}>
                              {productIsWished ? (
                                <div
                                  className={`icon-button active`}
                                  onClick={() => this.props.removeItemFromWishList(product.id)}
                                >
                                  <HeartFilled />
                                </div>
                              ) : (
                                <div
                                  className={`icon-button`}
                                  onClick={() => this.handleAddItemToWishListClick(product)}
                                >
                                  <HeartOutlined />
                                </div>
                              )}
                              <Link to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${product.slug}`}>
                                <Button type={'primary'}>
                                  {product.personalise
                                    ? t('products:button.personalise')
                                    : t('products:button.view')}
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <Row>
                  {this.state.loadingProducts ? (
                    <span>{t('products:loading_pages')}</span>
                  ) : (
                    <span>{t('products:no_products_found')}</span>
                  )}
                </Row>
              )}
              <Row>
                <Col xs={24}>
                  <Link to={'/cart'} style={{ float: 'right', marginTop: 15 }}>
                    {t('products:button.go_to_cart')} <ArrowRightOutlined />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id)),
  showCategoriesBar: () => dispatch(showCategoriesBar()),
  hideCategoriesBar: () => dispatch(hideCategoriesBar())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WebshopModule));
