import { ADD_WISHLIST_ITEM, CLEAR_WISHLIST, REMOVE_WISHLIST_ITEM } from '../types';
import { WishListState } from '../states/wishlist';

export default function wishlist(state = WishListState, action: any) {
  let items = state.items;

  switch (action.type) {
    case ADD_WISHLIST_ITEM:
      items.push(action.payload);
      return {
        ...state,
        items: items
      };
    case REMOVE_WISHLIST_ITEM:
      const newItems = items.filter((item) => item.id !== action.payload);
      return {
        ...state,
        items: newItems
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
}
