import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import '../../../styles/modules/waiting-list-signup.less';
import Swal from 'sweetalert2';
import { FormInstance } from 'antd/lib/form';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
}

const WaitingListSignUpModule = (props: Props) => {
  const formRef = React.createRef<FormInstance>();

  const section = props.section;
  const styles = section.settings.background_color
    ? { backgroundColor: section.settings.background_color }
    : {};

  const { t } = useTranslation();

  const handleSubmit = () => {
    const t: any = props.i18n.t.bind(props.i18n);
    if (formRef && formRef.current) {
      const formFields = formRef.current;
      const data = {
        ...formFields.getFieldsValue(),
        language: props.app.language.code,
        course_id: section.settings.course_id,
      };
      formFields.validateFields().then((res) => {
        new LoopBack()
          .post('/courses/waitinglist', data)
          .then(res => {
            Swal.fire({
              title: t('pages:course_waitinglist.popup.success.title'),
              html: t('pages:course_waitinglist.popup.success.description'),
              icon: 'success'
            });
            formFields.resetFields();
          })
          .catch((err) => {
            Swal.fire({
              title: t('pages:course_waitinglist.popup.error.title'),
              html: t('pages:course_waitinglist.popup.error.description'),
              icon: 'error'
            });
          });
      });
    }
  };

  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-waiting-list-signup'}
      style={styles}
    >
      <Col xs={24} sm={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
        <Row gutter={[30, 30]} justify={'center'}>
          <Col xs={24} lg={21} xl={12}>
            <h2>{section.settings.title[props.app.language.code]}</h2>
              <div
                id={'cms-module-waiting-list-signup-description'}
                dangerouslySetInnerHTML={{
                  __html: section.settings.description[props.app.language.code]
                }}
              />
            </Col>
          </Row>
          <Row justify={'center'}>
            <Col xs={24} lg={21} xl={12}>
              <Form name='basic' layout={'horizontal'} ref={formRef} onFinish={handleSubmit}>
                <Form.Item
                  label={t('pages:course_waitinglist.form.name.label')}
                  name={'name'}
                  rules={[
                    { required: true, message: t('pages:course_waitinglist.form.name.warning') }
                  ]}
                >
                  <Input size={'large'} placeholder={t('pages:course_waitinglist.form.name.placeholder')} />
                </Form.Item>
                <Form.Item
                  label={t('pages:course_waitinglist.form.email.label')}
                  name={'email'}
                  rules={[
                    { required: true, message: t('pages:course_waitinglist.form.email.warning') }
                  ]}
                >
                  <Input
                    size={'large'}
                    type={'email'}
                    placeholder={t('pages:course_waitinglist.form.email.placeholder')}
                  />
                </Form.Item>


                <div id={'cms-module-waiting-list-signup-button'}>
                <Button
                  size={'large'}
                  type='primary'
                  htmlType='submit'
                >
                  <span>{t('pages:course_waitinglist.form.button.submit.text')}</span>
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect<any, any, any>(mapStateToProps)(withTranslation()(WaitingListSignUpModule));
