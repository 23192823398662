import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { LoopBack } from '../../redux/api';
import { Product, ProductStyle, ProductType } from '../../types';
import { formatPrice } from '../../helpers/price.helper';
import {ICartState} from "../../redux/states/cart";

interface Props extends WithTranslation {
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
}

interface State {
  data: Product[];
  value: any;
  visible: boolean;
  searching: boolean;
}

const ProductSearchComponent = (props: Props) => {
  const [timeInterval, setTimeInterval] = useState<any>(null);
  const [productStyles, setProductStyles] = useState<ProductStyle[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);

  const [data, setData] = useState<Product[]>([]);
  const [searching, setSearching] = useState(false);
  const [value, setValue] = useState<undefined | string>(undefined);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    new LoopBack()
      .get('/product-styles')
      .then((res) => {
        setProductStyles(res.data);
      })
      .catch((err) => {
        setProductStyles([]);
      });

    new LoopBack()
      .get('/product-types')
      .then((res) => {
        setProductTypes(res);
      })
      .catch((err) => {
        setProductTypes([]);
      });
  }, []);

  const getProducts = (value: string) => {
    const lang = props.app.language.code;

    let filter: any = { where: { active: true } };
    new LoopBack()
      .get(`/products?filter=${JSON.stringify(filter)}`)
      .then((res) => {
        const filtered = res.filter(
          (product: Product) =>
            product.name[lang].toLowerCase().includes(value) ||
            productStyles.some(
              (style) =>
                style.id === product.product_style_id &&
                style.name[lang].toLowerCase().includes(value)
            ) ||
            productTypes.some(
              (type) =>
                type.id === product.product_type_id &&
                type.name[lang].toLowerCase().includes(value)
            )
        );
        setData(filtered);
        setSearching(false);
      })
      .catch((res) => {
        setSearching(false);
        setData([]);
        setValue(undefined);
      });
  };

  const fetch = (value: any) => {
    if (timeInterval) {
      clearTimeout(timeInterval);
      setTimeInterval(null);
    }
    setValue(value);

    const timeOut = setTimeout(() => getProducts(value.toLowerCase()), 300);
    setTimeInterval(timeOut);
  };
  //
  // handleChange = (value: any) => {
  //   this.setState({ value: value });
  // };

  const handleSearch = (_value: string) => {
    if (_value !== value) {
      setValue(_value);
      setSearching(true);
      if (_value) {
        fetch(_value);
      } else {
        setData([]);
        setSearching(false);
        setValue(undefined);
      }
    }
  };

  const getContent = () => {
    if (searching) {
      return (
        <span>
          <em>{t('app:navbar.products_search.searching')}</em>
        </span>
      );
    } else if (value === '' || value === undefined) {
      return (
        <span>
          <em>{t('app:navbar.products_search.enter_value')}</em>
        </span>
      );
    } else {
      if (data.length > 0) {
        return data.map((product, key) => {
          return (
            <div key={key} className={'product-search-box-product'}>
              <img
                alt={product.name[props.app.language.code]}
                src={product.pictures ? process.env.REACT_APP_API_URL + product.pictures[0] : ''}
              />
              <div className={'product-search-box-name'} onClick={() => setVisible(false)}>
                <Link
                  to={
                    settings.products.shoppingPage +
                    settings.products.productSlugPrefix +
                    product.slug
                  }
                >
                  {product.name[props.app.language.code]}
                </Link>

                <div
                  className={'product-search-box-price'}
                  dangerouslySetInnerHTML={{
                    __html: formatPrice(
                      product.price,
                      props.app.currency,
                      props.app.country,
                      product.sale
                    )
                  }}
                />
              </div>
            </div>
          );
        });
      } else {
        return <span>{t('app:navbar.products_search.no_results')}</span>;
      }
    }
  };

  const searchBox = () => {
    return (
      <div id={'product-search-box'}>
        <Input autoFocus={true} onChange={(value) => handleSearch(value.target.value)} />
        <hr />
        <div id={'product-search-box-list'}>{getContent()}</div>
      </div>
    );
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const customIcon = settings.navbar.search.customIcon;


  return window.innerWidth < 768 ? (
    <span id={'shopping-cart'}>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        trigger={['click']}
        visible={visible}
        overlay={searchBox}
      >
        {customIcon ? (
          <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`)} />
        ) : (
          <SearchOutlined />
        )}
      </Dropdown>
    </span>
  ) : (
    <span id={'search-product-icon'}>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        trigger={['click']}
        visible={visible}
        overlay={searchBox}
      >
        {customIcon ? (
          <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`)} />
        ) : (
          <SearchOutlined />
        )}
      </Dropdown>
    </span>
  );
};

const mapDispatchToProps = (dispatch: any) => ({});

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProductSearchComponent));
