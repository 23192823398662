import * as React from 'react';
import ErrorComponent from './core-module/components/app/error.component';

interface LocalErrorState {
  hasError: boolean;
}

export function errorBoundry(WrappedComponent: any) {
  return class extends React.Component<{}, LocalErrorState> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      this.setState({ hasError: true });
    }

    render() {
      if (this.state.hasError) {
        return <ErrorComponent />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
