import React, { useEffect } from 'react';
import PublicRoutes from './router';
import { ConfigProvider, message } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import configureStore from './core-module/redux/store';
import { unregister } from './serviceWorker';
import { logoutUser } from './core-module/redux/actions/auth';
import { AuthState } from './core-module/redux/states/user';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { errorBoundry } from './error-boundary';
import jwtDecode from 'jwt-decode';
import { Token } from './core-module/types';
import {
  fetchSettings,
  hideCategoriesBar,
  initializeLocalization
} from './core-module/redux/actions/app';
import { IAppState } from './core-module/redux/states/app';
import { useTranslation } from 'react-i18next';
import SkeletonComponent from './core-module/components/app/skeleton.component';
import { BrowserRouter } from 'react-router-dom';
import Translation from './i18n';
import moment from 'moment';

const Redux = configureStore();
Redux.store.subscribe(listener);

unregister();

function listener() {}

interface Props {
  auth: AuthState;
  dispatch: Dispatch;
  app: IAppState;
  fetchSettings: typeof fetchSettings;
  initializeLocalization: typeof initializeLocalization;
  hideCategoriesBar: typeof hideCategoriesBar;
  logoutUser: typeof logoutUser;
}

Translation('en');

const App = (props: Props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.app.language ? props.app.language.code.toLowerCase() : 'en');

    props.hideCategoriesBar();
    props.fetchSettings();

    const doSetLanguage = !props.app.languageInit;
    props.initializeLocalization(props, doSetLanguage, i18n);
  }, []);

  useEffect(() => {
    verifyLoginToken();
    const interval = setInterval(() => {
      verifyLoginToken();
    }, 3000);

    return () => clearInterval(interval);
  }, [props.auth]);

  const verifyLoginToken = () => {
    if (props.auth.isLoggedIn) {
      if (isTokenExpired()) {
        props.logoutUser();
        message.info('Your login session has expired. Please login again');
      }
    }
  };

  const isTokenExpired = () => {
    const { auth } = props;
    const containsInvalidUserObject = auth.user && Object.keys(auth.user).length === 0;

    let isExpired = false;

    if (auth.isLoggedIn && !containsInvalidUserObject) {
      const token: Token = jwtDecode(auth.token);
      const now = moment();
      isExpired = moment.unix(token.exp).isBefore(now);
    }

    return (auth.isLoggedIn && isExpired) || containsInvalidUserObject;
  };

  let style = {
    height: 'auto',
    width: '100%',
    opacity: 0.5,
    position: 'fixed',
    objectFit: 'contain',
    backgroundRepeat: 'no-repeat'
  };
  if (window.outerWidth < 720) {
    style.height = '130vh';
    style.width = '110%';
  }

  return (
    <ConfigProvider locale={enGB}>
      {props.app.currency === null || props.app.country === null ? (
        <SkeletonComponent />
      ) : props.app.settings?.coming_soon ? (
        <div className={'coming-soon'}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <PublicRoutes />
          </BrowserRouter>
        </div>
      ) : (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PublicRoutes />
        </BrowserRouter>
      )}
    </ConfigProvider>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  initializeLocalization: (props: any, doSetLanguage: boolean, i18: any) =>
    dispatch(initializeLocalization(props, doSetLanguage, i18)),
  logoutUser: () => dispatch(logoutUser()),
  hideCategoriesBar: () => dispatch(hideCategoriesBar())
});

export default connect(mapStateToProps, mapDispatchToProps)(errorBoundry(App));
