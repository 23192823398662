import { Button, Carousel, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection, Product, ProductCategory, ProductType } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import { gtagProducts } from '../../../helpers/gtag';
import { productSchema } from '../../../helpers/structured-data.helper';
import { settings } from '../../../../settings';
import { ReactComponent as ArrowRight } from '../../../../assets/icon-arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/icon-arrow-left.svg';
import '../../../styles/modules/highlighted-products.less';
import { CarouselRef } from 'antd/lib/carousel';
import { formatPrice } from '../../../helpers/price.helper';
import { ReactComponent as HeartFillIcon } from '../../../../assets/icon-heart-fill.svg';
import { HeartFilled, HeartOutlined, RightOutlined } from '@ant-design/icons';
import { ReactComponent as HeartIcon } from '../../../../assets/icon-heart.svg';
import { IWishListState } from '../../../redux/states/wishlist';
import { addItemToWishList, removeItemFromWishList } from '../../../redux/actions/wishlist';
import Swal from 'sweetalert2';
import moment from 'moment';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
  wishlist: IWishListState;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
}

interface State {
  products: Product[];
  productCategories: ProductCategory[];
  productTypes: ProductType[];
}

class HighlightedProductsModule extends React.Component<Props, State> {
  carouselRef = React.createRef<CarouselRef>();

  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      productCategories: [],
      productTypes: []
    };
  }

  componentDidMount(): void {
    const filter: any = {};
    filter.where = { id: { inq: this.props.section?.settings.products } };

    if (this.props.section?.settings.products_to_show === 'new_arrivals') {
      filter.where = { created: { gte: moment().subtract(1,'year').format('YYYY-MM-DD') }, active: true };
      filter.order = 'created DESC'
      filter.limit = 9;
    }

    new LoopBack().get(`/products?filter=${JSON.stringify(filter)}`).then((res) => {
      this.setState({ products: res });
      gtagProducts(res, this.props.app.language.code);
    });

    new LoopBack()
      .get('/product-categories')
      .then((res: ProductCategory[]) => {
        this.setState({ productCategories: res });
      })
      .catch((err) => {
        this.setState({ productCategories: [] });
      });

    new LoopBack()
      .get('/product-types')
      .then((res) => {
        this.setState({ productTypes: res });
      })
      .catch((err) => {
        this.setState({ productTypes: [] });
      });
  }

  onPrev = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.prev();
    }
  };

  onNext = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.next();
    }
  };

  getProductCategory = (product: Product) => {
    const categoryIndex = this.state.productCategories.findIndex(
      (type) => product.product_category_id === type.id
    );
    if (categoryIndex > -1) {
      return this.state.productCategories[categoryIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  getProductType = (product: Product) => {
    const typeIndex = this.state.productTypes.findIndex(
      (type) => product.product_type_id === type.id
    );
    if (typeIndex > -1) {
      return this.state.productTypes[typeIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  productIsInWishList = (product: Product) => {
    return this.props.wishlist.items.some((item) => item.id === product.id);
  };

  handleAddItemToWishListClick = (product: Product) => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    this.props.addItemToWishList(product);
    Swal.fire({
      title: t('products:detail.popup.wishlist.success.title'),
      html: t('products:detail.popup.wishlist.success.description', {
        text: `<strong>${product.name[this.props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  render() {
    const section = this.props.section;

    return (
      <Row
        id={section.uid ? section.uid : undefined}
        className={'cms-module-highlighted-products'}
        align={'middle'}
      >
        {section.settings.background_image && (
          <img
            className={'bg-image'}
            src={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
          />
        )}
        <Col xs={24} md={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
          {section?.settings.title &&
            section?.settings.title[this.props.app.language.code] &&
            section.settings.titles_position === 'above_products' && (
              <div className={'cms-module-highlighted-products-title'}>
                <h2>{section.settings.title[this.props.app.language.code]}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.settings.subtitle[this.props.app.language.code]
                  }}
                />
              </div>
            )}
          <Row>
            <Col xs={24}>
              <Carousel
                ref={this.carouselRef}
                responsive={[
                  {
                    breakpoint: 576,
                    settings: {
                      slidesToShow: 1,
                      variableWidth: true,
                      slidesPerRow: 1,
                      slidesToScroll: 1,
                      speed: 500,
                    }
                  },
                  {
                    breakpoint: 769,
                    settings: {
                      slidesToShow: 1,
                      variableWidth: true,
                      slidesPerRow: 2,
                      slidesToScroll: 1,
                      speed: 500,
                    }
                  },
                  // {
                  //   breakpoint: 992,
                  //   settings: { slidesToShow: 1, slidesPerRow: 3, slidesToScroll: 1 }
                  // },
                  {
                    breakpoint: 1024,
                    settings: { slidesToShow: 1, slidesPerRow: 2, slidesToScroll: 1,
                      speed: section.settings.speed,
                    }
                  },
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 1,
                      slidesPerRow: section.settings.products_per_slide,
                      slidesToScroll: 1,
                      speed: section.settings.speed,
                    }
                  }
                ]}
                useTransform={false}
                autoplay={section.settings.auto_play}
                autoplaySpeed={section.settings.auto_play_speed}
                slidesPerRow={section.settings.products_per_slide}
                slidesToShow={1}
                cssEase={'cubic-bezier(0.420, 0.000, 0.580, 1.000)'}
                dots={false}
                // speed={section.settings.speed}
                slidesToScroll={1}
              >
                {this.state.products.map((product, index) => {
                  const productIsWished = this.productIsInWishList(product);

                  return (
                    <div className={'product-slide'} key={index}>
                      <div className={'product-wrapper'}>
                        <script type='application/ld+json'>
                          {this.props.app.currency &&
                            JSON.stringify(
                              productSchema(
                                product,
                                this.props.app.currency.code,
                                this.props.app.language.code
                              )
                            )}
                        </script>
                        {product.custom_url ? (
                          <a href={settings.products.productSlugPrefix + product.slug} target='_blank' rel={'noopener noreferrer'}>
                            <div className={'highlighted-product'}>
                              {product.pictures && (
                                <img
                                  alt={`${product.name[this.props.app.language.code]}`}
                                  src={process.env.REACT_APP_API_URL + product.pictures[0]}
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <Link to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${product.slug}`}>
                            <div className={'highlighted-product'}>
                              {product.pictures && (
                                <img
                                  alt={`${product.name[this.props.app.language.code]}`}
                                  src={process.env.REACT_APP_API_URL + product.pictures[0]}
                                />
                              )}
                            </div>
                          </Link>
                        )}
                        {/*<div className={'highlighted-product-title'}>*/}
                        {/*  <h3>{product.name[this.props.app.language.code]}</h3>*/}
                        {/*</div>*/}

                        <div className={'product-details-container'}>
                          <Row>
                            <Col xs={24}>
                              <div className={'product-details'}>
                                <div style={{ float: 'left' }}>
                                  {product.name[this.props.app.language.code]}
                                </div>

                                <div
                                  style={{ float: 'right' }}
                                  dangerouslySetInnerHTML={{
                                    __html: formatPrice(
                                      product.price,
                                      this.props.app.currency,
                                      this.props.app.country,
                                      product.sale,
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={24} className={'product-actions'}>
                              <span className={'product-actions-category-label'}>
                                {this.getProductCategory(product)} -&nbsp;
                              </span>
                              {this.getProductType(product)}
                              {productIsWished ? (
                                <div
                                  className={`icon-button active`}
                                  onClick={() => this.props.removeItemFromWishList(product.id)}
                                >
                                  {settings.products.customWishlistIcon ? (
                                    <HeartFillIcon />
                                  ) : (
                                    <HeartFilled />
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={`icon-button`}
                                  onClick={() => this.handleAddItemToWishListClick(product)}
                                >
                                  {settings.products.customWishlistIcon ? (
                                    <HeartIcon />
                                  ) : (
                                    <HeartOutlined />
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <div className={'highlighted-products-navigation'}>
                <div
                  onClick={this.onPrev}
                  className={'highlighted-products-navigation-arrow arrow-left'}
                >
                  <ArrowLeft />
                </div>
                <div
                  onClick={this.onNext}
                  className={'highlighted-products-navigation-arrow arrow-right'}
                >
                  <ArrowRight />
                </div>
              </div>
            </Col>
          </Row>
          {section && section.settings.button && section.settings.button.url !== '' && (
            <Row className={'highlighted-products-link'}>
              <Col xs={24}>
                {section?.settings.title &&
                  section?.settings.title[this.props.app.language.code] &&
                  section.settings.titles_position === 'above_button' && (
                    <>
                      <h2>{section.settings.title[this.props.app.language.code]}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section.settings.subtitle[this.props.app.language.code]
                        }}
                      />
                    </>
                  )}

                <Link to={section.settings.button.url}>
                  <Button type={'primary'} size={'large'}>
                    {section.settings.button.text[this.props.app.language.code]} <RightOutlined />
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

const mapStateToProps = (state: any) => ({
  app: state.app,
  wishlist: state.wishlist
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HighlightedProductsModule));
