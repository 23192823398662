import { combineReducers } from 'redux';
import auth from './auth.reducer';
import cart from './cart.reducer';
import wishlist from './wishlist.reducer';
import app from './app.reducer';

export default combineReducers({
  app: app,
  auth: auth,
  cart: cart,
  wishlist: wishlist
});
