import { ADD_WISHLIST_ITEM, CLEAR_WISHLIST, REMOVE_WISHLIST_ITEM } from '../types';
import { Product } from '../../types';

export const emptyWishList = () => ({
  type: CLEAR_WISHLIST
});

export const addItemToWishList = (product: Product) => ({
  type: ADD_WISHLIST_ITEM,
  payload: product
});

export const removeItemFromWishList = (id: number) => ({
  type: REMOVE_WISHLIST_ITEM,
  payload: id
});
