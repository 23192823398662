import React, { useEffect, useState } from 'react';
import { removeItemFromCart } from '../../redux/actions/cart';
import { connect } from 'react-redux';
import { Badge, Button, Col, Dropdown, Layout, Menu, Row, Space } from 'antd';
import {
  DownOutlined,
  FlagOutlined,
  HeartOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link, useLocation } from 'react-router-dom';
import { Currency } from '../../types';
import { logoutUser } from '../../redux/actions/auth';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { setCurrency, setLanguage } from '../../redux/actions/app';
import { settings } from '../../../settings';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import ProductSearchComponent from './product-search.component';
import ShoppingCartComponent from './shopping-cart.component';
import { getMenu } from './helpers/menu.helper';
import WishlistModal from '../popups/wishlist.modal';
import AuthModal from '../popups/auth.modal';
import '../../styles/header.less';
import {ICartState} from "../../redux/states/cart";

const { Header } = Layout;

interface Props extends WithTranslation {
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
  wishlist: IWishListState;
  logoutUser: typeof logoutUser;
  removeItemFromCart: typeof removeItemFromCart;
  setCurrency: typeof setCurrency;
  setLanguage: typeof setLanguage;
}

const NavbarDefaultComponent = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(0);
  const [showUserAccountModal, setShowUserAccountModal] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [showWishlistModal, setShowWishlistModal] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', saveScrollTopValue);

    return () => {
      window.removeEventListener('scroll', saveScrollTopValue);
    };
  }, []);

  useEffect(() => {
    setMenuVisible(false);
  }, [location]);

  const saveScrollTopValue = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let _scrollTop = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    setScrollTop(_scrollTop);
  };

  const loginMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to={'/login'}>
            <LoginOutlined /> {t('app:navbar.menu.auth.go_to_login.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/forgot-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.forgot_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to={'/register'}>
            <Button style={{ width: 400 }} type={'primary'}>
              {t('app:navbar.menu.auth.button.text')}
            </Button>
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  const logoutMenu = () => {
    const customUserIcon = settings.navbar.userAccount.customIcon;

    return (
      <Menu>
        <Menu.Item>
          <Link to={'/profile'}>
            {customUserIcon ? (
              <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
            ) : (
              <UserOutlined />
            )}
            &nbsp;{t('app:navbar.menu.auth.my_account.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/change-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.change_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => props.logoutUser()}>
          <LogoutOutlined /> {t('app:navbar.menu.auth.logout.label')}
        </Menu.Item>
      </Menu>
    );
  };

  const currency = () => {
    return (
      <Menu>
        {props.app.currencies.map((currency, index) => {
          return (
            <Menu.Item key={index} onClick={() => props.setCurrency(currency)}>
              {currency.symbol} {t(`app:navbar.menu.currency.${currency.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const handleChangeLanguage = (language: { name: string; code: string }) => {
    props.i18n.changeLanguage(language.code.toLowerCase());
    props.setLanguage(language);
  };

  const language = () => {
    return (
      <Menu>
        {settings.languages.map((language: { name: string; code: string }, index: number) => {
          return (
            <Menu.Item key={index} onClick={() => handleChangeLanguage(language)}>
              {t(`app:navbar.menu.language.${language.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const userAccountIcon = () => {
    const userAccountStyle = settings.navbar.userAccount.style;
    const customUserIcon = settings.navbar.userAccount.customIcon;

    switch (userAccountStyle) {
      case 'popup':
        return (
          <span id={'login-menu'}>
            {customUserIcon ? (
              <img
                alt={'profile icon'}
                src={require(`../../../assets/${customUserIcon}`)}
                onClick={() => setShowUserAccountModal(true)}
              />
            ) : (
              <UserOutlined onClick={() => setShowUserAccountModal(true)} />
            )}
            <AuthModal
              visible={showUserAccountModal}
              hidePopup={() => setShowUserAccountModal(false)}
            />
          </span>
        );
      default:
        if (props.auth.isLoggedIn) {
          return (
            <Dropdown overlay={logoutMenu()}>
              <span>
                {customUserIcon ? (
                  <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
                ) : (
                  <UserOutlined />
                )}
                <span className={'dropdown-label'}>
                  <>
                    &nbsp;
                    {props.auth.user.customer
                      ? props.auth.user.customer.first_name
                      : t('app:navbar.menu.auth.my_account.label')}
                    &nbsp;
                  </>
                </span>
                <DownOutlined />
              </span>
            </Dropdown>
          );
        } else {
          return (
            <span id={'login-menu'}>
              <Dropdown overlay={loginMenu()}>
                <span>
                  {customUserIcon ? (
                    <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
                  ) : (
                    <UserOutlined />
                  )}
                  <span className={'dropdown-label'}>
                    <>
                      &nbsp;
                      {t('app:navbar.menu.auth.login.label')}
                    </>
                  </span>{' '}
                  <DownOutlined />
                </span>
              </Dropdown>
            </span>
          );
        }
    }
  };

  const wishlistIcon = () => {
    const wishlistStyle = settings.navbar.wishlist.style;
    const customIcon = settings.navbar.wishlist.customIcon;
    switch (wishlistStyle) {
      case 'popup':
        return (
          <span id={'wishlist-icon'}>
            <span onClick={() => setShowWishlistModal(true)}>
              <Badge className={'header-cart-badge'} count={props.wishlist.items.length}>
                {customIcon ? (
                  <img alt={'wishlist icon'} src={require(`../../../assets/${customIcon}`)} />
                ) : (
                  <HeartOutlined />
                )}
              </Badge>
            </span>
            <WishlistModal
              visible={showWishlistModal}
              hidePopup={() => setShowWishlistModal(false)}
            />
          </span>
        );
      default:
        return (
          <Link to={'/wishlist'}>
            <Badge className={'header-cart-badge'} count={props.wishlist.items.length}>
              {customIcon ? (
                <img alt={'wishlist icon'} src={require(`../../../assets/${customIcon}`)} />
              ) : (
                <HeartOutlined />
              )}
            </Badge>
          </Link>
        );
    }
  };

  const renderCurrencyLanguageDropdown = () => {
    return (
      <span id={'currency-icon'}>
        {!props.app.currenciesLoading &&
        !props.app.countriesLoading &&
        !props.app.languagesLoading ? (
          <Space className={'currency-country-dropdown'}>
            {settings.languages.length > 1 && (
              <>
                <Dropdown overlay={language}>
                  {props.app.language ? (
                    <span>
                      <FlagOutlined />
                      &nbsp;
                      <span className={'dropdown-label'}>
                        {t(
                          `app:navbar.menu.language.${props.app.language.code.toLowerCase()}.label`
                        )}
                      </span>{' '}
                      <DownOutlined />
                    </span>
                  ) : (
                    <span>
                      <span className={'dropdown-label'}>
                        <FlagOutlined />
                      </span>{' '}
                      {t('app:navbar.menu.currency.no_language_set.label')} <DownOutlined />
                    </span>
                  )}
                </Dropdown>
                {props.app.currencies.length > 1 && <span>|</span>}
              </>
            )}
            {props.app.currencies.length > 1 && (
              <Dropdown overlay={currency}>
                {props.app.currency ? (
                  <span>
                    {props.app.currency.symbol}{' '}
                    <span className={'dropdown-label'}>
                      {t(`app:navbar.menu.currency.${props.app.currency.code.toLowerCase()}.label`)}{' '}
                    </span>
                    <DownOutlined />
                  </span>
                ) : (
                  <span>
                    {t('app:navbar.menu.currency.no_currency_set.label')} <DownOutlined />
                  </span>
                )}
              </Dropdown>
            )}
          </Space>
        ) : (
          <span id={'currency-loading'}>Loading...</span>
        )}
      </span>
    );
  };

  const apiSettings = props.app.settings;

  const categories = apiSettings && apiSettings.navbar_settings?.category_bar && apiSettings.navbar_settings?.category_bar.links;
  const voucher = props.cart.voucher;
  let headerClassName = ``;
  if (scrollTop !== 0) {
    headerClassName += 'header-scrolled';
  }

  if (categories && categories.length > 0) {
    headerClassName += ' header-categories';
  }

  if (scrollTop >= settings.addClassToHeaderOnScroll) {
    headerClassName += ' header-scroll-margin';
  }

  const dropdownContainer = document.getElementById('header-navbar');
  const languageIsNextToLogo = settings.navbar.languageCurrencyPlacement === 'next-to-logo';

  return (
    <Header id={`header-navbar`} className={headerClassName}>
      {voucher && voucher.exists && (
        <Row id={'header-voucher'} style={{backgroundColor: '#554C43FF'}}>
          <Col xs={24} lg={0} className={'voucher-text'} style={{textAlign: 'center', fontSize: 16}}>
      {/*      <span>10% DISCOUNT on your first oder - USE CODE FIRSTORDER</span>*/}
            {t('app:navbar.voucher.title.mobile', { text: voucher.voucher.name })}
          </Col>
          <Col xs={0} lg={24} className={'voucher-text'} style={{textAlign: 'center',fontSize: 16}}>
            {/*<span>10% DISCOUNT on your first oder - USE CODE FIRSTORDER</span>*/}
            {voucher.voucher.navbarText[props.app.language.code]}
          </Col>
        </Row>
      )}
      <Row id={'header-main'}>
        <Col xs={{ span: 24 }}>
          <Row align={'middle'}>
            <Col xs={6} md={3} xl={4}>
              <Link id={'header-main-logo'} to={'/'}>
                <img
                  alt={`Logo ${settings.appName}`}
                  src={require('../../../assets/logo-color.png')}
                />
              </Link>
            </Col>
            {languageIsNextToLogo && (
              <Col xs={8} md={5} lg={4}>
                {renderCurrencyLanguageDropdown()}
              </Col>
            )}
            <Col xs={0} md={0} lg={11} xl={14}>
              {apiSettings && window.outerWidth >= settings.navbar.hamburgerBreakpoint && (
                <nav>{getMenu(apiSettings, props.app.language.code, 'horizontal', location)}</nav>
              )}
            </Col>
            <Col
              xs={!languageIsNextToLogo ? 18 : 10}
              md={!languageIsNextToLogo ? 21 : 5}
              lg={!languageIsNextToLogo ? 10 : 8}
              xl={!languageIsNextToLogo ? 6 : 8}
            >
              <div id={'header-main-auth-menu'}>
                {settings.navbar.languageCurrencyPlacement === 'next-to-cart' &&
                  renderCurrencyLanguageDropdown()}

                {settings.navbar.search.enable && <ProductSearchComponent />}

                {settings.navbar.wishlist.enable && wishlistIcon()}

                {settings.navbar.shoppingBag.enable &&
                <ShoppingCartComponent />}

                {settings.navbar.userAccount.enable && userAccountIcon()}

                {apiSettings &&
                  window.outerWidth < settings.navbar.hamburgerBreakpoint &&
                  apiSettings.navbar_settings &&
                  apiSettings.navbar_settings.bottom_bar &&
                  apiSettings.navbar_settings.bottom_bar.links &&
                  apiSettings.navbar_settings.bottom_bar.links.length > 0 && (
                    <Dropdown
                      getPopupContainer={() =>
                        dropdownContainer ? dropdownContainer : document.body
                      }
                      overlay={getMenu(
                        apiSettings,
                        props.app.language.code,
                        'horizontal',
                        location
                      )}
                      trigger={['click']}
                      onOpenChange={(e) => setMenuVisible(e)}
                    >
                      <div id={'header-menu-mobile'}>
                        <div id='header-main-hamburger' className={menuVisible ? 'open' : ''}>
                          <span />
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    </Dropdown>
                  )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {categories && categories.length > 0 && (
        <Row id={'header-categories'}>
          <Col xs={24}>
            <nav>
              <Menu selectedKeys={[location.pathname]} mode='horizontal'>
                {categories.map((link) => {
                  return (
                    <Menu.Item key={link.url}>
                      <Link to={link.url}>{link.name[props.app.language.code]}</Link>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </nav>
          </Col>
        </Row>
      )}
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NavbarDefaultComponent));
