import { Col, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';

interface SliderBlock {
  image: { url: string };
  title: { [key: string]: string };
  button: { text: { [key: string]: string }; url: string };
  subtitle: { [key: string]: string };
  language: string;
}

export const ImagesModule = (section: PageSection) => {
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-images'}
    >
      <Col xs={24}>
        {section.settings.images.map((slide: SliderBlock, index: number) => {
          return (
            <div key={index} className={'image-section'}>
              <img
                alt={`${slide.title[section.language]}`}
                src={process.env.REACT_APP_API_URL + slide.image.url}
              />
              <div className={'slider-content'}>
                <Row justify={'center'} align={'middle'}>
                  <Col xs={24} lg={8} className={'slider-title'}>
                    <h2>{slide.title[section.language]}</h2>
                  </Col>
                  <Col xs={12} lg={8} className={'slider-subtitle'}>
                    <p>{slide.subtitle[section.language]}</p>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};
