import { Country, Currency, Setting } from '../../types';
import { settings } from '../../../settings';

export interface IAppState {
  settings: Setting | null;
  currencies: Currency[];
  countries: Country[];
  loading: boolean;
  countriesLoading: boolean;
  currenciesLoading: boolean;
  currency: Currency | null;
  country: Country | null;
  cookieConsent: boolean;
  languagesLoading: boolean;
  language: { code: string; name: string };
  languageInit: boolean;
  showCategoriesBar: boolean;
}

export const AppState: IAppState = {
  settings: null,
  currencies: [],
  countries: [],
  loading: false,
  countriesLoading: false,
  currenciesLoading: false,
  languagesLoading: false,
  currency: null,
  country: null,
  cookieConsent: false,
  language: settings.languages[0],
  languageInit: false,
  showCategoriesBar: false
};
