import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { BreadCrumbItem } from '../../types';
import { Helmet } from 'react-helmet';

interface Props {
  items: BreadCrumbItem[];
}

class BreadcrumbsComponent extends Component<Props> {
  structuredData = () => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: this.props.items.map((crumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': crumb.title,
          name: crumb.title,
          url: window.location.host + crumb.link
        }
      }))
    };
  };

  render() {
    return (
      <div className={'breadcrumbs'}>
        <Helmet>
          <script type='application/ld+json'>{JSON.stringify(this.structuredData)}</script>
        </Helmet>
        <Breadcrumb>
          {this.props.items.map((item, bindex: number) => {
            if (item.link) {
              return (
                <Breadcrumb.Item key={bindex}>
                  <Link to={item.link}>{item.title}</Link>
                </Breadcrumb.Item>
              );
            }
            return <Breadcrumb.Item key={bindex}>{item.title}</Breadcrumb.Item>;
          })}
        </Breadcrumb>
      </div>
    );
  }
}

export default BreadcrumbsComponent;
