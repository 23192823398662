import React from 'react';
import { Button, Col, Input, Row, Space } from 'antd';

import { connect } from 'react-redux';
import { ICartState } from '../../../redux/states/cart';
import { CheckOutlined, DeleteOutlined, GiftOutlined, StopOutlined } from '@ant-design/icons';
import { AllCosts } from '../../../types';
import { formatPrice } from '../../../helpers/price.helper';
import Swal from 'sweetalert2';
import { IAppState } from '../../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { AuthState } from '../../../redux/states/user';
import { checkVoucher, clearVoucher } from '../../../redux/actions/cart';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props extends WithTranslation {
  voucherCheck: typeof checkVoucher;
  clearVoucher: typeof clearVoucher;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;

  costs: AllCosts;
  showInputOnInit: boolean;
}

interface State {
  showInputVoucher: boolean;
  voucherValue: string | null;
}

class VoucherComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      showInputVoucher: this.props.showInputOnInit,
      voucherValue: null
    };
  }

  switchShowVoucher = () => {
    const currentState = this.state.showInputVoucher;
    this.setState({ showInputVoucher: !currentState });
  };

  applyVoucher = () => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);
    const currentVoucher = this.state.voucherValue;
    if (currentVoucher !== '' && currentVoucher !== null) {
      this.props.voucherCheck(currentVoucher.trim(), t);
    } else {
      Swal.fire({
        title: t('cart:checkout.voucher.popup.title.warning'),
        html: t('cart:checkout.voucher.popup.description.warning'),
        icon: 'error'
      });
    }
  };



  render() {
    const t: any = this.props.i18n.t.bind(this.props.i18n);
    console.log(this.props.costs);

    return (
      <>
        {this.state.showInputVoucher &&
        (!this.props.cart.voucher ||
          (this.props.cart.voucher && !this.props.cart.voucher.exists)) ? (
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Input
                style={{ marginBottom: 5 }}
                onChange={(e) => this.setState({ voucherValue: e.target.value.toString() })}
                size={'large'}
                placeholder={t('cart:checkout.voucher.form.placeholder')}
              />

              <Button
                icon={<CheckOutlined />}
                style={{ marginBottom: 5 }}
                block={true}
                size={'large'}
                loading={this.props.cart.voucherLoading}
                onClick={this.applyVoucher}
                type={'primary'}
              >
                {this.props.cart.voucherLoading ? (
                  <span>{t('cart:checkout.voucher.button.loading')}</span>
                ) : (
                  <span>{t('cart:checkout.voucher.button.apply')}</span>
                )}
              </Button>

              {!this.props.showInputOnInit && (
                <Button
                  icon={<StopOutlined />}
                  block={true}
                  size={'large'}
                  onClick={this.switchShowVoucher}
                  type={'dashed'}
                >
                  {t('cart:checkout.voucher.button.cancel')}
                </Button>
              )}
            </Col>
          </Row>
        ) : (
          <>
            {this.props.cart.voucher && this.props.cart.voucher.exists ? (
              <>
                <span>Voucher overview</span>
                <Row className={'cart-total-price'}>
                  <Col xs={12} className={'cart-total-price-label'}>
                    <div>
                      <strong>{t('cart:checkout.voucher.total')}:</strong>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            this.props.costs.totalTotal,
                            this.props.app.currency,
                            null,
                          )
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={'cart-total-price'}>
                  <Col xs={12} className={'cart-total-price-label'}>
                    <div>
                      <Space>
                        {this.props.cart.voucher.voucher.type === 'percentage' ? (
                          <strong>
                            {this.props.cart.voucher.voucher.name} (-
                            {this.props.cart.voucher.voucher.value}%):
                          </strong>
                        ) : (
                          <strong>
                            {this.props.cart.voucher.voucher.name} (-
                            {this.props.cart.voucher.voucher.value}):
                          </strong>
                        )}
                        <DeleteOutlined onClick={this.props.clearVoucher} />
                      </Space>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `- ${formatPrice(
                            this.props.costs.totalVoucherReduction,
                            this.props.app.currency,
                            null,
                            undefined,
                            true
                          )}`
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Button
                  size={'large'}
                  onClick={this.switchShowVoucher}
                  type={'default'}
                  icon={<GiftOutlined />}
                >
                  {t('cart:checkout.voucher.button.enter_voucher')}
                </Button>
              </Row>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  voucherCheck: (code: string, t: TFunction) => dispatch(checkVoucher(code, t)),
  clearVoucher: () => dispatch(clearVoucher())
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VoucherComponent));
