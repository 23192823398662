import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { settings } from '../../settings';

const logger = createLogger({
  collapsed: true
});

let middleware = [thunk, logger];

const persistConfig = {
  key: settings.reduxStoreKey,
  storage: storage,
  blacklist: []
};

const configureStore = () => {
  let store = createStore(
    persistReducer(persistConfig, reducers),
    composeWithDevTools(applyMiddleware(...middleware))
  );

  let persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
