import React, { useState } from 'react';
import { Button, Col, Form, Input, Layout, Modal, Row } from 'antd';
import { RightOutlined, TwitterOutlined} from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../../redux/states/app';
import CookieBannerComponent from '../../app/cookie-banner.component';
import { clearCookieConsent, setCurrency, setLanguage } from '../../../redux/actions/app';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { Currency } from '../../../types';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { ReactComponent as FaceBookIcon } from '../../../../assets/icon-facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icon-youtube.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icon-instagram.svg';
import { ReactComponent as PinterestFilled } from '../../../assets/logo-pinterest.svg';
import { ReactComponent as LinkedInFilled } from '../../../assets/logo-linkedin.svg'

import '../../../styles/footer-minimal.less';

const { Footer } = Layout;

interface Props extends WithTranslation {
  setLanguage: typeof setLanguage;
  clearCookieConsent: typeof clearCookieConsent;
  setCurrency: typeof setCurrency;
  app: IAppState;
}

const FooterMinimalComponent = (props: Props) => {
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    new LoopBack()
      .post('/newsletter', values)
      .then((res) => {
        Swal.fire({
          title: t('app:footer.popup.signup.success.title'),
          html: t('app:footer.popup.signup.success.description'),
          icon: 'success'
        });
        setShowSubscribeModal(false);
      })
      .catch((err) => {
        Swal.fire({
          title: t('app:footer.popup.signup.warning.title'),
          html: t('app:footer.popup.signup.warning.description'),
          icon: 'warning'
        });
      });
  };

  const apiSettings = props.app.settings;
  const modalContainer = document.getElementById('footer-minimal');

  return (
    <Footer id={'footer-minimal'} className={'footer-minimal'}>
      <Modal
        getContainer={modalContainer ? modalContainer : document.body}
        open={showSubscribeModal}
        title={false}
        onCancel={() => setShowSubscribeModal(false)}
        footer={[
          <Button
            key='Close'
            onClick={() => {
              setShowSubscribeModal(false);
            }}
          >
            Close
          </Button>
        ]}
      >
        <div id={'subscribe-modal-content'}>
          <Form name='basic' layout={'horizontal'} onFinish={onFinish}>
            <Row gutter={20}>
              <Col xs={24} id={'newsletter-subscription'}>
                <h3>{t('app:footer.popup.signup.popup.title')}</h3>
                <p>{t('app:footer.popup.signup.popup.description')}</p>
                <Form.Item
                  name={'email'}
                  rules={[{ required: true, message: t('pages:contact.form.email.warning') }]}
                >
                  <Input
                    size={'large'}
                    type='email'
                    placeholder={t('pages:contact.form.email.placeholder')}
                  />
                </Form.Item>
                <Button size={'large'} block={true} htmlType={'submit'} type={'primary'}>
                  {t('app:footer.popup.signup.popup.button')} <RightOutlined />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <Row>
        <Col id={'subscribe-row'} xs={24} sm={24} md={8}>
          <div style={{ float: 'left', marginRight: 10 }}>Follow Us</div>
          {apiSettings?.footer_settings?.social_media_links && (
            <div id={'footer-social-media-icons'} style={{ float: 'left' }}>
              {apiSettings?.footer_settings?.social_media_links.instagram && (
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  href={apiSettings?.footer_settings?.social_media_links.instagram}
                >
                  <InstagramIcon />
                </a>
              )}
              {apiSettings?.footer_settings?.social_media_links.twitter && (
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  href={apiSettings?.footer_settings?.social_media_links.twitter}
                >
                  <TwitterOutlined />
                </a>
              )}
              {apiSettings?.footer_settings?.social_media_links.linked_in && (
                  <a
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={apiSettings?.footer_settings?.social_media_links.linked_in}
                  >
                    <LinkedInFilled />
                  </a>
              )}
              {apiSettings?.footer_settings?.social_media_links.facebook && (
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  href={apiSettings?.footer_settings?.social_media_links.facebook}
                >
                  <FaceBookIcon />
                </a>
              )}
              {apiSettings?.footer_settings?.social_media_links.pinterest && (
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  className={'custom-svg'}
                  href={apiSettings?.footer_settings?.social_media_links.pinterest}
                >
                  <PinterestFilled />
                </a>
              )}
              {apiSettings?.footer_settings?.social_media_links.youtube && (
                <a
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                  href={apiSettings?.footer_settings?.social_media_links.youtube}
                >
                  <YoutubeIcon />
                </a>
              )}
            </div>
          )}

          {settings.footer.enableEmailSubscription &&
              <span id={'subscribe-button'} onClick={() => setShowSubscribeModal(true)}>
            Subscribe
          </span>
          }
        </Col>
        <Col xs={24} sm={24} md={16}>
          <div id={'footer-links'}>
            <Row>
              <Col xs={24} sm={12} lg={14} xl={14}>
                <div id={'links'}>
                  {apiSettings?.footer_settings &&
                    apiSettings.footer_settings.relevant_links.links.map((link, lindex) => {
                      return (
                        <Link key={lindex} className={'footer-link'} to={link.url}>
                          {link.name[props.app.language.code]}
                        </Link>
                      );
                    })}
                </div>
              </Col>
              <Col xs={24} sm={12} lg={10} xl={10}>
                <div id={'copyright'}>
                  <span id={'copy-right-text'}>
                    {`© ${moment().format('YYYY')} ${settings.appName}, All Rights Reserved`}
                  </span>
                  <img alt={'Logo'} src={require('../../../../assets/footer-logo.png')} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {settings.cookies.enable &&
          <CookieBannerComponent/>
      }
    </Footer>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  clearCookieConsent: () => dispatch(clearCookieConsent()),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FooterMinimalComponent));
