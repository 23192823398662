import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { IAppState } from '../../redux/states/app';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getMenu } from './helpers/menu.helper';
import { useLocation } from 'react-router-dom';
import '../../styles/navbar-floating.less';
import { settings } from '../../../settings';

interface Props extends WithTranslation {
  auth: AuthState;
  app: IAppState;
}

const NavbarFloatingComponent = (props: Props) => {
  const location = useLocation();
  const apiSettings = props.app.settings;
  const [scrollTop, setScrollTop] = useState(0);

  const saveScrollTopValue = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let _scrollTop = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    setScrollTop(_scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', saveScrollTopValue);

    return () => {
      window.removeEventListener('scroll', saveScrollTopValue);
    };
  }, []);

  let headerClassName = '';
  if (scrollTop !== 0) {
    headerClassName += 'header-scrolled';
  }

  if (scrollTop >= settings.addClassToHeaderOnScroll) {
    headerClassName += ' header-scroll-margin';
  }

  return (
    <div id={'navbar-floating'} className={headerClassName}>
      {apiSettings && getMenu(apiSettings, props.app.language.code, 'inline', location)}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps)(withTranslation()(NavbarFloatingComponent));
