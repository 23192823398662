import { Button, Col, Row } from 'antd';
import React, { CSSProperties } from 'react';
import { PageSection } from '../../../types';
import '../../../styles/modules/grid.less';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

export const GridModule = (section: PageSection) => {
  const styles: CSSProperties = {};
  if (section.settings.background_color) {
    styles.backgroundColor = section.settings.background_color;
  }
  if (section.settings.text_color) {
    styles.color = section.settings.text_color;
  }

  const getGrid = () => {
    if (section.settings.grid_type === '1_4') {
      return (
        <Row className={'cms-module-grid-one-to-four'}>
          <Col xs={{ span: 24, order: 0 }} xl={{ span: 12, order: 0 }}>
            <Row className={'column-with-image'} style={{ height: '100%' }}>
              <Col xs={24}>
                <div
                  className={'column-content'}
                  dangerouslySetInnerHTML={{
                    __html: section.settings.items_first_column[0].content
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 1 }}>
            {section.settings.title_position === 'column' &&
              <div className={'cms-module-grid-title-container'}>
                {section.settings.title &&
                  Object.keys(section.settings.title).length > 0 &&
                  section.settings.title[section.language] !== '' && (
                    <h2 className={'cms-module-grid-title'}>
                      {section.settings.title[section.language]}
                    </h2>
                  )}
                {section.settings.subtitle &&
                  Object.keys(section.settings.subtitle).length > 0 &&
                  section.settings.subtitle[section.language] !== '' && (
                    <p
                      className={'cms-module-grid-subtitle'}
                      dangerouslySetInnerHTML={{
                        __html: section.settings.subtitle[section.language]
                      }}
                    />
                  )}
              </div>
            }
              <Row  className={'column-with-boxes'} align={'stretch'}>
              {section.settings.items_second_column.map(
                (
                  item: { content: string; button: { text: string; url: string } },
                  index: number
                ) => {
                  return (
                    <Col key={index} xs={24} sm={12}>
                      <div className={'column-content'}>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />

                        {item.button && item.button.url && (
                          <Link to={item.button.url}>
                            <Button size={'large'} type={'primary'}>
                              {item.button.text} <RightOutlined />
                            </Button>
                          </Link>
                        )}
                      </div>
                    </Col>
                  );
                }
              )}
            </Row>
          </Col>
        </Row>
      );
    } else if (section.settings.grid_type === '4_1') {
      return (
        <Row className={'cms-module-grid-four-to-one'}>
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 0 }}>

            {section.settings.title_position === 'column' &&
              <div className={'cms-module-grid-title-container'}>
                {section.settings.title &&
                  Object.keys(section.settings.title).length > 0 &&
                  section.settings.title[section.language] !== '' && (
                    <h2 className={'cms-module-grid-title'}>
                      {section.settings.title[section.language]}
                    </h2>
                  )}
                {section.settings.subtitle &&
                  Object.keys(section.settings.subtitle).length > 0 &&
                  section.settings.subtitle[section.language] !== '' && (
                    <p
                      className={'cms-module-grid-subtitle'}
                      dangerouslySetInnerHTML={{
                        __html: section.settings.subtitle[section.language]
                      }}
                    />
                  )}
              </div>
            }

            <Row className={'column-with-boxes'} align={'stretch'}>
              {section.settings.items_first_column.map(
                (
                  item: { content: string; button: { text: string; url: string } },
                  index: number
                ) => {
                  return (
                    <Col key={index} xs={24} sm={12}>
                      <div className={'column-content'}>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />

                        {item.button && item.button.url && (
                          <Link to={item.button.url}>
                            <Button size={'large'} type={'primary'}>
                              {item.button.text} <RightOutlined />
                            </Button>
                          </Link>
                        )}
                      </div>
                    </Col>
                  );
                }
              )}

              {section.settings.button &&
                section.settings.button.text &&
                section.settings.button.text[section.language] && (
                  <Col xs={24} className={'cms-module-column-layout-button-row'}>
                    <Link to={section.settings.button.url}>
                      <Button size={'large'} type={'primary'}>
                        {section.settings.button.text[section.language]} <RightOutlined />
                      </Button>
                    </Link>
                  </Col>
                )}
            </Row>

          </Col>
          <Col xs={{ span: 24, order: 0 }} xl={{ span: 12, order: 1 }}>
            <Row className={'column-with-image'} style={{ height: '100%' }}>
              <Col xs={24}>
                <div
                  className={'column-content'}
                  dangerouslySetInnerHTML={{
                    __html: section.settings.items_second_column[0].content
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return <></>;
  };

  return (
    <div>
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-grid`}
        style={styles}
      >
        {section.settings.title_position === 'top' &&
        <Col xs={24}>
          {section.settings.title && (
            <>
              {section.settings.title &&
                Object.keys(section.settings.title).length > 0 &&
                section.settings.title[section.language] !== '' && (
                  <h2 className={'cms-module-grid-title'}>
                    {section.settings.title[section.language]}
                  </h2>
                )}
              {section.settings.subtitle &&
                Object.keys(section.settings.subtitle).length > 0 &&
                section.settings.subtitle[section.language] !== '' && (
                  <p
                    className={'cms-module-grid-subtitle'}
                    dangerouslySetInnerHTML={{
                      __html: section.settings.subtitle[section.language]
                    }}
                  />
                )}
            </>
          )}
        </Col>
        }
        <Row className={'cms-module-grid-tiles'}>{getGrid()}</Row>
      </Row>
    </div>
  );
};
