import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Result, Row } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, WithTranslation } from 'react-i18next';
import { AuthState } from '../../redux/states/user';
import { IAppState } from '../../redux/states/app';
import { removeItemFromWishList } from '../../redux/actions/wishlist';
import { ICartState } from '../../redux/states/cart';
import { IWishListState } from '../../redux/states/wishlist';
import { Product, ProductCategory, ProductType } from '../../types';
import Swal from 'sweetalert2';
import { formatPrice } from '../../helpers/price.helper';
import { settings } from '../../../settings';
import { LoopBack } from '../../redux/api';

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  removeItemFromWishList: typeof removeItemFromWishList;
  wishlist: IWishListState;
  hidePopup: () => void;
  visible: boolean;
}

const WishlistModal = (props: Props) => {
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    new LoopBack()
      .get('/product-categories')
      .then((res: ProductCategory[]) => {
        setProductCategories(res);
      })
      .catch((err) => {
        setProductCategories([]);
      });

    new LoopBack()
      .get('/product-types')
      .then((res) => {
        setProductTypes(res);
      })
      .catch((err) => {
        setProductTypes([]);
      });
  },[]);

  const handleRemoveItemClick = (product: Product) => {
    props.removeItemFromWishList(product.id);
    Swal.fire({
      title: t('pages:wishlist.popup.success.title'),
      html: t('pages:wishlist.popup.success.description', {
        text: `<strong>${product.name[props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  const getProductCategory = (product: Product) => {
    const categoryIndex = productCategories.findIndex(
      (type) => product.product_category_id === type.id
    );
    if (categoryIndex > -1) {
      return productCategories[categoryIndex].name[props.app.language.code];
    }
    return '-';
  };

  const getProductType = (product: Product) => {
    const typeIndex = productTypes.findIndex((type) => product.product_type_id === type.id);
    if (typeIndex > -1) {
      return productTypes[typeIndex].name[props.app.language.code];
    }
    return '-';
  };

  const onProductClick = (product: Product) => {
    props.hidePopup();
    // props.history.push(`${settings.products.shoppingPage}${product.slug}`);
  };

  const wishListItems = props.wishlist.items;

  const productType = settings.products.style;

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.hidePopup();
      }}
      onOk={() => {
        props.hidePopup();
      }}
      width={window.outerWidth < 1420 ? '90%' : '70%'}
      footer={[
        <Button
          key='Close'
          onClick={() => {
            props.hidePopup();
          }}
        >
          Close
        </Button>
      ]}
    >
      <Row id={'wishlist-modal'} className={'products-container'}>
        <Col xs={24}>
          {wishListItems.length > 0 ? (
            <Row>
              <h2>{t('pages:wishlist.title')}</h2>
              <hr />
              <Col xs={24}>
                <Row gutter={[20, 20]}>
                  {wishListItems.map((product, pindex) => {
                    const image =
                      product.pictures && product.pictures.length > 0 ? product.pictures[0] : null;
                    return (
                      <Col
                        key={pindex}
                        xs={24}
                        sm={12}
                        lg={8}
                        className={productType === 'boxed' ? 'product-boxed-col' : 'product-col'}
                      >
                        <div className={'product'}>
                          <Row className={'product-image'}>
                            <Col xs={24}>
                              <Link to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${product.slug}`}>
                                {image && (
                                  <img
                                    alt={product.summary[props.app.language.code]}
                                    src={process.env.REACT_APP_API_URL + image}
                                  />
                                )}
                              </Link>
                            </Col>
                          </Row>
                          <div className={'product-details-container'}>
                            <Row>
                              <Col xs={24}>
                                <div className={'product-details'}>
                                  <div style={{ float: 'left' }}>
                                    {product.name[props.app.language.code]}
                                  </div>

                                  <div
                                    className={'product-price'}
                                    style={{ float: 'right' }}
                                    dangerouslySetInnerHTML={{
                                      __html: formatPrice(
                                        product.price,
                                        props.app.currency,
                                        props.app.country,
                                        product.sale,
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} className={'product-actions'}>
                                <span className={'product-actions-category-label'}>
                                  {getProductCategory(product)} -&nbsp;
                                </span>
                                {getProductType(product)}
                                <div>
                                  <Button
                                    onClick={() => onProductClick(product)}
                                    block={window.innerWidth < 968}
                                    style={{ float: 'left' }}
                                    type={'primary'}
                                  >
                                    {t('pages:wishlist.button.view.text')}
                                  </Button>
                                  <Button
                                    block={window.innerWidth < 968}
                                    onClick={() => handleRemoveItemClick(product)}
                                    type={'dashed'}
                                  >
                                    {t('pages:wishlist.button.remove.text')}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ) : (
            <Row align={'middle'} justify={'center'} className={'no-result-container'}>
              <Result
                icon={<HeartOutlined />}
                status='info'
                title={t('pages:wishlist.no_items.title')}
                subTitle={t('pages:wishlist.no_items.subtitle')}
                extra={
                  settings.navbar.wishlist.style !== 'popup' && (
                    <Link to={settings.products.shoppingPage}>
                      <Button size={'large'} type='primary' key='console'>
                        {t('pages:wishlist.no_items.button.go_shopping.text')}
                      </Button>
                    </Link>
                  )
                }
              />
            </Row>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(WishlistModal);
