import React, { Component } from 'react';
import { settings } from '../../../settings';
import FooterLargeComponent from './footers/footer-default.component';
import FooterBasicComponent from './footers/footer-basic.component';
import FooterMinimalComponent from './footers/footer-minimal.component';

class FooterComponent extends Component {
  render() {
    const navbarSettings = settings.footer;

    switch (navbarSettings.type) {
      case 'basic':
        return <FooterBasicComponent />;
      case 'minimal':
        return <FooterMinimalComponent />;
      default:
        return <FooterLargeComponent />;
    }
  }
}

export default FooterComponent;
