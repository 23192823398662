import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import NavbarFloatingComponent from '../navigation/navbar-floating.component';
import { Helmet } from 'react-helmet';
import { LoopBack } from '../../redux/api';
import { Page, PageSection } from '../../types';
import HeaderModule from './modules/header.module';
import { CenteredParagraphModule } from './modules/centered-paragraph.module';
import { ParagraphModule } from './modules/paragraph.module';
import HowItWorks from './modules/how-it-works.module';
import HighlightedProductsModule from './modules/highlighted-products.module';
import { ImageWallModule } from './modules/image-wall.module';
import { CollapsedListItemModule } from './modules/collapsed-list-item.module';
import { ColumnLayoutModule } from './modules/column-layout.module';
import WebshopModule from './modules/webshop.module';
import { prerenderIsReady } from '../../helpers/prerender-ready.helper';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/states/app';
import { useTranslation } from 'react-i18next';
import { BannerModule } from './modules/banner.module';
import { SliderModule } from './modules/slider.module';
import UpcomingEventsModule from './modules/upcoming-events.module';
import UpcomingProductsModule from './modules/upcoming-products.module';
import { PhotoGalleryModule } from './modules/photo-gallery.module';
import { TwoColoredBlocksModule } from './modules/two-colored-blocks.module';
import { TextWithPictureBlockModule } from './modules/text-with-picture-block.module';
import { ICartState } from '../../redux/states/cart';
import GiftCardsModule from './modules/gift-cards.module';
import SkeletonComponent from '../app/skeleton.component';
import { settings } from '../../../settings';
import DonationModule from './modules/donation.module';
import SliderPartnersModule from './modules/slider-partners.module';
import { ImagesModule } from './modules/images.module';
import { LinkTreeModule } from './modules/link-tree.module';
import WaitingListSignUpModule from './modules/waitinglist-signup';
import { useLocation, useNavigate } from 'react-router-dom';
import WebshopBoxedModule from './modules/webshop-boxed.module';
import { LookbooksModule } from './modules/lookbooks.module';
import { GridModule } from './modules/grid.module';
import { InstagramFeed } from './modules/instagram-feed.module';
import NewsletterSignup from './modules/newsletter-signup.module';
import SliderVideosModule from './modules/slider-videos.module';

const { Content } = Layout;

interface Props {
  app: IAppState;
  cart: ICartState;
}

const DynamicComponent = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<Page | null>(null);
  const [path, setPath] = useState(location.pathname);
  const { t } = useTranslation();

  useEffect(() => {
    if (page === null || (location.pathname !== path && !loading)) {
      setLoading(true);
      getPage();
    }
  }, [location]);

  const getPage = () => {
    const path = location.pathname.substr(1).split('/').join('%2F');
    new LoopBack()
      .get(`/pages/%2F${path}`)
      .then((res) => {
        if (res === null) {
          navigate('/404');
        }
        setLoading(false);
        setPath(location.pathname);
        setPage(res);

        if (window.location.href.includes('#')) {
          const id = window.location.href.split('#');
          if (id) {
            const element = document.getElementById(id[1]);
            if (element) {
              element.scrollIntoView();
            }
          }
        } else {
          window.scrollTo(0, 0);
        }
        prerenderIsReady();
      })
      .catch((err) => {
        setLoading(false);
        setPath(window.location.pathname);
        prerenderIsReady();
      });
  };

  const getComponent = (section: PageSection, index: number) => {
    section.id = `section-${index}`;
    section.language = props.app.language.code;
    let component = <React.Fragment key={section.id} />;
    switch (section.type) {
      case 'header':
        component = <HeaderModule key={section.id} section={section} />;
        break;
      case 'paragraph':
        component = (
          <ParagraphModule key={section.id} {...section} language={props.app.language.code} />
        );
        break;
      case 'centered_paragraph':
        component = (
          <CenteredParagraphModule
            key={section.id}
            {...section}
            language={props.app.language.code}
          />
        );
        break;
      case 'how_it_works':
        component = <HowItWorks key={section.id} {...section} language={props.app.language.code} />;
        break;
      case 'slider_partners':
        component = <SliderPartnersModule key={section.id} section={section} />;
        break;
      case 'slider_videos':
        component = <SliderVideosModule key={section.id} section={section} />;
        break;
      case 'highlighted_products':
        component = <HighlightedProductsModule key={section.id} section={section} />;
        break;
      case 'image_wall':
        component = <ImageWallModule key={section.id} {...section} />;
        break;
      case 'donation_module':
        component = <DonationModule key={section.id} section={section} />;
        break;
      case 'grid':
        component = <GridModule key={section.id} {...section} />;
        break;
      case 'instagram_feed':
        component = <InstagramFeed key={section.id} {...section} />;
        break;
      case 'slider':
        component = <SliderModule key={section.id} {...section} />;
        break;
      case 'lookbooks':
        component = <LookbooksModule key={section.id} {...section} />;
        break;
      case 'photo_gallery':
        component = <PhotoGalleryModule key={section.id} {...section} />;
        break;
      case 'two_colored_blocks':
        component = <TwoColoredBlocksModule key={section.id} {...section} />;
        break;
      case 'text_with_picture_block':
        component = <TextWithPictureBlockModule key={section.id} {...section} />;
        break;
      case 'upcoming_events':
        component = <UpcomingEventsModule key={section.id} section={section} />;
        break;
      case 'upcoming_products':
        component = <UpcomingProductsModule key={section.id} section={section} />;
        break;
      case 'gift_cards':
        component = <GiftCardsModule key={section.id} section={section} />;
        break;
      case 'images':
        component = <ImagesModule key={section.id} {...section} />;
        break;
      case 'link_tree':
        component = <LinkTreeModule key={section.id} {...section} />;
        break;
      case 'collapsed_list_items':
        component = <CollapsedListItemModule key={section.id} {...section} />;
        break;
      case 'waiting_list_signup':
        component = <WaitingListSignUpModule key={section.id} section={section} />;
        break;
      case 'newsletter_signup':
        component = <NewsletterSignup key={section.id} section={section} />;
        break;
      case 'webshop':
        component = loading ? (
          <span>{t('products:loading.text')}</span>
        ) : settings.products.style === 'boxed' ? (
          <WebshopBoxedModule key={section.id} section={section} />
        ) : (
          <WebshopModule key={section.id} section={section} />
        );
        break;
      case 'banner':
        component = <BannerModule key={section.id} />;
        break;
      case 'column_layout':
        component = (
          <ColumnLayoutModule key={section.id} {...section} language={props.app.language.code} />
        );
        break;
    }
    return component;
  };

  const voucher = props.cart.voucher;
  const className = voucher && voucher.exists && voucher.voucher ? 'voucher-applied' : '';

  return (
    <Layout className={page ? 'page-' + (page.slug === '/' ? 'home' : page.slug.substr(1)) : ''}>
      {!loading && page && (
        <Helmet>
          <title>{page.title[props.app.language.code]}</title>
          <meta name='description' content={page.description[props.app.language.code]} />
          <script>window.prerenderReady = false;</script>
          <link rel='canonical' href={window.location.origin + window.location.pathname} />
          <meta property='og:title' content={page.title[props.app.language.code]} />
          <meta property='og:description' content={page.description[props.app.language.code]} />
          <meta property='og:url' content={window.location.origin + window.location.pathname} />
          <meta name='twitter:title' content={page.title[props.app.language.code]} />
          <meta name='twitter:description' content={page.description[props.app.language.code]} />
        </Helmet>
      )}
      <NavbarComponent />
      <Content
        className={`${props.app.showCategoriesBar ? 'has-categories-bar ' + className : className}`}
      >
        {settings.navbar.linksStyle === 'floating' && <NavbarFloatingComponent />}

        {loading ? (
          <SkeletonComponent />
        ) : (
          page &&
          page.sections.map((section, index) => {
            return getComponent(section, index);
          })
        )}
      </Content>
      <FooterComponent />
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

export default connect(mapStateToProps)(DynamicComponent);
