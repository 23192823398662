import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export default function Translation(lang: string): any {
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      ns: ['app', 'auth', 'pages', 'products', 'cart'],
      defaultNS: 'auth',
      lng: lang,
      fallbackLng: 'en',
      debug: false,
      keySeparator: false,
      saveMissing: true,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: true
      },
      backend: {
        loadPath: `${process.env.REACT_APP_API_URL}/locales/{{lng}}/{{ns}}.json`,
        addPath: `${process.env.REACT_APP_API_URL}/translations/add/{{lng}}/{{ns}}`,
        crossDomain: true
      }
    });
}
