import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Steps } from 'antd';
import { useWindowWidth } from '../../../helpers/use-window-width.helper';
import { PageSection } from '../../../types';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../styles/modules/how-it-works.less';

const { Step } = Steps;

const progressDot = (dot: any, { index }: any, section: PageSection) => {
  const step = stepMapper(index, section.settings);
  return <img alt={step.title} src={process.env.REACT_APP_API_URL + step.icon} />;
};

const stepMapper = (index: number, settings: any) => {
  switch (index) {
    case 0:
      return settings.step_one;
    case 1:
      return settings.step_two;
    case 2:
      return settings.step_three;
    case 3:
      return settings.step_four;
    case 4:
      return settings.step_five;
  }
};

const HowItWorks = (section: PageSection) => {
  const windowWidth = useWindowWidth();
  const settings = section.settings;

  return (
    <Row id={section.uid ? section.uid : undefined} key={section.id} className='how-it-works-row'>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 20, offset: 2 }}
        xl={{ span: 18, offset: 3 }}
        xxl={{ span: 16, offset: 4 }}
        className='how-it-works-column'
      >
        <div className='how-it-works-container'>
          <h2>{settings.title[section.language]}</h2>
          {settings.button.show && (
            <Link to={settings.button.url}>
              {settings.button.text[section.language]} <ArrowRightOutlined />
            </Link>
          )}
          <Row>
            <Steps
              progressDot={(dot: any, index: any) => progressDot(dot, index, section)}
              direction={windowWidth <= 767 ? 'vertical' : 'horizontal'}
            >
              {settings.step_one && (
                <Step
                  className={`steps-${settings.num_of_steps}`}
                  key={1}
                  title={stepMapper(0, settings).title[section.language]}
                  description={stepMapper(0, settings).description[section.language]}
                />
              )}
              {settings.step_two && (
                <Step
                  className={`steps-${settings.num_of_steps}`}
                  key={2}
                  title={stepMapper(1, settings).title[section.language]}
                  description={stepMapper(1, settings).description[section.language]}
                />
              )}
              {settings.step_three && (
                <Step
                  className={`steps-${settings.num_of_steps}`}
                  key={3}
                  title={stepMapper(2, settings).title[section.language]}
                  description={stepMapper(2, settings).description[section.language]}
                />
              )}
              {settings.step_four && settings.num_of_steps > 3 && (
                <Step
                  className={`steps-${settings.num_of_steps}`}
                  key={4}
                  title={stepMapper(3, settings).title[section.language]}
                  description={stepMapper(3, settings).description[section.language]}
                />
              )}
              {settings.step_five && settings.num_of_steps > 4 && (
                <Step
                  className={`steps-${settings.num_of_steps}`}
                  key={5}
                  title={stepMapper(4, settings).title[section.language]}
                  description={stepMapper(4, settings).description[section.language]}
                />
              )}
            </Steps>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default HowItWorks;
