import React from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import { AuthState, User } from '../../redux/states/user';
import { LoopBack } from '../../redux/api';
import { updateUser } from '../../redux/actions/auth';
import Swal from 'sweetalert2';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Country } from '../../types';
import { SelectValue } from 'antd/es/select';
import { setCountry } from '../../redux/actions/app';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { settings } from '../../../settings';
import {ICartState} from "../../redux/states/cart";

interface Props extends WithTranslation {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  updateUser: typeof updateUser;
  setCountry: typeof setCountry;
}

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

interface State {
  different_delivery_address: boolean;
  different_invoice_address: boolean;
}

class ProfileFormComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      different_delivery_address: this.props.auth.user.different_delivery_address,
      different_invoice_address: this.props.auth.user.different_invoice_address
    };

    window.scrollTo(0, 0);
  }

  switchDifferentDeliveryAddress = (e: boolean) => {
    this.setState({ different_delivery_address: e });
    if (e && this.formRef && this.formRef.current) {
      const currentCustomerDetails = this.formRef.current.getFieldValue('customer');
      const deliveryDetails = {
        ...currentCustomerDetails,
        street_name: null,
        door_number: null,
        postal_code: null
      };
      this.formRef.current.setFieldsValue({ delivery: deliveryDetails });
    }
  };

  switchDifferentInvoiceAddress = (e: boolean) => {
    this.setState({ different_invoice_address: e });
    if (e && this.formRef && this.formRef.current) {
      const currentCustomerDetails = this.formRef.current.getFieldValue('customer');
      const invoiceDetails = {
        ...currentCustomerDetails,
        street_name: null,
        door_number: null,
        postal_code: null
      };
      this.formRef.current.setFieldsValue({ invoice: invoiceDetails });
    }
  };

  componentDidMount(): void {
    if (this.props.auth.isLoggedIn) {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ ...this.props.auth.user });
        if (this.props.auth.user.customer === undefined || this.props.auth.user.customer === null) {
          this.formRef.current.setFieldsValue({
            customer: { email: this.props.auth.user.email }
          });
        }
      }
    }
  }

  onCountryChange = (e: SelectValue) => {
    if (e) {
      const country = this.props.app.countries.filter(
        (country) => country.id.toString() === e.toString()
      );
      this.props.setCountry(country[0]);
    }
  };

  onFinish = (values: any) => {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    new LoopBack(this.props.auth.token)
      .patch('/users/me', values)
      .then((res) => {
        this.props.updateUser(res.user, res.token);
        Swal.fire({
          title: t('auth:profile.popup.update.success.title'),
          html: t('auth:profile.popup.update.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:profile.popup.update.success.button.ok')
        });
      })
      .catch((err) => {
        Swal.fire({
          title: t('auth:profile.popup.update.error.title'),
          html: t('auth:profile.popup.update.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:profile.popup.update.error.button.ok')
        });
      });
  };

  render() {
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Form
        {...formItemLayout}
        ref={this.formRef}
        name='register'
        onFinish={this.onFinish}
        scrollToFirstError
      >
        <div className={'form-section'} style={{ marginBottom: 0 }}>
          <Link to={'/cart'} style={{ float: 'right' }}>
            {t('auth:profile.button.to_shopping_cart.text')} <ArrowRightOutlined />
          </Link>
          <h2>{t('auth:profile.section.my_details.title')}</h2>
          <hr />
          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('first_name') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'first_name']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.first_name.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.first_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
            {settings.cart.checkoutFields.includes('last_name') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'last_name']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.last_name.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.last_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('street_name') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'street_name']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.street_name.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.street_name.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
            {settings.cart.checkoutFields.includes('door_number') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'door_number']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.door_number.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.door_number.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('postal_code') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'postal_code']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.postal_code.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.postal_code.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
            {settings.cart.checkoutFields.includes('city') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'city']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.city.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.city.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
            {settings.cart.checkoutFields.includes('state') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'state']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.state.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.state.placeholder')}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('country') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'country']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.country.warning')
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.country.placeholder')}
                    onChange={this.onCountryChange}
                  >
                    {this.props.app.countries.map((country) => {
                      return (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={12}>
              <Form.Item
                name={['customer', 'email']}
                rules={[
                  {
                    type: 'email',
                    message: t('auth:profile.section.my_details.form.not_valid_email.warning')
                  },
                  {
                    required: true,
                    message: t('auth:profile.section.my_details.form.email.warning')
                  }
                ]}
              >
                <Input
                  size={'large'}
                  placeholder={t('auth:profile.section.my_details.form.email.placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            {settings.cart.checkoutFields.includes('phone') && (
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['customer', 'phone']}
                  rules={[
                    {
                      required: true,
                      message: t('auth:profile.section.my_details.form.phone.warning')
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.phone.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {settings.cart.checkoutFields.includes('company') && (
              <Col xs={24} lg={12}>
                <Form.Item name={['customer', 'company']} rules={[{ required: false }]}>
                  <Input
                    size={'large'}
                    placeholder={t('auth:profile.section.my_details.form.company.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </div>
        {settings.cart.enableDifferentDeliveryAddress && (
          <Row>
            <Col xs={24} className={'form-action-highlight'}>
              <Form.Item
                valuePropName='checked'
                label={t('auth:profile.section.my_details.form.different_delivery_address.label')}
                name={'different_delivery_address'}
                rules={[{ required: false, type: 'boolean' }]}
              >
                <Switch onChange={(e) => this.switchDifferentDeliveryAddress(e)} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {settings.cart.enableDifferentDeliveryAddress && this.state.different_delivery_address && (
          <div className={'form-section'} style={{ marginBottom: 0 }}>
            <h2>{t('auth:profile.section.my_details.different_delivery.title')}</h2>
            <hr />
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'first_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.first_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.first_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'last_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.last_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.last_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'street_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.street_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.street_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'door_number']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.door_number.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.door_number.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'postal_code']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.postal_code.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.postal_code.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'city']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.city.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.city.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'state']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.state.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.state.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'country']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.country.warning'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.country.placeholder'
                    )}
                  >
                    {this.props.app.countries.map((country) => {
                      return (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'email']}
                  rules={[
                    {
                      type: 'email',
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.not_valid_email.warning'
                      )
                    },
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.email.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.email.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['delivery', 'phone']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_delivery.phone.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.phone.placeholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name={['delivery', 'company']} rules={[{ required: false }]}>
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_delivery.company.placeholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        {settings.cart.enableDifferentInvoiceAddress && (
          <Row>
            <Col xs={24} className={'form-action-highlight'}>
              <Form.Item
                label={t('auth:profile.section.my_details.form.different_invoice.label')}
                name={'different_invoice_address'}
                valuePropName='checked'
              >
                <Switch onChange={(e) => this.switchDifferentInvoiceAddress(e)} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {settings.cart.enableDifferentInvoiceAddress && this.state.different_invoice_address && (
          <div className={'form-section'} style={{ marginBottom: 0 }}>
            <h2>{t('auth:profile.section.my_details.different_invoice.title')}</h2>
            <hr />
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'first_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.first_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.first_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'last_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.last_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.last_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'street_name']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.street_name.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.street_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'door_number']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.door_number.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.door_number.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'postal_code']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.postal_code.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.postal_code.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'city']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.city.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.city.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'state']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.state.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.state.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'country']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.country.warning'
                      )
                    }
                  ]}
                >
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.country.placeholder'
                    )}
                  >
                    {this.props.app.countries.map((country) => {
                      return (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'email']}
                  rules={[
                    {
                      type: 'email',
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.not_valid_email.warning'
                      )
                    },
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.email.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.email.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name={['invoice', 'phone']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'auth:profile.section.my_details.form.different_invoice.phone.warning'
                      )
                    }
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.phone.placeholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name={['invoice', 'company']} rules={[{ required: false }]}>
                  <Input
                    size={'large'}
                    placeholder={t(
                      'auth:profile.section.my_details.form.different_invoice.company.placeholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={10}>
          <Col xs={24}>
            <Form.Item>
              <Button block={true} size={'large'} type='primary' htmlType='submit'>
                {t('auth:profile.section.my_details.form.button.save.text')}
              </Button>
            </Form.Item>
            <Link to={'/cart'} style={{ float: 'right' }}>
              {t('auth:profile.section.my_details.form.button.go_shopping.text')}{' '}
              <ArrowRightOutlined />
            </Link>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (user: User, token: string) => dispatch(updateUser(user, token)),
  setCountry: (country: Country) => dispatch(setCountry(country))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileFormComponent));
