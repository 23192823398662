import { CartItem, Voucher } from '../../types';

export interface ICartState {
  items: CartItem[];
  events: CartItem[];
  giftCards: CartItem[];
  voucher: Voucher | null;
  voucherLoading: boolean;
}

export const CartState: ICartState = {
  items: [],
  events: [],
  giftCards: [],
  voucherLoading: false,
  voucher: null
};
