import React, { useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Tabs } from 'antd';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AuthState } from '../../redux/states/user';
import { IAppState } from '../../redux/states/app';
import { removeItemFromWishList } from '../../redux/actions/wishlist';
import { ICartState } from '../../redux/states/cart';
import { IWishListState } from '../../redux/states/wishlist';
import { Country, Currency } from '../../types';
import Swal from 'sweetalert2';
import { LoopBack } from '../../redux/api';
import { debounce } from '../../helpers/debounce.helper';
import { FormInstance } from 'antd/es/form';
import { TFunction } from 'i18next';
import { logoutUser, userLoginFetch } from '../../redux/actions/auth';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import ProfileFormComponent from '../auth/profile-form.component';
import ProfileOrdersComponent from '../auth/profile-orders.component';
import ChangePasswordFormComponent from '../auth/change-password-form.component';
import SettingsFormComponent from '../auth/settings-form.component';

const { TabPane } = Tabs;

interface Props {
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  removeItemFromWishList: typeof removeItemFromWishList;
  wishlist: IWishListState;
  hidePopup: () => void;
  visible: boolean;
  logoutUser: typeof logoutUser;
  userLoginFetch: typeof userLoginFetch;
}

const AuthModal = (props: Props) => {
  const formRef = React.createRef<FormInstance>();
  const [forgetPassword, setForgetPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    const data = {
      ...values,
      language: props.app.language.code
    };
    new LoopBack()
      .post('/users/register', data)
      .then((res) => {
        Swal.fire({
          title: t('auth:register.popup.success.title'),
          html: t('auth:register.popup.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:register.popup.success.button.ok')
        });
        if (formRef && formRef.current) {
          formRef.current.resetFields();
        }
        props.hidePopup();
      })
      .catch((err) => {
        if (err.status === 422) {
          Swal.fire({
            title: t('auth:register.popup.error.exists.title'),
            html: t('auth:register.popup.error.exists.description'),
            icon: 'error',
            confirmButtonText: t('auth:register.popup.error.exists.button.ok')
          });
        } else {
          Swal.fire({
            title: t('auth:register.popup.error.title'),
            html: t('auth:register.popup.error.description'),
            icon: 'error',
            confirmButtonText: t('auth:register.popup.error.button.ok')
          });
        }
      });
  };

  const onFinishForgot = (values: any) => {
    const data = {
      ...values,
      language: props.app.language.code
    };

    new LoopBack(props.auth.token)
      .post('/users/reset-mail', data)
      .then((res) => {
        Swal.fire({
          title: t('auth:forgot_password.popup.success.title'),
          html: t('auth:forgot_password.popup.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:forgot_password.popup.success.button.ok')
        });
        setForgetPassword(false);
        props.hidePopup();
      })
      .catch((err) => {
        Swal.fire({
          title: t('auth:forgot_password.popup.error.title'),
          html: t('auth:forgot_password.popup.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:forgot_password.popup.error.button.ok')
        });
      });
  };

  const onKeyUp = () => {
    if (formRef && formRef.current) {
      const values = formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        setShowPasswordError(true);
      } else {
        setShowPasswordError(false);
      }
    }
  };

  const onFinishLogin = (values: any) => {
    props.userLoginFetch(values, props.app.countries, props.app.currencies, t);
  };

  const linkText = t('auth:register.form.agreement.link.text');

  let buttons = [
    <Button
      key='Close'
      onClick={() => {
        props.hidePopup();
      }}
    >
      Close
    </Button>
  ];
  if (props.auth.isLoggedIn) {
    buttons = [
      <Button
        id={'auth-modal-logout'}
        key='Logout'
        onClick={() => {
          props.logoutUser();
          props.hidePopup();

          Swal.fire({
            title: t('auth:logout.popup.success.title'),
            html: t('auth:logout.popup.success.description'),
            icon: 'success',
            confirmButtonText: t('auth:logout.popup.success.button.ok')
          });
        }}
      >
        Logout
      </Button>,
      ...buttons
    ];
  }

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.hidePopup();
      }}
      onOk={() => {
        props.hidePopup();
      }}
      width={window.outerWidth < 1420 ? '90%' : '70%'}
      footer={buttons}
    >
      {!forgetPassword ? (
        <>
          {props.auth.user && props.auth.isLoggedIn ? (
            <Row id={'auth-modal'} gutter={[60, 60]}>
              <Col xs={24}>
                <Tabs defaultActiveKey='1' tabPosition={window.outerWidth < 768 ? 'top' : 'left'}>
                  <TabPane tab={t('auth:profile.section.my_details.tab.title')} key={'1'}>
                    <ProfileFormComponent />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.order_history.tab.title')} key={'2'}>
                    <h2>{t('auth:profile.section.order_history.title')}</h2>
                    <hr />
                    <ProfileOrdersComponent />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.my_account.tab.title')} key={'3'}>
                    <h2>{t('auth:profile.section.my_account.title')}</h2>
                    <hr />
                    <ChangePasswordFormComponent />
                  </TabPane>
                  <TabPane tab={t('auth:profile.section.settings.tab.title')} key={'4'}>
                    <SettingsFormComponent />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          ) : (
            <Row id={'auth-modal'} gutter={[60, 60]}>
              <Col xs={24} sm={12}>
                <Form layout='vertical' onFinish={onFinishLogin}>
                  <h2>{t('auth:popup.login.title')}</h2>
                  <p>{t('auth:popup.login.description')}</p>
                  <Form.Item
                    name='email'
                    rules={[{ required: true, message: t('cart:login.form.email.warning') }]}
                  >
                    <Input size={'large'} placeholder={t('cart:login.form.email.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: 20 }}
                    name='password'
                    rules={[{ required: true, message: t('car:login.form.password.warning') }]}
                  >
                    <Input.Password
                      size={'large'}
                      placeholder={t('cart:login.form.password.placeholder')}
                    />
                  </Form.Item>
                  <span
                    className={'app-link'}
                    onClick={() => setForgetPassword(true)}
                    style={{ float: 'right', marginTop: 10, marginBottom: 10 }}
                  >
                    {t('auth:login.button.forgot_password.text')} <ArrowRightOutlined />
                  </span>
                  <Button
                    size={'large'}
                    block={true}
                    type={'primary'}
                    htmlType='submit'
                    loading={props.auth.loading}
                  >
                    {t('auth:login.button.login.text')}
                  </Button>
                </Form>
              </Col>
              <Col xs={24} sm={12}>
                <Form
                  layout={'vertical'}
                  name='register'
                  ref={formRef}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <h2>{t('auth:popup.register.title')}</h2>
                  <p>{t('auth:popup.register.description')}</p>
                  <Row gutter={10}>
                    <Col xs={24}>
                      {showPasswordError && (
                        <Alert
                          message={t('auth:register.alert.title')}
                          description={t('auth:register.alert.description')}
                          type={'warning'}
                          showIcon={true}
                          closable={false}
                        />
                      )}

                      <Form.Item
                        name={'email'}
                        rules={[
                          {
                            type: 'email',
                            message: t('auth:register.form.email.warning.not_a_valid_email')
                          },
                          {
                            required: true,
                            message: t('auth:register.form.email.warning')
                          }
                        ]}
                      >
                        <Input
                          size={'large'}
                          placeholder={t('auth:register.form.email.placeholder')}
                        />
                      </Form.Item>

                      <Form.Item
                        name='password'
                        rules={[
                          {
                            required: true,
                            message: t('auth:register.form.new_password.warning')
                          }
                        ]}
                      >
                        <Input
                          size={'large'}
                          type={'password'}
                          placeholder={t('auth:register.form.new_password.placeholder')}
                        />
                      </Form.Item>

                      <Form.Item
                        name='password_repeat'
                        rules={[
                          {
                            required: true,
                            message: t('auth:register.form.new_password_repeat.warning')
                          }
                        ]}
                      >
                        <Input
                          size={'large'}
                          type={'password'}
                          placeholder={t('auth:register.form.repeat_new_password.placeholder')}
                          onKeyUp={debounce(onKeyUp, 800)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={10}>
                    <Col xs={24}>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name='news_letter'
                        valuePropName='checked'
                      >
                        <Checkbox>{t('auth:register.form.news_letter.label')}</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name='agreement'
                        valuePropName='checked'
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(t('auth:register.form.agreement.warning'))
                          }
                        ]}
                      >
                        <Checkbox>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t('auth:register.form.agreement.label', {
                                link: `<a href="/terms-and-conditions" rel='noopener noreferrer' target="_blank">${linkText}</a>`,
                                interpolation: { escapeValue: false }
                              })
                            }}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={10}>
                    <Col xs={24}>
                      <Form.Item>
                        <Button block={true} size={'large'} type='primary' htmlType='submit'>
                          {t('auth:register.form.button.register.text')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row id={'auth-modal'}>
          <Col xs={24} sm={12}>
            <Form
              layout={'vertical'}
              name='register'
              ref={formRef}
              onFinish={onFinishForgot}
              scrollToFirstError
            >
              <h2>{t('auth:forgot_password.block.title')}</h2>
              <div className={'form-section'}>
                <Row gutter={10}>
                  <Col xs={24}>
                    <Form.Item
                      name='email'
                      extra={t('auth:forgot_password.form.email.extra')}
                      rules={[
                        { required: true, message: t('auth:forgot_password.form.email.warning') }
                      ]}
                    >
                      <Input
                        size={'large'}
                        type={'email'}
                        placeholder={t('auth:forgot_password.form.email.placeholder')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Row gutter={10}>
                <Col xs={24}>
                  <Form.Item>
                    <Button block={true} size={'large'} type='primary' htmlType='submit'>
                      {t('auth:forgot_password.button.text')}
                    </Button>
                    <div
                      className={'app-link'}
                      onClick={() => setForgetPassword(false)}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <ArrowLeftOutlined /> {t('auth:login.button.forgot_password.cancel')}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id)),
  userLoginFetch: (userInfo: any, countries: Country[], currencies: Currency[], t: TFunction) =>
    dispatch(userLoginFetch(userInfo, countries, currencies, t))
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AuthModal);
