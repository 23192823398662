export interface User {
  id: number;
  email: string | null;
  email_verified: boolean;
  customer: {
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    company: string | null;
    street_name: string | null;
    door_number: string | null;
    postal_code: string | null;
    city: string | null;
    country: number | null;
  } | null;
  different_delivery_address: boolean;
  delivery: {
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    company: string | null;
    street_name: string | null;
    door_number: string | null;
    postal_code: string | null;
    city: string | null;
    country: string | null;
  } | null;
  different_invoice_address: boolean;
  invoice: {
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    company: string | null;
    street_name: string | null;
    door_number: string | null;
    postal_code: string | null;
    city: string | null;
    country: string | null;
  } | null;
  news_letter: boolean;
  card_holder: string;
}

export interface AuthState {
  token: string;
  user: User;
  isLoggedIn: boolean;
  loading: boolean;
}

export const UserState: AuthState = {
  token: '',
  user: {
    id: -1,
    email: null,
    customer: null,
    different_delivery_address: false,
    different_invoice_address: false,
    invoice: null,
    delivery: null,
    email_verified: false,
    news_letter: false,
    card_holder: ''
  },
  isLoggedIn: false,
  loading: false
};
