import { Col, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';
import { Parallax } from 'react-parallax';
import '../../../styles/modules/link-tree.less';

export const LinkTreeModule = (section: PageSection) => {
  const style = section.settings.background_color
    ? { backgroundColor: section.settings.background_color }
    : {};

  let buttonStyle = {};
  if (section.settings.button_color) {
    Object.assign(buttonStyle, { backgroundColor: section.settings.button_color });
  }

  let bgClass = '';
  if (section.settings.background_image) {
    bgClass = ' has-bg-image';
  }

  const getRow = () => {
    return (
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-link-tree${bgClass}`}
        align={'middle'}
        justify={'center'}
        style={style}
      >
        <Col xs={{ span: 22 }} sm={20}>
          {section.settings.title && (
            <>
              {section.settings.title && <h2>{section.settings.title}</h2>}
              {section.settings.subtitle && <em>{section.settings.subtitle}</em>}
            </>
          )}

          <Row className={'link-tree-items'}>
            {section.settings.items.map((item: { title: string; url: string }) => {
              return (
                <Col xs={24}>
                  <a
                    style={buttonStyle}
                    target={'_blank'}
                    className={'link-tree-item'}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {section.settings.background_image ? (
        <Parallax
          bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
          bgImageAlt=''
          strength={200}
        >
          {getRow()}
        </Parallax>
      ) : (
        getRow()
      )}
    </>
  );
};
