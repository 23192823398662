import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Dropdown, Layout, Menu, Row } from 'antd';
import { AuthState } from '../../redux/states/user';
import { Link, useLocation } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import { settings } from '../../../settings';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import ShoppingCartComponent from './shopping-cart.component';
import { getMenu } from './helpers/menu.helper';
import '../../styles/header.less';
import {
  DownOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import AuthModal from '../popups/auth.modal';
import { logoutUser } from '../../redux/actions/auth';
import {ICartState} from "../../redux/states/cart";

const { Header } = Layout;

interface Props extends WithTranslation {
  auth: AuthState;
  cart: ICartState;
  logoutUser: typeof logoutUser;
  app: IAppState;
}

const NavbarComponent = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(0);
  const [showUserAccountModal, setShowUserAccountModal]: [boolean, any] = useState(false);
  const [menuVisible, setMenuVisible]: [boolean, any] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', saveScrollTopValue);

    return () => {
      window.removeEventListener('scroll', saveScrollTopValue);
    };
  }, []);

  const saveScrollTopValue = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let scrollTop = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    setScrollTop(scrollTop);
  };

  const logoutMenu = () => {
    const customUserIcon = settings.navbar.userAccount.customIcon;

    return (
      <Menu>
        <Menu.Item>
          <Link to={'/profile'}>
            {customUserIcon ? (
              <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
            ) : (
              <UserOutlined />
            )}
            &nbsp;{t('app:navbar.menu.auth.my_account.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/change-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.change_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => props.logoutUser()}>
          <LogoutOutlined /> {t('app:navbar.menu.auth.logout.label')}
        </Menu.Item>
      </Menu>
    );
  };

  const loginMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to={'/login'}>
            <LoginOutlined /> {t('app:navbar.menu.auth.go_to_login.label')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/forgot-password'}>
            <KeyOutlined /> {t('app:navbar.menu.auth.forgot_password.label')}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to={'/register'}>
            <Button style={{ width: 400 }} type={'primary'}>
              {t('app:navbar.menu.auth.button.text')}
            </Button>
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  const userAccountIcon = () => {
    const userAccountStyle = settings.navbar.userAccount.style;
    const customUserIcon = settings.navbar.userAccount.customIcon;

    switch (userAccountStyle) {
      case 'popup':
        return (
          <span id={'login-menu'}>
            {customUserIcon ? (
              <img
                alt={'profile icon'}
                src={require(`../../../assets/${customUserIcon}`)}
                onClick={() => setShowUserAccountModal(true)}
              />
            ) : (
              <UserOutlined onClick={() => setShowUserAccountModal(true)} />
            )}
            <AuthModal
              visible={showUserAccountModal}
              hidePopup={() => setShowUserAccountModal(false)}
            />
          </span>
        );
      default:
        if (props.auth.isLoggedIn) {
          return (
            <Dropdown overlay={logoutMenu()}>
              <span>
                {customUserIcon ? (
                  <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
                ) : (
                  <UserOutlined />
                )}
                <span className={'dropdown-label'}>
                  &nbsp;
                  {props.auth.user && props.auth.user.customer
                    ? props.auth.user.customer.first_name
                    : t('app:navbar.menu.auth.my_account.label')}
                  &nbsp;
                </span>
                <DownOutlined />
              </span>
            </Dropdown>
          );
        } else {
          return (
            <span id={'login-menu'}>
              <Dropdown overlay={loginMenu()}>
                <span>
                  {customUserIcon ? (
                    <img alt={'profile icon'} src={require(`../../../assets/${customUserIcon}`)} />
                  ) : (
                    <UserOutlined />
                  )}
                  <span className={'dropdown-label'}>
                    &nbsp;
                    {t('app:navbar.menu.auth.login.label')}
                  </span>{' '}
                  <DownOutlined />
                </span>
              </Dropdown>
            </span>
          );
        }
    }
  };

  const apiSettings = props.app.settings;
  const voucher = props.cart.voucher;
  let headerClassName = `header-${apiSettings?.navbar_settings?.type}`;

  if (scrollTop >= settings.addClassToHeaderOnScroll) {
    headerClassName += ' header-scrolled';
  }
  const dropdownContainer = document.getElementById('header-navbar');

  return (
    <Header id={`header-navbar`} className={headerClassName}>
      {voucher && voucher.exists && (
        <Row id={'header-voucher'}>
          <Col xs={24} lg={0} className={'voucher-text'}>
            {t('app:navbar.voucher.title.mobile', { text: voucher.voucher.name })}
          </Col>
          <Col xs={0} lg={24} className={'voucher-text'}>
            {voucher.voucher.navbarText[props.app.language.code]}
          </Col>
        </Row>
      )}
      <Row id={'header-main'}>
        <Col xs={24} xl={{ span: 18, offset: 3 }}>
          <Row>
            <Col xs={4}>
              <Link id={'header-main-logo'} to={'/'}>
                <img
                  alt={`Logo ${settings.appName}`}
                  src={require('../../../assets/logo-color.png')}
                />
              </Link>
            </Col>
            {apiSettings && (
              <Col xs={20}>
                {window.outerWidth > 930 ? (
                  <>
                    <div id={'header-main-auth-menu'}>
                      <ShoppingCartComponent />
                      {userAccountIcon()}
                    </div>
                    <nav>
                      {getMenu(apiSettings, props.app.language.code, 'horizontal', location)}
                    </nav>
                  </>
                ) : (
                  <span>
                    <Dropdown
                      getPopupContainer={() =>
                        dropdownContainer ? dropdownContainer : document.body
                      }
                      overlay={getMenu(
                        apiSettings,
                        props.app.language.code,
                        'horizontal',
                        location
                      )}
                      trigger={['click']}
                      onOpenChange={(e) => setMenuVisible(e)}
                    >
                      <div id={'header-menu-mobile'}>
                        <div id='nav-hamburger' className={menuVisible ? 'open' : ''}>
                          <span />
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    </Dropdown>
                    <div id={'header-main-auth-menu'}>
                      <ShoppingCartComponent />
                      {userAccountIcon()}
                    </div>
                  </span>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavbarComponent));
