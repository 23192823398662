import { Button, Col, Row } from 'antd';

import React from 'react';
import { GiftCard, PageSection } from '../../../types';
import { LoopBack } from '../../../redux/api';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../styles/modules/upcoming-events.less';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
}

interface State {
  giftCards: GiftCard[];
}

class DonationModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      giftCards: []
    };
  }

  getDonationLink = (amount: number) => {
    new LoopBack()
      .post('/donation-link', { amount: amount, currencyCode: this.props.app.currency?.code })
      .then((res) => {
        window.location.href = res.formUrl;
      });
  };

  render() {
    const t: any = this.props.i18n.t.bind(this.props.i18n);
    const section = this.props.section;
    const styles = section.settings.background_color
      ? { backgroundColor: section.settings.background_color }
      : {};
    return (
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={'cms-module-upcoming-events'}
        style={styles}
      >
        <Col xs={24} sm={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }}>
          <Row gutter={[30, 30]} justify={'center'}>
            <Col xs={24} lg={21} xl={6}>
              <h2>{section.settings.title[this.props.app.language.code]}</h2>

              <div
                dangerouslySetInnerHTML={{
                  __html: section.settings.description[this.props.app.language.code]
                }}
              />
            </Col>
            {section.settings.donation_options.map((option: any, index: number) => {
              return (
                <Col key={index} xs={24} sm={12} lg={7} xl={6}>
                  <div className={'upcoming-event'}>
                    <div className={'upcoming-event-image-container'}>
                      {/*<Link to={`/gift-card${giftCard.slug}`}>*/}
                      <img
                        onClick={() => this.getDonationLink(option.amount)}
                        alt={option.title[this.props.app.language.code]}
                        src={process.env.REACT_APP_API_URL + option.image.url}
                      />
                      {/*</Link>*/}
                    </div>
                    <div className={'upcoming-event-description'}>
                      <h3>{option.title[this.props.app.language.code]}</h3>

                      {/*<Link to={`/gift-card${giftCard.slug}`}>*/}
                      <Button type={'primary'} onClick={() => this.getDonationLink(option.amount)}>
                        <ArrowRightOutlined /> {t('pages:donations.button.donate')}
                      </Button>
                      {/*</Link>*/}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect<any, any, any>(mapStateToProps)(withTranslation()(DonationModule));
