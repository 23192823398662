import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Col, Drawer, Dropdown, Menu, Result, Row } from 'antd';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined
} from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import { Link } from 'react-router-dom';
import { IAppState } from '../../redux/states/app';
import { settings } from '../../../settings';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { CartItem, Product } from '../../types';
import { formatPrice } from '../../helpers/price.helper';
import {
  removeEventFromCart,
  removeGiftCardFromCart,
  removeItemFromCart
} from '../../redux/actions/cart';
import { gtagUpdateEventRemoval, gtagUpdateProductRemoval } from '../../helpers/gtag';
import ShoppingListComponent from '../pages/cart/shopping-list.component';
import {ICartState} from "../../redux/states/cart";

interface Props extends WithTranslation {
  auth: AuthState;
  cart: ICartState;
  app: IAppState;
  removeItemFromCart: typeof removeItemFromCart;
  removeEventFromCart: typeof removeEventFromCart;
  removeGiftCardFromCart: typeof removeGiftCardFromCart;
}

const ShoppingCartComponent = (props: Props) => {
  const [showShoppingBagDrawer, setShowShoppingBagDrawer] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const { t } = useTranslation();

  const handleVisibleChange = (flag: boolean) => {
    setCartVisible(flag);
  };

  const handleRemoveItem = (item: CartItem) => {
    if (item.product) {
      let id = `${item.product.id}-${item.size.id}${
        item.custom_text ? `-${item.custom_text}` : ''
      }`;
      gtagUpdateProductRemoval(item, props.app.language.code);
      props.removeItemFromCart(id);
    }
    if (item.event) {
      let id = item.event.id.toString();
      gtagUpdateEventRemoval(item, props.app.language.code);
      props.removeEventFromCart(id);
    }

    if (item.giftCard) {
      let id = item.giftCard.id.toString();
      props.removeGiftCardFromCart(id);
    }
  };

  const productPrice = (item: CartItem) => {
    let price = item.product.price;
    price += item.size.price;

    price *= item.quantity;

    if (item.product.sale) {
      price = price * (1 - (item.product.sale / 100));
    }

    return price;
  };

  const shoppingList = () => {
    const t: any = props.i18n.t.bind(props.i18n);
    const products = props.cart.items;
    let totalPrice = 0;
    if (products && products.length > 0) {
      totalPrice += products.reduce((c: number, item: CartItem) => productPrice(item) + c, 0);
    }

    const events = props.cart.events;
    if (events && events.length > 0) {
      totalPrice += events.reduce(
        (c: number, item: CartItem) => item.event.price * item.quantity + c + item.extra_amount,
        0
      );
    }

    const giftCards = props.cart.giftCards;
    if (giftCards && giftCards.length > 0) {
      totalPrice += giftCards.reduce(
        (c: number, item: CartItem) => item.giftCard.price * item.quantity + c,
        0
      );
    }

    let menu = (
      <Menu>
        <Menu.Item>
          <div className={'menu-no-items'}>
            <>
              <InfoCircleOutlined /> {t('app:navbar.menu.cart.no_items')}
            </>
          </div>
        </Menu.Item>
        {settings.cart.showGoHomeShoppingCartButton &&
          window.location.pathname !== settings.products.shoppingPage && <Menu.Divider />}
        {settings.cart.showGoHomeShoppingCartButton &&
          window.location.pathname !== settings.products.shoppingPage && (
            <Menu.Item key={'button'}>
              <Link to={settings.products.shoppingPage}>
                <Button style={{ width: 400 }} type={'primary'}>
                  {t('app:navbar.menu.cart.button.go_to_shop.text')}
                </Button>
              </Link>
            </Menu.Item>
          )}
      </Menu>
    );

    if (products.length > 0 || events.length > 0 || giftCards.length > 0) {
      menu = (
        <Menu>
          {products.map((item: CartItem, index: number) => {
            return (
              <Menu.Item key={index}>
                <Row>
                  <Col xs={16}>
                    <Link
                      to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${item.product.slug}`}
                    >
                      {item.product.name[props.app.language.code]}
                    </Link>
                    &nbsp;({item.size.name})&nbsp;
                    <DeleteOutlined
                      style={{ marginLeft: 5 }}
                      onClick={() => handleRemoveItem(item)}
                    />
                  </Col>
                  <Col xs={2} className={'menu-amount'}>
                    {item.quantity}x
                  </Col>
                  <Col xs={6} className={'menu-price'}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatPrice(
                          (item.product.price + item.size.price) * item.quantity,
                          props.app.currency,
                          props.app.country,
                          item.product.sale
                        )
                      }}
                    />
                  </Col>
                </Row>
              </Menu.Item>
            );
          })}
          {events &&
            events.map((item: CartItem, index: number) => {
              return (
                <Menu.Item key={index}>
                  <Row>
                    <Col xs={16}>
                      <Link to={`${settings.events.rootUrl}${item.event.slug}`}>
                        {item.event.name[props.app.language.code]}
                      </Link>
                      <DeleteOutlined
                        style={{ marginLeft: 5 }}
                        onClick={() => handleRemoveItem(item)}
                      />
                    </Col>
                    <Col xs={2} className={'menu-amount'}>
                      {item.quantity}x
                    </Col>
                    <Col xs={6} className={'menu-price'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            item.event.price * item.quantity + item.extra_amount,
                            props.app.currency,
                            props.app.country
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
              );
            })}
          {giftCards &&
            giftCards.map((item: CartItem, index: number) => {
              return (
                <Menu.Item key={index}>
                  <Row>
                    <Col xs={16}>
                      <Link to={`/gift-card${item.giftCard.slug}`}>
                        {item.giftCard.name[props.app.language.code]}
                      </Link>
                      <DeleteOutlined
                        style={{ marginLeft: 5 }}
                        onClick={() => handleRemoveItem(item)}
                      />
                    </Col>
                    <Col xs={2} className={'menu-amount'}>
                      {item.quantity}x
                    </Col>
                    <Col xs={6} className={'menu-price'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            item.giftCard.price * item.quantity,
                            props.app.currency,
                            props.app.country
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Menu.Item>
              );
            })}
          <Menu.Divider />
          <Menu.Item>
            <Row>
              <Col xs={{ span: 4, offset: 12 }} className={'menu-total'}>
                <strong>{t('app:navbar.menu.cart.total')}</strong>
              </Col>
              <Col xs={8} className={'menu-total-price'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatPrice(totalPrice, props.app.currency, props.app.country)
                  }}
                />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item key={'button'}>
            <Link to={'/cart'}>
              <Button style={{ width: 400 }} type={'primary'}>
                {t('app:navbar.menu.cart.button.checkout.text')} <ArrowRightOutlined />
              </Button>
            </Link>
          </Menu.Item>
        </Menu>
      );
    }
    return menu;
  };

  let count = 0;
  const products = props.cart.items;
  const events = props.cart.events;
  const giftCards = props.cart.giftCards;
  const customIcon = settings.navbar.shoppingBag.customIcon;
  if (products) {
    count += products.length;
  }

  if (events) {
    count += events.length;
  }

  if (giftCards) {
    count += giftCards.length;
  }

  if (settings.navbar.shoppingBag.popupStyle === 'drawer') {
    return (
      <>
        <span id={'shopping-cart'} onClick={() => setShowShoppingBagDrawer(true)}>
          {customIcon ? (
            <Badge className={'header-cart-badge'} count={count}>
              <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`)} />
            </Badge>
          ) : (
            <Badge className={'header-cart-badge'} count={count}>
              {settings.navbar.shoppingBag.icon === 'cart' ? (
                <ShoppingCartOutlined />
              ) : (
                <ShoppingOutlined />
              )}
            </Badge>
          )}
        </span>
        <Drawer
          className={'shopping-cart-drawer'}
          width={'30%'}
          title={t('products:navbar.menu.cart.title')}
          placement='right'
          closable={true}
          onClose={() => setShowShoppingBagDrawer(false)}
          open={showShoppingBagDrawer}
        >
          {settings.navbar.shoppingBag.listStyle === 'compact' && shoppingList()}
          {settings.navbar.shoppingBag.listStyle === 'default' && (
            <>
              {props.cart.items.length === 0 ? (
                <Result
                  icon={<ShoppingCartOutlined />}
                  status='info'
                  title={t('pages:cart.no_products.title')}
                  extra={
                    <Link to={settings.products.shoppingPage}>
                      <Button size={'large'} type='primary' key='console'>
                        {t('pages:cart.no_products.button.go_shopping.text')} <ArrowRightOutlined />
                      </Button>
                    </Link>
                  }
                />
              ) : (
                <>
                  <ShoppingListComponent forceVoucherView={false} />
                  <Row>
                    <Col xs={24}>
                      <Link to={'/cart'}>
                        <Button block={true} type={'primary'}>
                          Proceed to Checkout <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Drawer>
      </>
    );
  } else {
    return window.innerWidth < 768 ? (
      <span id={'shopping-cart'}>
        <Dropdown
          overlay={shoppingList}
          onOpenChange={handleVisibleChange}
          open={cartVisible}
        >
          <Badge className={'header-cart-badge'} count={count}>
            {customIcon ? (
              <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`)} />
            ) : (
              <ShoppingCartOutlined />
            )}
          </Badge>
        </Dropdown>
      </span>
    ) : (
      <Link id={'shopping-cart'} to={'/cart'}>
        <Dropdown
          overlay={shoppingList}
          onOpenChange={handleVisibleChange}
          open={cartVisible}
        >
          <Badge className={'header-cart-badge'} count={count}>
            {customIcon ? (
              <img alt={'shopping cart icon'} src={require(`../../../assets/${customIcon}`)} />
            ) : (
              <ShoppingCartOutlined />
            )}
          </Badge>
        </Dropdown>
      </Link>
    );
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  removeEventFromCart: (id: string) => dispatch(removeEventFromCart(id)),
  removeGiftCardFromCart: (id: string) => dispatch(removeGiftCardFromCart(id))
});

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShoppingCartComponent));
