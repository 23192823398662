import React, { Component } from 'react';
import { Col, Layout, Row } from 'antd';
import {
  EnvironmentOutlined,
  FacebookOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  TwitterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../../redux/states/app';
import CookieBannerComponent from '../../app/cookie-banner.component';
import { clearCookieConsent } from '../../../redux/actions/app';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { ReactComponent as PinterestFilled } from '../../../assets/logo-pinterest.svg';
import { ReactComponent as EtsyFilled } from '../../../assets/logo-etsy.svg';
import '../../../styles/footer-large.less';

const { Footer } = Layout;

interface Props extends WithTranslation {
  clearCookieConsent: typeof clearCookieConsent;
  app: IAppState;
}

class FooterDefaultComponent extends Component<Props> {
  render() {
    const apiSettings = this.props.app.settings;
    const address = apiSettings && apiSettings.addresses && apiSettings.addresses[0];
    const t: any = this.props.i18n.t.bind(this.props.i18n);

    return (
      <Footer className={'footer-large'}>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
            <div id={'footer-logo-container'}>
              <img
                alt={`Logo ${settings.appName}`}
                src={require('../../../../assets/logo-white.png')}
              />
            </div>
            <hr />
            <Row gutter={[0, 40]}>
              {apiSettings?.footer_settings && (
                <Col xs={12} xl={8}>
                  <h4>{t('app:footer.relevant_links.title')}</h4>
                  <Row>
                    <Col xs={24}>
                      <nav>
                        <ul>
                          {apiSettings.footer_settings.relevant_links &&
                            apiSettings.footer_settings.relevant_links.links.map((link, lindex) => {
                              return (
                                <li key={lindex}>
                                  <Link to={link.url}>
                                    <RightOutlined /> {link.name[this.props.app.language.code]}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                    </Col>
                  </Row>
                </Col>
              )}

              {apiSettings?.footer_settings && (
                <Col xs={12} xl={8}>
                  <h4>{t('app:footer.customer_service.title')}</h4>

                  <Row>
                    <Col xs={24}>
                      <nav>
                        <ul>
                          {apiSettings.footer_settings.customer_service_links &&
                            apiSettings.footer_settings.customer_service_links.links.map(
                              (link, lindex) => {
                                return (
                                  <li key={lindex}>
                                    <Link to={link.url}>
                                      <RightOutlined /> {link.name[this.props.app.language.code]}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          <li
                            className={'additional-links'}
                            key={'consent'}
                            onClick={() => this.props.clearCookieConsent()}
                          >
                            <span>
                              <RightOutlined /> {t('app:footer.cookie_consent.label')}
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xs={24} md={24} xl={8}>
                <h4 className='footer-heading'>{t('app:footer.contact.title')}</h4>
                {address && (
                  <Row gutter={[0, 30]}>
                    <Col xs={24} sm={24}>
                      {address.title}
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col lg={1} xl={3} xxl={2}>
                          <EnvironmentOutlined />
                        </Col>
                        <Col lg={23} xl={21} xxl={22}>
                          <div>{address.address}</div>
                          <div>
                            {address.postal_code} {address.city}
                          </div>
                          <div>{address.country}</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <div>
                        <strong>
                          <PhoneOutlined />
                        </strong>{' '}
                        {address.phone}
                      </div>
                      <div>
                        <MailOutlined /> {address.email}
                      </div>
                    </Col>
                  </Row>
                )}

                {apiSettings?.footer_settings?.social_media_links && (
                  <h4 className='footer-heading'>{t('app:footer.social_media.title')}</h4>
                )}

                {apiSettings?.footer_settings?.social_media_links && (
                  <Row>
                    <Col xs={24} id={'footer-social-media-icons'}>
                      {apiSettings?.footer_settings?.social_media_links.instagram && (
                        <a href={apiSettings?.footer_settings?.social_media_links.instagram}>
                          <InstagramOutlined />
                        </a>
                      )}
                      {apiSettings?.footer_settings?.social_media_links.twitter && (
                        <a href={apiSettings?.footer_settings?.social_media_links.twitter}>
                          <TwitterOutlined />
                        </a>
                      )}
                      {apiSettings?.footer_settings?.social_media_links.facebook && (
                        <a href={apiSettings?.footer_settings?.social_media_links.facebook}>
                          <FacebookOutlined />
                        </a>
                      )}
                      {apiSettings?.footer_settings?.social_media_links.pinterest && (
                        <a
                          className={'custom-svg'}
                          href={apiSettings?.footer_settings?.social_media_links.pinterest}
                        >
                          <PinterestFilled />
                        </a>
                      )}
                      {apiSettings?.footer_settings?.social_media_links.etsy && (
                        <a
                          className={'custom-svg'}
                          href={apiSettings?.footer_settings?.social_media_links.etsy}
                        >
                          <EtsyFilled />
                        </a>
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={24} id={'footer-bottom-container'}>
                {t('app:footer.title.bottom', { text: moment().year() })}
              </Col>
            </Row>
          </Col>
        </Row>

        <CookieBannerComponent />
      </Footer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  clearCookieConsent: () => dispatch(clearCookieConsent())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FooterDefaultComponent));
