import {
  ADD_CART_ITEM,
  ADD_EVENT_TO_CART,
  ADD_GIFT_CARD_TO_CART,
  CLEAR_CART,
  CLEAR_VOUCHER,
  ENABLE_GIFT_CARD_RECEIVER_EMAIL,
  REMOVE_CART_ITEM,
  REMOVE_EVENT_FROM_CART,
  REMOVE_GIFT_CARD_FROM_CART,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_GIFT_CARD_RECEIVER_EMAIL,
  VOUCHER_ERROR,
  VOUCHER_RECEIVE,
  VOUCHER_REQUEST
} from '../types';
import { CartState } from '../states/cart';

export default function cart(state = CartState, action: any) {
  let items = state.items ? state.items : [];
  let events = state.events ? state.events : [];
  let giftCards = state.giftCards ? state.giftCards : [];

  switch (action.type) {
    case ADD_EVENT_TO_CART:
      const cartItemEventIndex = events.findIndex((item) => item.id === action.payload.id);
      if (cartItemEventIndex > -1) {
        const cartItem = events[cartItemEventIndex];
        cartItem.quantity += action.payload.quantity;
        events[cartItemEventIndex] = cartItem;
      } else {
        events.push(action.payload);
      }
      return {
        ...state,
        events: events
      };
    case REMOVE_EVENT_FROM_CART:
      const eventExists = events.some((item) => item.id.toString() === action.payload);
      let newEventItems = events.filter((item) => item.id.toString() !== action.payload);
      if (!eventExists) {
        newEventItems = []; // clear cart otherwise item will not be removed
      }
      return {
        ...state,
        events: newEventItems
      };
    case ADD_GIFT_CARD_TO_CART:
      const cartItemGiftCardIndex = giftCards.findIndex((item) => item.id === action.payload.id);
      if (cartItemGiftCardIndex > -1) {
        const cartItem = giftCards[cartItemGiftCardIndex];
        cartItem.quantity += action.payload.quantity;
        giftCards[cartItemGiftCardIndex] = cartItem;
      } else {
        giftCards.push(action.payload);
      }
      return {
        ...state,
        giftCards: giftCards
      };
    case REMOVE_GIFT_CARD_FROM_CART:
      const giftCardExists = giftCards.some((item) => item.id.toString() === action.payload);
      let newGiftCardItems = giftCards.filter((item) => item.id.toString() !== action.payload);
      if (!giftCardExists) {
        newGiftCardItems = []; // clear cart otherwise item will not be removed
      }
      return {
        ...state,
        giftCards: newGiftCardItems
      };
    case UPDATE_GIFT_CARD_RECEIVER_EMAIL:
      const cartItemGiftCardIndexA = giftCards.findIndex((item) => item.id === action.payload.id);
      if (cartItemGiftCardIndexA > -1) {
        const giftCard = giftCards[cartItemGiftCardIndexA];
        giftCard.custom_text = action.payload.value;
        giftCards[cartItemGiftCardIndexA] = giftCard;
      }
      return {
        ...state,
        giftCards: giftCards
      };
    case ENABLE_GIFT_CARD_RECEIVER_EMAIL:
      const cartItemGiftCardIndexB = giftCards.findIndex((item) => item.id === action.payload.id);
      if (cartItemGiftCardIndexB > -1) {
        const giftCard = giftCards[cartItemGiftCardIndexB];
        giftCard.custom_receiver = action.payload.value;
        giftCards[cartItemGiftCardIndexB] = giftCard;
      }
      return {
        ...state,
        giftCards: giftCards
      };
    case UPDATE_CART_ITEM_QUANTITY:
      const productIndex = items.findIndex((item) => item.id === action.payload.item.id);
      if (productIndex > -1) {
        const cartItem = items[productIndex];
        cartItem.quantity = action.payload.quantity;
        items[productIndex] = cartItem;
      } else {
        const eventIndex = events.findIndex((item) => item.id === action.payload.item.id);
        if (eventIndex > -1) {
          const cartItem = events[eventIndex];
          cartItem.quantity = action.payload.quantity;
          events[eventIndex] = cartItem;
        } else {
          const giftCardIndex = giftCards.findIndex((item) => item.id === action.payload.item.id);
          if (giftCardIndex > -1) {
            const cartItem = giftCards[giftCardIndex];
            cartItem.quantity = action.payload.quantity;
            giftCards[giftCardIndex] = cartItem;
          }
        }
      }
      return {
        ...state,
        events: events,
        items: items,
        giftCards: giftCards
      };
    case ADD_CART_ITEM:
      const cartItemIndex = items.findIndex((item) => item.id === action.payload.id);
      if (cartItemIndex > -1) {
        const cartItem = items[cartItemIndex];
        cartItem.quantity += action.payload.quantity;
        items[cartItemIndex] = cartItem;
      } else {
        items.push(action.payload);
      }
      return {
        ...state,
        items: items
      };
    case REMOVE_CART_ITEM:
      const exists = items.some((item) => item.id === action.payload);
      let newItems = items.filter((item) => item.id.toString() !== action.payload);
      if (!exists) {
        newItems = []; // clear cart otherwise item will not be removed
      }
      return {
        ...state,
        items: newItems
      };
    case CLEAR_CART:
      return {
        ...state,
        items: [],
        events: []
      };
    case VOUCHER_REQUEST:
      return {
        ...state,
        voucherLoading: true
      };
    case VOUCHER_RECEIVE:
      return {
        ...state,
        voucher: action.payload,
        voucherLoading: false
      };
    case VOUCHER_ERROR:
      return {
        ...state,
        voucherLoading: false
      };
    case CLEAR_VOUCHER:
      return {
        ...state,
        voucherLoading: false,
        voucher: null
      };
    default:
      return state;
  }
}
