import React, { useEffect, useState } from 'react';
import { PageSection } from '../../../types';
import { Carousel, Col, Row } from 'antd';
import { LoopBack } from '../../../redux/api';
import '../../../styles/modules/instagram-feed.less';
import { Link, useNavigate } from 'react-router-dom';

export const InstagramFeed = (section: PageSection) => {
  const [pictures, setPictures] = useState<{ uid: string; name: string; url: string }[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    new LoopBack()
      .get('/containers/instagram/files')
      .then((res: any) => {
        let files: any = [];
        res.forEach((file: any) => {
          const uploadedFile = {
            uid: file.mtime,
            name: file.name,
            url: process.env.REACT_APP_API_URL + `/${file.container}/${file.name}`,
            status: 'done'
          };
          files.push(uploadedFile);
        });
        files = files
          .sort((a: any, b: any) => (a.uid > b.uid ? 1 : -1))
          .slice(0, section.settings.num_of_images);
        setPictures(files);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        setPictures([]);
      });
  }, []);

  const getColWidth = (numOfColumns: number) => {
    if (numOfColumns === 2) {
      return 12;
    } else if (numOfColumns === 3) {
      return 8;
    } else if (numOfColumns === 4) {
      return 6;
    } else if (numOfColumns === 5 || numOfColumns === 6) {
      return 4;
    } else if (numOfColumns === 7 || numOfColumns === 8) {
      return 3;
    }
    return 6;
  };

  return (
    <Row id={section.uid ? section.uid : undefined} className={'cms-module-instagram-feed'}>
      <Col xs={24}>
        {section.settings.title && <h2>{section.settings.title}</h2>}
        {section.settings.subtitle && (
          <div
            className={'cms-module-instagram-feed-subtitle'}
            dangerouslySetInnerHTML={{ __html: section.settings.subtitle[section.language] }}
          />
        )}
      </Col>
      <Col xs={24}>
        <Row className={'cms-module-instagram-feed-container'}>
          <Carousel
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              }
            ]}

            useTransform={false}
            pauseOnHover={false}
            swipeToSlide={false}
            autoplaySpeed={2500}
            dots={false}
            autoplay
            variableWidth={false}
            slidesPerRow={1}
            slidesToShow={5}
            slidesToScroll={1}
          >
            {pictures.map((picture, index) => {
              return (
                <div key={index}>
                  <a target={'_blank'} href={'https://instagram.com/' + section.settings.instagram_profile}>
                  <img src={picture.url} />
                  </a>
                </div>
              );
            })}
          </Carousel>
        </Row>
      </Col>
    </Row>
  );
};
