import { Button, Col, Result, Row } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import Layout from 'antd/lib/layout';
import { Helmet } from 'react-helmet';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { BannerModule } from '../pages/modules/banner.module';
import { settings } from '../../../settings';
import { useLocation } from 'react-router-dom';
import { FrownOutlined } from '@ant-design/icons';

const NotFoundComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Layout>
      <Helmet>
        <title>{t('app:404.meta.title')}</title>
        <meta name='description' content={t('app:404.meta.description')} />
        <meta name='robots' content='noindex,nofollow' />
        <meta name='prerender-status-code' content='404' />
        <link rel='canonical' href={window.location.origin + location.pathname} />
      </Helmet>
      <NavbarComponent />
      <Layout.Content style={{ paddingTop: 0 }}>
        <div>
          <Row style={{ height: '100vh' }} justify={'space-around'} align={'middle'}>
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Result
                // status='404'
                icon={<FrownOutlined />}
                title={t('app:404.message.title')}
                subTitle={t('app:404.message.subtitle')}
                extra={
                  <a href={'/'}>
                    <Button icon={<HomeOutlined />} type={'primary'} size={'large'}>
                      {t('app:404.button.text')}
                    </Button>
                  </a>
                }
              />
            </Col>
          </Row>
        </div>
        {settings.showCompanyBanner && <BannerModule />}
        <FooterComponent />
      </Layout.Content>
    </Layout>
  );
};

export default NotFoundComponent;
