import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection } from '../../../types';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../styles/modules/two-colored-blocks.less';

export const TwoColoredBlocksModule = (section: PageSection) => {
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-two-colored-blocks'}
    >
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }}>
        <Row>
          <Col xs={24} sm={12}>
            <div
              className={'colored-block colored-block-first'}
              style={{ backgroundColor: section.settings.first_block.background_color }}
            >
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  xl={{ span: 10, order: 0 }}
                  xxl={{ span: 8, order: 0 }}
                  className={'colored-block-description'}
                >
                  <h3>{section.settings.first_block.title[section.language]}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: section.settings.first_block.description[section.language]
                    }}
                  />
                  <Link to={section.settings.first_block.button.url}>
                    <Button type={'primary'}>
                      {section.settings.first_block.button.text[section.language]}{' '}
                      <ArrowRightOutlined />
                    </Button>
                  </Link>
                </Col>
                <Col
                  xs={{ span: 24, order: 0 }}
                  xl={{ span: 14, order: 1 }}
                  xxl={{ span: 16, order: 1 }}
                >
                  <img
                    src={process.env.REACT_APP_API_URL + section.settings.first_block.image_url}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div
              className={'colored-block colored-block-last'}
              style={{ backgroundColor: section.settings.second_block.background_color }}
            >
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  xl={{ span: 10, order: 0 }}
                  xxl={{ span: 8, order: 0 }}
                  className={'colored-block-description'}
                >
                  <h3>{section.settings.second_block.title[section.language]}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: section.settings.second_block.description[section.language]
                    }}
                  />
                  <Link to={section.settings.second_block.button.url}>
                    <Button type={'primary'}>
                      {section.settings.second_block.button.text[section.language]}{' '}
                      <ArrowRightOutlined />
                    </Button>
                  </Link>
                </Col>
                <Col
                  xs={{ span: 24, order: 0 }}
                  xl={{ span: 14, order: 1 }}
                  xxl={{ span: 16, order: 1 }}
                >
                  <img
                    src={process.env.REACT_APP_API_URL + section.settings.second_block.image_url}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
