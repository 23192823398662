export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const ADD_CART_ITEM = 'ADD_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_ITEM';
export const CLEAR_CART = 'CLEAR_CART';

export const ADD_EVENT_TO_CART = 'ADD_EVENT_TO_CART';
export const REMOVE_EVENT_FROM_CART = 'REMOVE_EVENT_FROM_CART';

export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';

export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export const CLEAR_WISHLIST = 'CLEAR_WISHLIST';

export const SHOW_CATEGORIES_BAR = 'SHOW_CATEGORIES_BAR';
export const HIDE_CATEGORIES_BAR = 'HIDE_CATEGORIES_BAR';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_RECEIVE = 'SETTINGS_RECEIVE';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';

export const CURRENCIES_REQUEST = 'CURRENCIES_REQUEST';
export const CURRENCIES_RECEIVE = 'CURRENCIES_RECEIVE';
export const CURRENCIES_ERROR = 'CURRENCIES_ERROR';
export const SET_CURRENCY = 'SET_CURRENCY';

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_RECEIVE = 'COUNTRIES_RECEIVE';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR';
export const SET_COUNTRY = 'SET_COUNTRY';

export const VOUCHER_REQUEST = 'VOUCHER_REQUEST';
export const VOUCHER_RECEIVE = 'VOUCHER_RECEIVE';
export const VOUCHER_ERROR = 'VOUCHER_ERROR';
export const CLEAR_VOUCHER = 'CLEAR_VOUCHER';

export const ACCEPT_COOKIE_CONSENT = 'ACCEPT_COOKIE_CONSENT';
export const CLEAR_COOKIE_CONSENT = 'CLEAR_COOKIE_CONSENT';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const LOAD_LANGUAGE = 'LOAD_LANGUAGE';
export const DONE_LOADING_LANGUAGE = 'DONE_LOADING_LANGUAGE';

export const ADD_GIFT_CARD_TO_CART = 'ADD_GIFT_CARD_TO_CART';
export const REMOVE_GIFT_CARD_FROM_CART = 'REMOVE_GIFT_CARD_FROM_CART';
export const UPDATE_GIFT_CARD_RECEIVER_EMAIL = 'UPDATE_GIFT_CARD_RECEIVER_EMAIL';
export const ENABLE_GIFT_CARD_RECEIVER_EMAIL = 'ENABLE_GIFT_CARD_RECEIVER_EMAIL';

export interface ApiError {
  message: string;
  statusCode: number;
  name: string;
}
