import { Col, Collapse, Row } from 'antd';
import React from 'react';
import { PageSection } from '../../../types';

const { Panel } = Collapse;

export const CollapsedListItemModule = (section: PageSection) => {
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-collapsed-list-items'}
      align={'middle'}
      justify={'center'}
    >
      <Col xs={{ span: 22 }} sm={20}>
        {section.settings.title && (
          <>
            <h2>{section.settings.title}</h2>
            <em>{section.settings.subtitle}</em>
          </>
        )}

        <Collapse
          accordion={true}
          bordered={false}
          defaultActiveKey={['0']}
          className={'list-item-container'}
        >
          {section.settings.items.map((item: { title: string; content: string }, index: number) => (
            <Panel header={item.title} key={index}>
              <p style={{ paddingLeft: 24 }} dangerouslySetInnerHTML={{ __html: item.content }} />
            </Panel>
          ))}
        </Collapse>
      </Col>
    </Row>
  );
};
