import { Anchor, Menu } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { NavbarLink, Setting } from '../../../types';
import { RightOutlined } from '@ant-design/icons';

const getSublinks = (link: NavbarLink, language: string) => {
  return link.links.map((link: { name: { [key: string]: string }; url: string }, index: number) => {
    return (
      <Menu.Item key={link.url}>
        <Link to={link.url} className={'no-underline'}>
          <RightOutlined /> {link.name[language]}
        </Link>
      </Menu.Item>
    );
  });
};

const getSubMenuActive = (link: NavbarLink) => {
  let className = '';
  if (window.location.pathname === link.url) {
    className += 'active-submenu';
  }
  if (link.highlight) {
    className += ' highlight';
  }
  return className;
};

export const getMenu = (settings: Setting, language: string, type: any, location: any) => {
  const paths = location.pathname.split('/').filter((path: string) => path !== '');
  return (
    <Menu
      id={'header-main-navigation'}
      selectedKeys={[location.pathname + location.search]}
      defaultOpenKeys={type !== 'horizontal' ? ['/' + paths[0]] : []}
      mode={type}
    >
      {settings?.navbar_settings?.bottom_bar.links?.map((link, lindex) => {
        return link.links && link.links.length > 0 ? (
          window.outerWidth < 720 ? (
            <Menu.ItemGroup key={link.url} title={link.name[language]}>
              {getSublinks(link, language)}
            </Menu.ItemGroup>
          ) : (
            <Menu.SubMenu
              key={link.url}
              title={<span>{link.name[language]}</span>}
              className={getSubMenuActive(link)}
            >
              {getSublinks(link, language)}
            </Menu.SubMenu>
          )
        ) : (
          <Menu.Item key={link.url} className={link.highlight ? 'highlighted' : ''}>
            {link.url.includes('#') ? (
              <Anchor affix={false} offsetTop={50}>
                <Anchor.Link href={link.url} title={link.name[language]} />
              </Anchor>
            ) : (
              <Link to={link.url} title={link.url}>
                {link.name[language]}
              </Link>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
