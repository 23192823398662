import React, { useState } from 'react';
import { Badge, Button, Col, Row, Select } from 'antd';
import { Color, Product, ProductCategory, ProductStyle, ProductType, Size } from '../../types';
import { Link, useNavigate } from 'react-router-dom';
import { formatPrice } from '../../helpers/price.helper';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import Swal from 'sweetalert2';
import { addItemToWishList, removeItemFromWishList } from '../../redux/actions/wishlist';
import {
  ArrowRightOutlined,
  CaretDownOutlined,
  HeartFilled,
  HeartOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';
import { IWishListState } from '../../redux/states/wishlist';
import { IAppState } from '../../redux/states/app';
import { productSchema } from '../../helpers/structured-data.helper';
import { WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../settings';
import { ICartState } from '../../redux/states/cart';
import { getProductStyle, getProductType, handleAddItemToCartClick } from '../../helpers/product.helper';
import { addItemToCart } from '../../redux/actions/cart';
import { ReactComponent as HeartIcon } from '../../../assets/icon-heart.svg';
import { ReactComponent as HeartFillIcon } from '../../../assets/icon-heart-fill.svg';

interface Props extends WithTranslation {
  index: number;
  product: Product;
  productCategories: ProductCategory[];
  productTypes: ProductType[];
  productStyles: ProductStyle[];
  sizes: Size[];
  measurement_unit: string;
  width: number;
  colors: Color[];
  spacing: string;
  app: IAppState;
  auth: AuthState;
  cart: ICartState;
  wishlist: IWishListState;
  addItemToWishList: typeof addItemToWishList;
  removeItemFromWishList: typeof removeItemFromWishList;
}

const ProductsBoxedProductComponent = (props: Props) => {
  const navigate = useNavigate();
  const [size, setSize] = useState<Size | null>(null);

  // const getProductCategory = (product: Product) => {
  //   const categoryIndex = props.productCategories.findIndex(
  //     (type) => product.product_category_id === type.id
  //   );
  //   if (categoryIndex > -1) {
  //     return props.productCategories[categoryIndex].name[props.app.language.code];
  //   }
  //   return '-';
  // };

  // const getProductType = (product: Product) => {
  //   const typeIndex = props.productTypes.findIndex((type) => product.product_type_id === type.id);
  //   if (typeIndex > -1) {
  //     return props.productTypes[typeIndex].name[props.app.language.code];
  //   }
  //   return '-';
  // };

  const getSize = (sizeId: number) => {
    return props.sizes.filter((size) => size.id === sizeId)[0];
  };

  const productIsInWishList = (product: Product) => {
    return props.wishlist.items.some((item) => item.id === product.id);
  };

  const isProductSoldOut = (product: Product) => {
    return product.manage_stock && product.stock <= 0;
  };

  const getStockMessage = (product: Product) => {
    // const t: any = props.i18n.t.bind(props.i18n);

    // if (product.manage_stock) {
    //   if (product.stock <= 0) {
    //     return <div className={'stock-message sold-out'}>{t('products:stock.out_of_stock')}</div>;
    //   } else if (product.stock === 1 && settings.products.fewLeftLimit > 1) {
    //     return (
    //       <div className={'stock-message'}>
    //         {t('products:stock.one_left', { stock: product.stock, total: product.total_stock })}
    //       </div>
    //     );
    //   } else if (product.stock === 2 && settings.products.fewLeftLimit > 2) {
    //     return (
    //       <div className={'stock-message'}>
    //         {t('products:stock.two_left', { stock: product.stock, total: product.total_stock })}
    //       </div>
    //     );
    //   } else if (product.stock === 3 && settings.products.fewLeftLimit > 3) {
    //     return (
    //       <div className={'stock-message'}>
    //         {t('products:stock.three_left', { stock: product.stock, total: product.total_stock })}
    //       </div>
    //     );
    //   } else if (product.stock > 1 && product.stock < settings.products.fewLeftLimit) {
    //     return (
    //       <div className={'stock-message'}>
    //         {t('products:stock.few_left', { stock: product.stock, total: product.total_stock })}
    //       </div>
    //     );
    //   }
    // }
    return undefined;
  };

  const onChangeSize = (sizeId: number) => {
    setSize(getSize(sizeId));
  };

  const handleAddItemToWishListClick = (product: Product) => {
    const t: any = props.i18n.t.bind(props.i18n);

    props.addItemToWishList(product);
    Swal.fire({
      title: t('products:detail.popup.wishlist.success.title'),
      html: t('products:detail.popup.wishlist.success.description', {
        text: `<strong>${product.name[props.app.language.code]}</strong>`
      }),
      icon: 'success'
    });
  };

  const getTagName = (key: string) => {
    const allTags = settings.products.tags;
    const tagIndex = allTags.findIndex((tag) =>tag.key === key);
    if (tagIndex > -1) {
      return allTags[tagIndex].name;
    }
    return '-';
  }

  const image =
    props.product.pictures && props.product.pictures.length > 0 ? props.product.pictures[0] : null;
  const productIsWished = productIsInWishList(props.product);
  const t: any = props.i18n.t.bind(props.i18n);
  const customCartIcon = settings.navbar.shoppingBag.customIcon;
  const popupContainer = document.getElementById('select-product-' + props.product.id);

  return (
    <Col
      key={props.index}
      xs={24}
      sm={12}
      lg={8}
      xl={props.width === 3 ? 8 : 6}
      className={`product-boxed-col spacing-${props.spacing}`}
    >
      <script type='application/ld+json'>
        {props.app.currency &&
          JSON.stringify(
            productSchema(props.product, props.app.currency.code, props.app.language.code)
          )}
      </script>
      <Badge.Ribbon
        color={'red'}
        className={props.product.sale === null || props.product.sale === 0 ? 'in-active' : ''}
        text={props.product.sale > 0 ? `${props.product.sale}% off` : undefined}
      >
        <div className={'product'}>

          {props.product.tags && props.product.tags.length > 0 &&
            <div style={{position: 'absolute', top: 20, left: 0, zIndex: 1, paddingLeft : 20}} className={'product-tags'}>
              {props.product.tags.map((tag,index) =>
              <div key={index} style={{display: 'inline-block',marginBottom: 5, paddingLeft: 5, color: '#aaa'}}>
                { getTagName(tag).toUpperCase()}
                {index < props.product.tags.length - 1? ' |  ' : ''}
              </div>
              )}
            </div>
          }
          <Row className={'product-image'}>
            <Col xs={24}>
              {props.product.manage_stock && getStockMessage(props.product)}
              <Link
                to={`${settings.products.shoppingPage}${settings.products.productSlugPrefix}${props.product.slug}`}
              >
                {image && (
                  <img
                    className={'product-image-img'}
                    alt={props.product.summary[props.app.language.code]}
                    src={process.env.REACT_APP_API_URL + image}
                  />
                )}
              </Link>
              {props.product.stock === null ||
                (props.product.stock > 0 && (
                  <div className={'product-quick-buy'}>
                    <Row gutter={5}>
                      <Col id={'select-product-' + props.product.id} xs={12}>
                        <Select
                          getPopupContainer={() =>
                            popupContainer ? popupContainer : document.body
                          }
                          suffixIcon={<CaretDownOutlined />}
                          onChange={(e) => onChangeSize(parseInt(e.toString()))}
                          placeholder={t('products:detail.details.sizes.placeholder')}
                          style={{ width: '100%' }}
                        >
                          {props.product.sizes.map((size, sindex) => {
                            const dbSize = getSize(size);
                            if (dbSize) {
                              return (
                                <Select.Option key={sindex} value={size}>
                                  {props.measurement_unit === 'imperial' ? (
                                    <span>{dbSize.imperial_name}</span>
                                  ) : (
                                    <span>{dbSize.metric_name}</span>
                                  )}

                                  {dbSize.price && dbSize.price > 0 ? (
                                    <span>
                                      &nbsp;(+{' '}
                                      {formatPrice(
                                        dbSize.price,
                                        props.app.currency,
                                        props.app.country
                                      )}
                                      )
                                    </span>
                                  ) : (
                                    <span />
                                  )}
                                </Select.Option>
                              );
                            }
                            return undefined;
                          })}
                        </Select>
                      </Col>
                      <Col xs={12}>
                        <Button
                          style={{ marginBottom: 10 }}
                          size={'large'}
                          onClick={() =>
                            handleAddItemToCartClick(
                              {size: size, quantity: 1},
                              props,
                              props.product,
                              props.colors,
                              navigate
                            )
                          }
                          block={true}
                          type={'primary'}
                        >
                          { props.product.enable_pre_order ? t('products:detail.button.pre_order.text')  :  t('products:detail.button.add_to_cart.text')}&nbsp;

                          {customCartIcon ? (
                            <img src={require(`../../../assets/${customCartIcon}`)} />
                          ) : (
                            <ShoppingCartOutlined />
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Col>
          </Row>
          <div className={'product-details-container'}>
            <Row>
              <Col xs={24}>
                <div className={'product-details'}>
                  <div style={{ float: 'left' }}>{props.product.name[props.app.language.code]}</div>


                  <div
                    style={{ float: 'right' }}
                  >

                    {props.product.sale > 0 &&
                      <span>
                    <div
                      style={{ display: 'inline-block', textDecoration: 'line-through' }}
                      dangerouslySetInnerHTML={{
                        __html: formatPrice(
                          props.product.price,
                          props.app.currency,
                          props.app.country,
                        )
                      }}
                    />
                        &nbsp;&nbsp;
                        <ArrowRightOutlined/>
                        &nbsp;&nbsp;
                    </span>
                    }
                    <div
                      style={{ display: 'inline-block' }}
                      dangerouslySetInnerHTML={{
                        __html: formatPrice(
                          props.product.price,
                          props.app.currency,
                          props.app.country,
                          props.product.sale
                        )
                      }}
                    />
                  </div>



                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} className={'product-actions'}>
                <span className={'product-actions-category-label'}>
                    {getProductType(
                      props.product,
                      props.app.language.code,
                      props.productTypes,
                    )}
                </span>
                {/*{getProductType(props.product)}*/}


                {' '}
                |{' '}
                {getProductStyle(
                 props.product,
                  props.app.language.code,
                  props.productStyles
                )}

                {productIsWished ? (
                  <div
                    className={`icon-button active`}
                    onClick={() => props.removeItemFromWishList(props.product.id)}
                  >
                    {settings.products.customWishlistIcon ? <HeartFillIcon /> : <HeartFilled />}
                  </div>
                ) : (
                  <div
                    className={`icon-button`}
                    onClick={() => handleAddItemToWishListClick(props.product)}
                  >
                    {settings.products.customWishlistIcon ? <HeartIcon /> : <HeartOutlined />}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Badge.Ribbon>
    </Col>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  cart: state.cart,
  wishlist: state.wishlist
});

const mapDispatchToProps = (dispatch: any) => ({
  addItemToCart: (
    product: Product,
    size: { id: number; price: number; name: string },
    quantity: number,
    custom_text: string | null,
    thread_color: string | null,
    color_name: { [key: string]: string } | null
  ) => dispatch(addItemToCart(product, size, quantity, custom_text, thread_color, color_name)),
  addItemToWishList: (product: Product) => dispatch(addItemToWishList(product)),
  removeItemFromWishList: (id: number) => dispatch(removeItemFromWishList(id))
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProductsBoxedProductComponent));
