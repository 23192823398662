import React, { useState } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { updateToken } from '../../redux/actions/auth';
import { AuthState } from '../../redux/states/user';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { debounce } from '../../helpers/debounce.helper';
import { FormInstance } from 'antd/es/form';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import '../../styles/register.less';
import { settings } from '../../../settings';
import { useNavigate } from 'react-router-dom';

interface Props {
  updateToken: typeof updateToken;
  auth: AuthState;
}

const ChangePasswordFormComponent = (props: Props) => {
  const formRef = React.createRef<FormInstance>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPasswordError, setShowPasswordError] = useState(false);

  const onFinish = (values: any) => {
    new LoopBack(props.auth.token)
      .post('/users/change-password', values)
      .then((res) => {
        Swal.fire({
          title: t('app:change_password.popup.success.title'),
          html: t('app:change_password.popup.success.description'),
          icon: 'success',
          confirmButtonText: t('auth:change_password.success.button.ok')
        });
        props.updateToken(res.token);
        if (settings.navbar.userAccount.style === 'page') {
          navigate('/profile');
        } else {
          if (formRef && formRef.current) {
            formRef.current.resetFields();
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: t('auth:change_password.popup.error.title'),
          html: t('auth:change_password.popup.error.description'),
          icon: 'error',
          confirmButtonText: t('auth:change_password.popup.error.button.ok')
        });
      });
  };

  const onKeyUp = () => {
    if (formRef && formRef.current) {
      const values = formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        setShowPasswordError(true);
      } else {
        setShowPasswordError(false);
      }
    }
  };

  return (
    <Form
      layout={'vertical'}
      name='change-password'
      ref={formRef}
      onFinish={onFinish}
      scrollToFirstError
    >
      <div className={'form-section'}>
        <Row gutter={10}>
          <Col xs={24}>
            {showPasswordError && (
              <Alert
                message={t('auth:change_password.alert.title')}
                description={t('auth:change_password.alert.description')}
                type={'warning'}
                showIcon={true}
                closable={false}
              />
            )}
            <Form.Item
              label={t('auth:change_password.form.password.label')}
              name='password'
              rules={[{ required: true, message: t('auth:change_password.form.password.warning') }]}
            >
              <Input
                type={'password'}
                placeholder={t('auth:change_password.form.password.placeholder')}
              />
            </Form.Item>

            <Form.Item
              label={t('auth:change_password.form.password_repeat.label')}
              name='password_repeat'
              rules={[
                {
                  required: true,
                  message: t('auth:change_password.form.password_repeat.warning')
                }
              ]}
            >
              <Input
                type={'password'}
                placeholder={t('auth:change_password.form.password_repeat.placeholder')}
                onKeyUp={debounce(onKeyUp, 800)}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Row gutter={10}>
        <Col xs={24}>
          <Form.Item>
            <Button block={true} size={'large'} type='primary' htmlType='submit'>
              {t('auth:change_password.form.button.text')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  updateToken: (token: string) => dispatch(updateToken(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordFormComponent);
