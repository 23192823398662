import {
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  UPDATE_TOKEN,
  UPDATE_USER
} from '../types';
import { UserState } from '../states/user';

export default function user(state = UserState, action: any) {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loading: true,
        token: ''
      };
    case AUTH_LOGIN:
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        loading: false,
        token: action.payload.token
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case AUTH_ERROR:
    case AUTH_LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loading: false,
        token: ''
      };
    default:
      return state;
  }
}
