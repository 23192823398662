import React from 'react';
import { Alert, Button, Col, Input, InputNumber, Radio, Row, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ICartState } from '../../../redux/states/cart';
import { AllCosts, CartItem, CartItemProduct, Color } from '../../../types';
import { formatPrice } from '../../../helpers/price.helper';
import { IAppState } from '../../../redux/states/app';
import { FormInstance } from 'antd/lib/form';
import VoucherComponent from './voucher.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as StandardDelivery } from '../../../../assets/icon-truck.svg';
import { ReactComponent as TrackedDelivery } from '../../../../assets/icon-checklist.svg';
import { settings } from '../../../../settings';
import {
  enableGiftCardReceiverEmail,
  removeEventFromCart,
  removeGiftCardFromCart,
  removeItemFromCart,
  updateCartItemQuantity,
  updateGiftCardReceiverEmail
} from '../../../redux/actions/cart';
import { gtagUpdateEventRemoval, gtagUpdateProductRemoval } from '../../../helpers/gtag';
import { calculateAllCosts } from '../../../helpers/calculate-all-costs';
import { DeleteOutlined } from '@ant-design/icons';
import { LoopBack } from '../../../redux/api';

interface Props extends WithTranslation {
  app: IAppState;
  forceVoucherView: boolean;
  cart: ICartState;
  onDeliveryTypeChange: (type: string) => void;
  deliveryType: string;
  updateCartItemQuantity: typeof updateCartItemQuantity;
  removeItemFromCart: typeof removeItemFromCart;
  removeEventFromCart: typeof removeEventFromCart;
  removeGiftCardFromCart: typeof removeGiftCardFromCart;
  updateGiftCardReceiverEmail: typeof updateGiftCardReceiverEmail;
  enableGiftCardReceiverEmail: typeof enableGiftCardReceiverEmail;
}

interface State {
  colors: Color[];
}

class ShoppingListComponent extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      colors: []
    };

    new LoopBack()
      .get('/colors')
      .then((res) => {
        this.setState({ colors: res });
      })
      .catch((err) => {
        this.setState({ colors: [] });
      });
  }

  updateItemQuantity = (e: number | string | undefined | null, item: CartItem) => {
    if (e) {
      this.props.updateCartItemQuantity(parseInt(e.toString()), item);
    }
  };

  handleRemoveItem = (item: CartItem) => {
    if (item.product) {
      let id = `${item.product.id}-${item.size.id}${
        item.custom_text ? `-${item.custom_text}` : ''
      }`;
      gtagUpdateProductRemoval(item, this.props.app.language.code);
      this.props.removeItemFromCart(id);
    }
    if (item.event) {
      let id = item.event.id.toString();
      gtagUpdateEventRemoval(item, this.props.app.language.code);
      this.props.removeEventFromCart(id);
    }
    if (item.giftCard) {
      let id = item.giftCard.id.toString();
      this.props.removeGiftCardFromCart(id);
    }
  };

  getColorName = (product: CartItemProduct) => {
    const colorId = product.color_id;
    const allColors = this.state.colors;
    const colorIndex = allColors.findIndex((color) => color.id === colorId);
    if (colorIndex > -1) {
      return allColors[colorIndex].name[this.props.app.language.code];
    }
    return '-';
  };

  isWhite = (value: string) => {
    return value === 'white' || value === '#fff' || value === '#ffffff';
  };

  getCurrentColor = (product: CartItemProduct) => {
    const colorId = product.color_id;
    const allColors = this.state.colors;
    const colorIndex = allColors.findIndex((color) => color.id === colorId);
    if (colorIndex > -1) {
      return allColors[colorIndex];
    }
    return null;
  };

  render() {
    const products = this.props.cart.items;
    const events = this.props.cart.events;
    const giftCards = this.props.cart.giftCards;
    const t: any = this.props.i18n.t.bind(this.props.i18n);
    const allCosts: AllCosts = calculateAllCosts(this.props);

    return (
      <>
        <h2 id={'shopping-list-title'}>{t('cart:checkout.shopping_list.title')}</h2>
        <div id={'block-1'}>
          <Row>
            {products &&
              products.map((item, index) => {
                const currentColor = this.getCurrentColor(item.product);

                return (
                  <Col key={index} xs={24} className={'cart-col'}>
                    <div className={'cart-item'}>
                      <Row>
                        <Col xs={8} lg={6}>
                          <Badge.Ribbon
                            color={'red'}
                            className={
                              item.product.sale === null || item.product.sale === 0 ? 'in-active' : ''
                            }
                            text={item.product.sale > 0 ? `${item.product.sale}% off` : undefined}
                          >
                          {item.product.image && (
                            <img
                              className={'cart-item-image'}
                              alt={item.product.name[this.props.app.language.code]}
                              src={process.env.REACT_APP_API_URL + item.product.image}
                            />
                          )}
                          </Badge.Ribbon>
                        </Col>
                        <Col xs={16} lg={18}>
                          <div className={'cart-item-details'}>
                            <div className={'cart-item-details-name'}>
                              <Link to={settings.products.shoppingPage + settings.products.productSlugPrefix + item.product.slug}>
                                <strong>{item.product.name[this.props.app.language.code]}</strong>
                              </Link>
                              &nbsp;
                              <DeleteOutlined
                                style={{ marginLeft: 5 }}
                                onClick={() => this.handleRemoveItem(item)}
                              />
                              <hr />
                            </div>
                            {item.custom_text && (
                              <div className={'cart-item-detail'}>
                                <strong>{t('cart:checkout.shopping_list.custom_text')}:</strong>
                                &nbsp;
                                {item.custom_text}
                              </div>
                            )}
                            {item.thread_color && (
                              <div className={'cart-item-detail'}>
                                <strong>{t('cart:checkout.shopping_list.thread_color')}:</strong>
                                &nbsp;
                                {item.thread_color}
                              </div>
                            )}
                            <div className={'cart-item-detail'}>
                              <strong>{t('cart:checkout.shopping_list.size')}:</strong>&nbsp;
                              {item.size.name}
                            </div>
                            <div className={'cart-item-detail'}>
                              <strong>{t('cart:checkout.shopping_list.color')}:</strong>&nbsp;
                              {this.getColorName(item.product)}
                              {currentColor && (
                                <div
                                  key={index}
                                  className={'product-color active'}
                                  style={{
                                    backgroundColor: currentColor.value,
                                    borderColor: this.isWhite(currentColor.value)
                                      ? '#aaa'
                                      : currentColor.value
                                  }}
                                />
                              )}
                            </div>
                            <div className={'cart-item-detail'}>
                              <strong>{t('cart:checkout.shopping_list.quantity')}:</strong>&nbsp;
                              <InputNumber
                                min={1}
                                onChange={(e) => this.updateItemQuantity(e, item)}
                                defaultValue={item.quantity}
                              />
                            </div>
                          </div>

                          <div className={'cart-item-price'}>
                            <strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatPrice(
                                    (item.product.price + item.size.price) * item.quantity,
                                    this.props.app.currency,
                                    this.props.app.country,
                                    item.product.sale,
                                  )
                                }}
                              />
                            </strong>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            {events &&
              events.map((item, index) => {
                return (
                  <Col key={index} xs={24} className={'cart-col'}>
                    <div className={'cart-item'}>
                      <Row>
                        <Col xs={6}>
                          {item.event.image && (
                            <img
                              className={'cart-item-image'}
                              alt={item.event.name[this.props.app.language.code]}
                              src={process.env.REACT_APP_API_URL + item.event.image}
                            />
                          )}
                        </Col>
                        <Col xs={10} lg={11} xl={13}>
                          <div className={'cart-item-details'}>
                            <div className={'cart-item-details-name'}>
                              <strong>{item.event.name[this.props.app.language.code]}</strong>
                              &nbsp; &nbsp;
                              <DeleteOutlined
                                style={{ marginLeft: 5 }}
                                onClick={() => this.handleRemoveItem(item)}
                              />
                              <hr />
                            </div>
                            {item.custom_text && (
                              <div className={'cart-item-detail'}>
                                <strong>{t('cart:checkout.shopping_list.custom_text')}:</strong>
                                &nbsp;
                                {item.custom_text}
                              </div>
                            )}
                            <div className={'cart-item-detail'}>
                              <strong>{t('cart:checkout.shopping_list.quantity')}:</strong>&nbsp;
                              <InputNumber
                                min={1}
                                onChange={(e) => this.updateItemQuantity(e, item)}
                                defaultValue={item.quantity}
                              />
                            </div>

                            {/*{settings.events.partialPartipation.enable && settings.events.partialPartipation.donation.enable && item.extra_amount > 0 &&*/}
                            {/*  <div className={'cart-item-detail'}>*/}
                            {/*    <strong>{t('cart:checkout.shopping_list.extra_amount')}:</strong>&nbsp;*/}
                            {/*    <span>{formatPrice(item.extra_amount, this.props.app.currency, this.props.app.country)}</span>*/}
                            {/*  </div>*/}
                            {/*}*/}
                          </div>
                        </Col>
                        <Col xs={8} lg={7} xl={5}>
                          <div className={'cart-item-price'}>
                            <strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatPrice(
                                    item.event.price * item.quantity,
                                    this.props.app.currency,
                                    this.props.app.country
                                  )
                                }}
                              />
                            </strong>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}

            {giftCards &&
              giftCards.map((item, index) => {
                return (
                  <Col key={index} xs={24} className={'cart-col'}>
                    <div className={'cart-item'}>
                      <Row>
                        <Col xs={6}>
                          {item.giftCard.image && (
                            <img
                              className={'cart-item-image'}
                              alt={item.giftCard.name[this.props.app.language.code]}
                              src={process.env.REACT_APP_API_URL + item.giftCard.image}
                            />
                          )}
                        </Col>
                        <Col xs={10} lg={11} xl={13}>
                          <div className={'cart-item-details'}>
                            <strong>{item.giftCard.name[this.props.app.language.code]}</strong>
                            &nbsp; &nbsp;
                            <DeleteOutlined
                              style={{ marginLeft: 5 }}
                              onClick={() => this.handleRemoveItem(item)}
                            />
                            <hr />
                            <div className={'cart-item-detail'}>
                              <strong>{t('cart:checkout.shopping_list.quantity')}:</strong>&nbsp;
                              <InputNumber
                                min={1}
                                onChange={(e) => this.updateItemQuantity(e, item)}
                                defaultValue={item.quantity}
                              />
                              {item.custom_receiver ? (
                                <div className={'cart-item-detail'}>
                                  <strong>{t('cart:checkout.shopping_list.custom_email')}:</strong>
                                  &nbsp;
                                  <Input
                                    placeholder={t(
                                      'cart:checkout.shopping_list.custom_email_placeholder'
                                    )}
                                    onKeyUp={(e: any) =>
                                      this.props.updateGiftCardReceiverEmail(
                                        item.id,
                                        e.target.value
                                      )
                                    }
                                    defaultValue={item.custom_text ? item.custom_text : ''}
                                  />
                                  <Button
                                    onClick={() =>
                                      this.props.enableGiftCardReceiverEmail(item.id, false)
                                    }
                                    style={{ marginTop: 10 }}
                                    type={'ghost'}
                                  >
                                    {t('cart:checkout.shopping_list.remove_custom_receiver')}
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  onClick={() =>
                                    this.props.enableGiftCardReceiverEmail(item.id, true)
                                  }
                                  style={{ marginTop: 10 }}
                                  type={'ghost'}
                                >
                                  {t('cart:checkout.shopping_list.add_custom_receiver')}
                                </Button>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col xs={8} lg={7} xl={5}>
                          <div className={'cart-item-price'}>
                            <strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formatPrice(
                                    item.giftCard.price * item.quantity,
                                    this.props.app.currency,
                                    this.props.app.country
                                  )
                                }}
                              />
                            </strong>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <hr />
          <Row id={'shopping-list-totals'}>
            <Col xs={12}>
              {settings.cart.showTax && <p>Subtotal</p>}
              {settings.cart.showTax && <p>Tax ({this.props.app.country?.tax_rate}%)</p>}
              <p>Grand Total</p>
            </Col>
            <Col xs={12}>
              {settings.cart.showTax && (
                <p>
                  {formatPrice(
                    allCosts.totalCosts,
                    this.props.app.currency,
                    this.props.app.country
                  )}
                </p>
              )}
              {settings.cart.showTax && (
                <p>
                  {formatPrice(allCosts.totalTax, this.props.app.currency, this.props.app.country)}
                </p>
              )}
              <p>
                {formatPrice(
                  allCosts.totalTotalWithVoucher,
                  this.props.app.currency,
                  this.props.app.country
                )}
              </p>
            </Col>
          </Row>
        </div>
        {this.props.app.country &&
          this.props.app.currency &&
          allCosts.preferredCurrencyObject &&
          (this.props.app.country.default_shipping_costs > 0 ||
            this.props.app.country.tracked_shipping_costs > 0) && (
            <div id={'block-2'}>
              <Row className={'cart-total-price'}>
                <Col xs={12} className={'cart-total-price-label'}>
                  <div>
                    <strong>{t('cart:checkout.shopping_list.shipping_costs')}:</strong>
                  </div>
                </Col>

                <Col xs={24}>
                  <Radio.Group value={this.props.deliveryType}>
                    <Button
                      className={'delivery-option'}
                      onClick={() => this.props.onDeliveryTypeChange('standard')}
                    >
                      <StandardDelivery />
                      <span>
                        {t('cart:checkout.shopping_list.shipping_costs.standard_delivery')} (
                        {allCosts.freeShipping
                          ? t('cart:checkout.shopping_list.shipping_costs.free')
                          : formatPrice(
                              this.props.app.country.default_shipping_costs /
                                allCosts.preferredCurrencyObject.rate,
                              this.props.app.currency,
                              this.props.app.country
                            )}
                        )
                      </span>
                      <Radio value={'standard'} style={{ marginBottom: 0 }} />
                    </Button>

                    <Button
                      className={'delivery-option'}
                      onClick={() => this.props.onDeliveryTypeChange('tracked')}
                    >
                      <TrackedDelivery />
                      <span>
                        {t('cart:checkout.shopping_list.shipping_costs.tracked_delivery')} (
                        {formatPrice(
                          allCosts.freeShipping
                            ? this.props.app.country.tracked_shipping_costs /
                                allCosts.preferredCurrencyObject.rate -
                                this.props.app.country.default_shipping_costs /
                                  allCosts.preferredCurrencyObject.rate
                            : this.props.app.country.tracked_shipping_costs /
                                allCosts.preferredCurrencyObject.rate,
                          this.props.app.currency,
                          this.props.app.country
                        )}
                        )
                      </span>
                      <Radio value={'tracked'} style={{ marginBottom: 0 }} />
                    </Button>
                  </Radio.Group>
                </Col>
              </Row>

              {!allCosts.freeShipping && allCosts.preferredCurrencyObject && (
                <Alert
                  message={t('cart:checkout.shopping_list.shipping_costs_alert.title')}
                  description={t('cart:checkout.shopping_list.shipping_costs_alert.description', {
                    text: formatPrice(
                      this.props.app.country.shipping_costs_limit /
                        allCosts.preferredCurrencyObject.rate,
                      this.props.app.currency,
                      null
                    )
                  })}
                  type='info'
                  showIcon
                />
              )}
            </div>
          )}
        {((this.props.app.country && settings.cart.showVoucherInShoppinglist) ||
          this.props.forceVoucherView) && (
          <div className={'shopping-list-block'}>
            <>
              {/*<h4>Voucher</h4>*/}
              {settings.cart.enableVoucher && <VoucherComponent costs={allCosts} />}
              {/*<hr />*/}
              {this.props.cart.voucher && (
                <Row className={'cart-total-price total-total'}>
                  <Col xs={12} className={'cart-total-price-label'}>
                    <div>
                      <strong>{t('cart:checkout.shopping_list.total_total')}:</strong>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className={'cart-total-price-value'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatPrice(
                            allCosts.totalTotalWithVoucher,
                            this.props.app.currency,
                            null,
                            undefined,
                            true
                          )
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  cart: state.cart
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCartItemQuantity: (quantity: number, item: CartItem) =>
    dispatch(updateCartItemQuantity(quantity, item)),
  removeItemFromCart: (id: string) => dispatch(removeItemFromCart(id)),
  removeEventFromCart: (id: string) => dispatch(removeEventFromCart(id)),
  removeGiftCardFromCart: (id: string) => dispatch(removeGiftCardFromCart(id)),
  updateGiftCardReceiverEmail: (id: string, value: string) =>
    dispatch(updateGiftCardReceiverEmail(id, value)),
  enableGiftCardReceiverEmail: (id: string, value: boolean) =>
    dispatch(enableGiftCardReceiverEmail(id, value))
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShoppingListComponent));
