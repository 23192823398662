import { settingsType } from './core-module/settings';

export const settings: settingsType = {
  roundNonBaseFiatCurries: false,
  thankYou: { showFollowUsBanner: true },
  cookies: { enable: false, cookiePolicyUrl: '/cookies', enableLearnMoreButton: false },
  events: {
    upcomingEvents: { imageMode: 'cover', imageZoom: false },
    partialPartipation: {
      donation: {
        donationOptions: [],
        enable: false,
        donationOptionsCurrency: 'USD',
        condition: { type: 'sessions', quantity: 1 }
      },
      enable: false
    },
    rootUrl: '',
    sessions: { enable: false }
  },
  parallaxStrength: 200,
  parallaxInHeader: false,
  showBreadcrumbs: false,
  enableWishlist: false,
  appName: 'Bright',
  reduxStoreKey: 'bright_web_v1.0',
  paymentMethod: 'stripe',
  languages: [
    {
      name: 'English',
      code: 'en',
      flag: 'english.png'
    }
  ],
  navbar: {
    type: 'default',
    hamburgerBreakpoint: 9999,
    languageCurrencyPlacement: null,
    linksStyle: 'default',
    shoppingBag: {
      popupStyle: 'drawer',
      listStyle: 'default',
      icon: 'bag',
      enable: false,
      customIcon: 'icon-shoppingbag.png'
    },
    search: {
      enable: false,
      customIcon: 'icon-search.png'
    },
    wishlist: {
      enable: false,
      style: 'popup',
      customIcon: 'icon-wishlist.png'
    },
    userAccount: {
      redirectUrlOnLogin: '/',
      redirectOnLogin: false,
      showOverview: false,
      redirectOnRegister: '/',
      style: 'popup',
      enable: false,
      customIcon: 'icon-user.png'
    }
  },
  cart: {
    checkoutFields: [],
    enableDonationOption: false,
    enableDifferentDeliveryAddress: true,
    enableDifferentInvoiceAddress: false,
    enableVoucher: false,
    paymentURL: '/orders',
    paymentURLAuth: '/orders',
    showVoucherInShoppinglist: false,
    showBanner: false,
    style: 'steps',
    showGoHomeShoppingCartButton: true,
    showHelpText: false,
    showTax: false
  },
  contact: {
    mapCenter: [52.637747, -1.074802],
    mapLocation: [52.637747, -1.074802],
    style: 'default',
    showMap: false,
    showDecoration: false,
    subjects: {
      enable: false,
      choices: []
    }
  },
  faqs: {
    showDecoration: false
  },
  products: {
    tags: [],
    productSlugPrefix: '/product',
    style: 'boxed',
    shoppingPage: '/the-boutique',
    layoutOffset: true,
    navbarAnimated: true,
    filter: {
      filters: ['product_occasions', 'sizes', 'colors', 'product_types', 'price_range'],
      showUnitSwitch: false
    },
    customWishlistIcon: 'icon-heart.svg',
    fewLeftLimit: 3
  },
  productCategories: {
    rootUrl: '/lookbook'
  },
  lookbook: {
    rootUrl: '/lookbook'
  },
  footer: {
    type: 'minimal',
    enableEmailSubscription: false,
  },
  showDefaultHeader: true,
  showCompanyBanner: false,
  addClassToHeaderOnScroll: 50,
  expandAllFAQS: false,
  useImageBackground: false
};
