import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AuthState } from './core-module/redux/states/user';
import { IAppState } from './core-module/redux/states/app';
import DynamicComponent from './core-module/components/pages/dynamic.component';
import NotFoundComponent from './core-module/components/app/404.component';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ContactDefaultComponent from "./core-module/components/pages/contact-default.component";
import ContactBasicComponent from "./core-module/components/pages/contact-basic.component";

interface Props {
  auth: AuthState;
  app: IAppState;
}

const PublicRoutes = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.auth.isLoggedIn && props.auth.user) {
      if (location.pathname === `${process.env.PUBLIC_URL}/login`) {
        navigate('/');
      }
    } else if (location.pathname === `${process.env.PUBLIC_URL}/profile`) {
      navigate('/login');
    }
  }, [props.auth.isLoggedIn, location, navigate, props.auth.user]);

  return (
    <Routes>
      <Route path='/contact' element={<ContactBasicComponent />} />
      <Route key={location.pathname} path='*' element={<DynamicComponent />} />
      <Route path='/404' element={<NotFoundComponent />} />
    </Routes>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps)(PublicRoutes);
