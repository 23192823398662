import {
  ACCEPT_COOKIE_CONSENT,
  CLEAR_COOKIE_CONSENT,
  COUNTRIES_ERROR,
  COUNTRIES_RECEIVE,
  COUNTRIES_REQUEST,
  CURRENCIES_ERROR,
  CURRENCIES_RECEIVE,
  CURRENCIES_REQUEST,
  DONE_LOADING_LANGUAGE,
  HIDE_CATEGORIES_BAR,
  LOAD_LANGUAGE,
  SET_COUNTRY,
  SET_CURRENCY,
  SET_LANGUAGE,
  SETTINGS_ERROR,
  SETTINGS_RECEIVE,
  SETTINGS_REQUEST,
  SHOW_CATEGORIES_BAR
} from '../types';
import { LoopBack } from '../api';
import { Country, Currency, Setting } from '../../types';
import { settings } from '../../../settings';

export const fetchSettings = () => {
  return (dispatch: any) => {
    dispatch(settingsRequest());
    new LoopBack()
      .get('/settings/web')
      .then((res) => {
        dispatch(settingsReceive(res));
      })
      .catch((err) => {
        dispatch(settingsError());
      });
  };
};

const dispatchCurrency = (dispatch: any, currencies: Currency[], currencyCode: string) => {
  const currency = currencies.find((currency: Currency) => currency.code === currencyCode);

  if (currency) {
    dispatch(setCurrency(currency));
  }
};

export const initializeLocalization = (props: any, setLanguage: boolean, i18: any) => {
  return (dispatch: any) => {
    const setCurrency = props.app.currency === undefined || props.app.currency === null;
    const setCountry = props.app.country === undefined || props.app.country === null;
    const currentCode = props.app.currency ? props.app.currency.code : null;
    dispatch(loadingLanguage());
    dispatch(currenciesRequest());
    new LoopBack()
      .get('/currencies')
      .then((res) => {
        fetchCountries(dispatch, setCountry, setCurrency, setLanguage, res, props, i18);
        dispatch(currenciesReceive(res));
        if (!setCurrency && currentCode) {
          // set the current currency again as the rate may have been updated
          dispatchCurrency(dispatch, res, currentCode);
        }
      })
      .catch((err) => {
        dispatch(currenciesError());
      });
  };
};

const continueDispatch = (
  dispatch: any,
  props: any,
  i18n: any,
  country: any,
  doSetCurrency?: boolean,
  doSetLanguage?: boolean,
  currencies?: Currency[]
) => {
  dispatch(setCountry(country));

  if (doSetCurrency && currencies && currencies.length > 0) {
    dispatchCurrency(dispatch, currencies, country.preferred_currency);
  }
  if (doSetLanguage) {
    doDispatchLanguage(country, dispatch, props, i18n);
  }
};

const setCountryAndCurrencyBasedOnIP = (
  dispatch: any,
  countries: any,
  props: any,
  i18n: any,
  doSetCurrency?: boolean,
  doSetLanguage?: boolean,
  currencies?: Currency[]
) => {
  fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then((response) => {
      const code = response.country_code;
      const filteredCountry = countries.filter((country: Country) => country.code === code);
      let country = countries[0];
      if (filteredCountry.length > 0) {
        country = filteredCountry[0];
      }
      continueDispatch(dispatch, props, i18n, country, doSetCurrency, doSetLanguage, currencies);
    })
    .catch((err) => {
      const country = countries[0];
      continueDispatch(dispatch, props, i18n, country, doSetCurrency, doSetLanguage, currencies);
    });
};

export const doDispatchLanguage = (country: Country, dispatch: any, props: any, i18n: any) => {
  const preferredLanguage = country.preferred_language;
  const language = settings.languages.find(
    (lang) => lang.code.toLowerCase() === preferredLanguage.toLowerCase()
  );
  const languageObject = language ? language : settings.languages[0];
  try {
    i18n.changeLanguage(languageObject.code);
  } catch (err) {
    console.error(err);
  }
  dispatch(setLanguage(languageObject));
  dispatch(doneLoadingLanguage());
};

export const fetchCountries = (
  dispatch: any,
  doSetCountry: boolean,
  doSetCurrency: boolean,
  doSetLanguage: boolean,
  currencies: Currency[],
  props: any,
  i18n: any
) => {
  dispatch(countriesRequest());
  new LoopBack()
    .get('/countries')
    .then((countries) => {
      if (doSetLanguage) {
        if (!doSetCountry) {
          doDispatchLanguage(props.app.country, dispatch, props, i18n);
          try {
            props.i18n.changeLanguage(props.app.country.preferred_language);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        dispatch(doneLoadingLanguage());
      }

      if (doSetCountry) {
        setCountryAndCurrencyBasedOnIP(
          dispatch,
          countries,
          props,
          i18n,
          doSetCurrency,
          doSetLanguage,
          currencies
        );
      } else {
        // re-apply the country in case any of its properties has changed
        const currentCountry = props.app.country;
        const newCountry = countries.filter((country: Country) => country.id === currentCountry.id);
        if (newCountry.length > 0) {
          dispatch(setCountry(newCountry[0]));
        }
      }

      if (doSetCurrency && currencies.length > 0) {
        dispatchCurrency(dispatch, currencies, currencies[0].code);
      }

      dispatch(countriesReceive(countries));
    })
    .catch((err) => {
      dispatch(countriesError());
    });
};

export const showCategoriesBar = () => ({
  type: SHOW_CATEGORIES_BAR
});

export const hideCategoriesBar = () => ({
  type: HIDE_CATEGORIES_BAR
});

const settingsRequest = () => ({
  type: SETTINGS_REQUEST
});

const settingsReceive = (data: Setting) => ({
  type: SETTINGS_RECEIVE,
  payload: data
});

const settingsError = () => ({
  type: SETTINGS_ERROR
});

const currenciesRequest = () => ({
  type: CURRENCIES_REQUEST
});

const currenciesReceive = (data: Currency[]) => ({
  type: CURRENCIES_RECEIVE,
  payload: data
});

const currenciesError = () => ({
  type: CURRENCIES_ERROR
});

export const setCurrency = (data: Currency) => ({
  type: SET_CURRENCY,
  payload: data
});

export const acceptCookieConsent = () => ({
  type: ACCEPT_COOKIE_CONSENT
});

export const clearCookieConsent = () => ({
  type: CLEAR_COOKIE_CONSENT
});

const countriesRequest = () => ({
  type: COUNTRIES_REQUEST
});

const countriesReceive = (data: Country[]) => ({
  type: COUNTRIES_RECEIVE,
  payload: data
});

const countriesError = () => ({
  type: COUNTRIES_ERROR
});

export const setCountry = (data: Country) => ({
  type: SET_COUNTRY,
  payload: data
});

export const setLanguage = (data: { code: string; name: string }) => ({
  type: SET_LANGUAGE,
  payload: data
});

const doneLoadingLanguage = () => ({
  type: DONE_LOADING_LANGUAGE
});

const loadingLanguage = () => ({
  type: LOAD_LANGUAGE
});
