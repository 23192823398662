import React from 'react';
import { Button, Col, Dropdown, Form, Input, Layout, Menu, Row, Space } from 'antd';
import { DownOutlined, RightOutlined, TwitterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '../../../redux/states/app';
import CookieBannerComponent from '../../app/cookie-banner.component';
import { clearCookieConsent, setCurrency, setLanguage } from '../../../redux/actions/app';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { Currency } from '../../../types';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { ReactComponent as FaceBookIcon } from '../../../../assets/icon-facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icon-youtube.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icon-instagram.svg';
import '../../../styles/footer-basic.less';

const { Footer } = Layout;

interface Props extends WithTranslation {
  setLanguage: typeof setLanguage;
  clearCookieConsent: typeof clearCookieConsent;
  setCurrency: typeof setCurrency;
  app: IAppState;
}

const FooterBasicComponent = (props: Props) => {
  const { t } = useTranslation();

  const handleChangeLanguage = (language: { name: string; code: string }) => {
    props.i18n.changeLanguage(language.code.toLowerCase());
    props.setLanguage(language);
    window.scrollTo(0, 0);
  };

  // const language = () => {
  //
  //   return (
  //     <Menu>
  //       {settings.languages.map((language: { name: string; code: string }, index: number) => {
  //         return (
  //           <Menu.Item key={index} onClick={() => handleChangeLanguage(language)}>
  //             {t(`app:navbar.menu.language.${language.code.toLowerCase()}.label`)}
  //           </Menu.Item>
  //         );
  //       })}
  //     </Menu>
  //   );
  // };

  const currency = () => {
    return (
      <Menu>
        {props.app.currencies.map((currency, index) => {
          return (
            <Menu.Item key={index} onClick={() => props.setCurrency(currency)}>
              {currency.symbol} {t(`app:navbar.menu.currency.${currency.code.toLowerCase()}.label`)}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const onFinish = (values: any) => {
    new LoopBack()
      .post('/newsletter', values)
      .then((res) => {
        Swal.fire({
          title: t('app:footer.popup.signup.success.title'),
          html: t('app:footer.popup.signup.success.description'),
          icon: 'success'
        });
      })
      .catch((err) => {
        Swal.fire({
          title: t('app:footer.popup.signup.warning.title'),
          html: t('app:footer.popup.signup.warning.description'),
          icon: 'warning'
        });
      });
  };

  const apiSettings = props.app.settings;
  const address = apiSettings && apiSettings.addresses && apiSettings.addresses[0];

  return (
    <Footer className={'footer-basic'}>
      <Row>
        <Col xs={24} xl={{ span: 18, offset: 3 }}>
          <Row gutter={[0, 40]}>
            <Col xs={12} sm={10}>
              <div id={'footer-left-col'}>
                <p>{t('app:footer.contact.description')}</p>
                {address && (
                  <p>
                    {address.title}
                    <br />
                    {address.business_registration.label} {address.business_registration.value}
                  </p>
                )}

                <Form name='basic' layout={'vertical'} onFinish={onFinish}>
                  <Row gutter={20}>
                    <Col xs={24} md={12} id={'newsletter-subscription'}>
                      <h3>Newsletter Subscription</h3>
                      <Form.Item
                        name={'email'}
                        rules={[{ required: true, message: t('pages:contact.form.email.warning') }]}
                      >
                        <Input
                          addonAfter={
                            <Button htmlType={'submit'} type={'primary'}>
                              <RightOutlined />
                            </Button>
                          }
                          type='email'
                          placeholder={t('pages:contact.form.email.placeholder')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>

            {apiSettings?.footer_settings && (
              <Col xs={12} sm={6}>
                <Row>
                  <Col xs={24}>
                    <nav>
                      <ul>
                        {apiSettings.footer_settings.customer_service_links.links.map(
                          (link, lindex) => {
                            return (
                              <li key={lindex}>
                                <Link to={link.url}>
                                  <RightOutlined /> {link.name[props.app.language.code]}
                                </Link>
                              </li>
                            );
                          }
                        )}
                        <li
                          className={'additional-links'}
                          key={'consent'}
                          onClick={() => props.clearCookieConsent()}
                        >
                          <span>
                            <RightOutlined /> {t('app:footer.cookie_consent.label')}
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={24} sm={8}>
              {apiSettings?.footer_settings?.social_media_links && (
                <p>{t('app:footer.social_media.title')}</p>
              )}

              {apiSettings?.footer_settings?.social_media_links && (
                <div id={'footer-social-media-icons'}>
                  {apiSettings?.footer_settings?.social_media_links.instagram && (
                    <a
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={apiSettings?.footer_settings?.social_media_links.instagram}
                    >
                      <InstagramIcon />
                    </a>
                  )}
                  {apiSettings?.footer_settings?.social_media_links.twitter && (
                    <a
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={apiSettings?.footer_settings?.social_media_links.twitter}
                    >
                      <TwitterOutlined />
                    </a>
                  )}
                  {apiSettings?.footer_settings?.social_media_links.facebook && (
                    <a
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={apiSettings?.footer_settings?.social_media_links.facebook}
                    >
                      <FaceBookIcon />
                    </a>
                  )}
                  {apiSettings?.footer_settings?.social_media_links.youtube && (
                    <a
                      rel={'noopener noreferrer'}
                      target={'_blank'}
                      href={apiSettings?.footer_settings?.social_media_links.youtube}
                    >
                      <YoutubeIcon />
                    </a>
                  )}
                </div>
              )}

              <div>
                {!props.app.currenciesLoading &&
                !props.app.countriesLoading &&
                !props.app.languagesLoading ? (
                  <Space className={'language-selection'}>
                    {settings.languages.length > 1 && (
                      <>
                        {settings.languages.map(
                          (
                            language: { name: string; code: string; flag: string },
                            index: number
                          ) => {
                            return (
                              <div
                                className={'language-selection-language'}
                                key={index}
                                onClick={() => handleChangeLanguage(language)}
                              >
                                <img
                                  alt={'flag icon'}
                                  src={require('../../../assets/flags/' + language.flag)}
                                />{' '}
                                {t(`app:navbar.menu.language.${language.code.toLowerCase()}.label`)}
                                {index !== settings.languages.length - 1 && <span> | </span>}
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </Space>
                ) : (
                  <span>Loading...</span>
                )}

                <div style={{ marginTop: 10 }}>
                  <span>{t('app:footer.currency.title')}: </span>

                  {props.app.currencies.length > 1 && (
                    <Dropdown overlay={currency}>
                      {props.app.currency ? (
                        <span>
                          {props.app.currency.symbol}{' '}
                          <span className={'dropdown-label'}>
                            {t(
                              `app:navbar.menu.currency.${props.app.currency.code.toLowerCase()}.label`
                            )}{' '}
                          </span>
                          <DownOutlined />
                        </span>
                      ) : (
                        <span>
                          {t('app:navbar.menu.currency.no_currency_set.label')} <DownOutlined />
                        </span>
                      )}
                    </Dropdown>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row id={'footer-bottom-container'}>
            <Col xs={12}>{t('app:footer.title.bottom', { text: moment().year() })}</Col>
            <Col xs={12} id={'footer-bottom-container-right'}>
              <Link to={'/terms-and-conditions'}>
                {t('cart:checkout.form.terms_and_conditions.link')}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <CookieBannerComponent />
    </Footer>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  clearCookieConsent: () => dispatch(clearCookieConsent()),
  setCurrency: (currency: Currency) => dispatch(setCurrency(currency)),
  setLanguage: (language: { code: string; name: string }) => dispatch(setLanguage(language))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FooterBasicComponent));
