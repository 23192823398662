import React, { Suspense } from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './core-module/redux/store';
import { Provider } from 'react-redux';
import 'moment/locale/en-gb';
import './core-module/styles/index.less';
import './custom/styles/custom.less';
import SkeletonComponent from './core-module/components/app/skeleton.component';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);

const Redux = configureStore();

root.render(
  <PersistGate loading={null} persistor={Redux.persistor}>
    <Provider store={Redux.store}>
      <Suspense fallback={<SkeletonComponent />}>
        <App />
      </Suspense>
    </Provider>
  </PersistGate>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
