import { Carousel, Col, Row } from 'antd';

import React from 'react';
import { PageSection } from '../../../types';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import { Parallax } from 'react-parallax';
import '../../../styles/modules/slider-partners.less';
import { CarouselRef } from 'antd/lib/carousel';
import { ReactComponent as ArrowRight } from '../../../../assets/icon-arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/icon-arrow-left.svg';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
}

interface State {}

class SliderVideosModule extends React.Component<Props, State> {
  carouselRef = React.createRef<CarouselRef>();

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  onPrev = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.prev();
    }
  };

  onNext = () => {
    if (this.carouselRef && this.carouselRef.current) {
      this.carouselRef.current.next();
    }
  };

  getRow = (section: PageSection) => {
    let style: any = {};
    let bgClass = null;
    if (section.settings.background_image) {
      bgClass = ' has-bg-image';
    }
    if (section.settings.background_color) {
      Object.assign(style, { backgroundColor: section.settings.background_color });
    }

    return (
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-slider-partners ${bgClass ? bgClass : ''}`}
        style={style}
      >
        <Col xs={{ span: 22, offset: 1 }}>
          <Row>
            <Col xs={24}>
              <h2>{section.settings.title[this.props.app.language.code]}</h2>
            </Col>
          </Row>

          <Row className={'brand-slider-row'}>
            <Col xs={24}>

              <div className={'slider-row'}>
              <div className={'slider-navigation'}>
                <div
                  onClick={this.onPrev}
                  className={'highlighted-products-navigation-arrow arrow-left'}
                >
                  <ArrowLeft />
                </div>
              </div>

              <Carousel
                ref={this.carouselRef}
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]}
                lazyLoad={'ondemand'}
                autoplaySpeed={2500}
                dots={true}
                arrows={true}
                slidesToScroll={1}
                slidesToShow={4}
              >
                {section.settings.slides.map(
                  (slide: { video: { url: string; title: string } }, index: number) => {
                    return (
                      <div key={index}>
                        <video style={{width: '100%', height: 'auto',padding: 10}} autoPlay={true} loop muted playsInline>
                          <source src={process.env.REACT_APP_API_URL + '/video' + slide.video.url.replace('/images','')} type="video/mp4"/>
                        </video>
                      </div>
                    );
                  }
                )}
              </Carousel>

              <div className={'slider-navigation'}>
                <div
                  onClick={this.onNext}
                  className={'highlighted-products-navigation-arrow arrow-right'}
                >
                  <ArrowRight />
                </div>
              </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const section = this.props.section;

    return (
      <>
        {section.settings.background_image ? (
          <Parallax
            bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
            bgImageAlt=''
            strength={200}
          >
            {this.getRow(section)}
          </Parallax>
        ) : (
          this.getRow(section)
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect<any, Props, any>(mapStateToProps)(withTranslation()(SliderVideosModule));
