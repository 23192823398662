import {
  ACCEPT_COOKIE_CONSENT,
  CLEAR_COOKIE_CONSENT,
  COUNTRIES_ERROR,
  COUNTRIES_RECEIVE,
  COUNTRIES_REQUEST,
  CURRENCIES_ERROR,
  CURRENCIES_RECEIVE,
  CURRENCIES_REQUEST,
  DONE_LOADING_LANGUAGE,
  HIDE_CATEGORIES_BAR,
  LOAD_LANGUAGE,
  SET_COUNTRY,
  SET_CURRENCY,
  SET_LANGUAGE,
  SETTINGS_ERROR,
  SETTINGS_RECEIVE,
  SETTINGS_REQUEST,
  SHOW_CATEGORIES_BAR
} from '../types';
import { AppState } from '../states/app';

export default function app(state = AppState, action: any) {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SETTINGS_RECEIVE:
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        settings: null
      };
    case SHOW_CATEGORIES_BAR:
      return {
        ...state,
        showCategoriesBar: true
      };
    case HIDE_CATEGORIES_BAR:
      return {
        ...state,
        showCategoriesBar: false
      };
    case CURRENCIES_REQUEST:
      return {
        ...state,
        currenciesLoading: true
      };
    case CURRENCIES_RECEIVE:
      return {
        ...state,
        currenciesLoading: false,
        currencies: action.payload ? action.payload : []
      };
    case CURRENCIES_ERROR:
      return {
        ...state,
        currenciesLoading: false,
        currencies: []
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
    case ACCEPT_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: true
      };
    case CLEAR_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: false
      };
    case COUNTRIES_REQUEST:
      return {
        ...state,
        countriesLoading: true
      };
    case COUNTRIES_RECEIVE:
      return {
        ...state,
        countriesLoading: false,
        countries: action.payload ? action.payload : []
      };
    case COUNTRIES_ERROR:
      return {
        ...state,
        countriesLoading: false,
        countries: []
      };
    case SET_COUNTRY:
      return {
        ...state,
        country: action.payload
      };
    case LOAD_LANGUAGE:
      return {
        ...state,
        languagesLoading: true
      };
    case DONE_LOADING_LANGUAGE:
      return {
        ...state,
        languagesLoading: false
      };
    case SET_LANGUAGE:
      return {
        ...state,
        languageInit: true,
        languagesLoading: false,
        language: action.payload
      };
    default:
      return state;
  }
}
