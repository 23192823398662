import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection } from '../../../types';
import '../../../styles/modules/image-wall.less';
import { settings } from '../../../../settings';

interface LinkType {
  type: string;
  value: string;
  filter: {
    property: string;
    value: string;
  };
}

interface ImageType {
  url: string;
  title_block: { title: string; subtitle: string; position: string; alignment: string };
  link: LinkType;
}

interface ImageBlock {
  image: ImageType;
}

export const ImageWallModule = (section: PageSection) => {
  const numOfImages = section.settings.images.length;
  let responsive: any = { xs: 12, sm: 12, lg: 6 };
  if (numOfImages === 2) {
    responsive = { xs: 12 };
  } else if (numOfImages === 3 || numOfImages === 6) {
    responsive = { xs: 12, sm: 8 };
  }

  const getLink = (link: LinkType) => {
    switch (link.type) {
      case 'products_with_filter':
        return `${settings.products.shoppingPage}?${link.filter.property}=${link.filter.value}`;
      case 'product_page':
        return `${settings.products.shoppingPage}${link.value}`;
      case 'webpage':
        return link.value;
    }
    return '/';
  };

  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-image-wall'}
    >
      <Col xs={{ span: 20, offset: 2 }}>
        {section.settings.title && (
          <>
            <h2>{section.settings.title}</h2>
            <em>{section.settings.subtitle}</em>
          </>
        )}
        <Row gutter={[10, 10]} align={'middle'} justify={'center'}>
          {section.settings.images.map((image: ImageBlock, index: number) => {
            return (
              <Col key={index} {...responsive}>
                <Link to={getLink(image.image.link)}>
                  <div className={'image-block'}>
                    <img
                      alt={`${image.image.title_block.title}`}
                      src={process.env.REACT_APP_API_URL + image.image.url}
                    />
                    <div
                      className={`image-block-title position-${image.image.title_block.position} alignment-${image.image.title_block.alignment}`}
                    >
                      <span>{image.image.title_block.subtitle}</span>
                      <h3>{image.image.title_block.title}</h3>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
