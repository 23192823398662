import React, { Component } from 'react';
import { settings } from '../../../settings';
import NavbarEcommerceComponent from '../navigation/navbar-default.component';
import NavbarBasicComponent from '../navigation/navbar-basic.component';

class NavbarComponent extends Component {
  render() {
    if (settings.navbar.type === 'basic') {
      return <NavbarBasicComponent />;
    } else {
      return <NavbarEcommerceComponent />;
    }
  }
}

export default NavbarComponent;
