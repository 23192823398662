import { Carousel, Col, Row } from 'antd';

import React from 'react';
import { PageSection } from '../../../types';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAppState } from '../../../redux/states/app';
import { Parallax } from 'react-parallax';
import '../../../styles/modules/slider-partners.less';
import { CarouselRef } from 'antd/lib/carousel';

interface Props extends WithTranslation {
  app: IAppState;
  section: PageSection;
}

interface State {}

class SliderPartnersModule extends React.Component<Props, State> {
  carouselRef = React.createRef<CarouselRef>();

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getRow = (section: PageSection) => {
    let style: any = {};
    let bgClass = null;
    if (section.settings.background_image) {
      bgClass = ' has-bg-image';
    }
    if (section.settings.background_color) {
      Object.assign(style, { backgroundColor: section.settings.background_color });
    }

    return (
      <Row
        id={section.uid ? section.uid : undefined}
        key={section.id}
        className={`cms-module-slider-partners ${bgClass ? bgClass : ''}`}
        style={style}
      >
        <Col
          xs={{ span: 22, offset: 1 }}
          xl={{ span: 18, offset: 3 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Row>
            <Col xs={24}>
              <h2>{section.settings.title[this.props.app.language.code]}</h2>
            </Col>
          </Row>

          <Row className={'brand-slider-row'}>
            <Col xs={24}>
              <Carousel
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 2
                    }
                  }
                ]}
                pauseOnHover={false}
                swipeToSlide={false}
                autoplaySpeed={1500}
                dots={false}
                autoplay
                slidesPerRow={1}
                slidesToShow={5}
                slidesToScroll={1}
              >
                {section.settings.slides.map(
                  (slide: { image: { url: string; title: string } }, index: number) => {
                    return (
                      <div key={index}>
                        <img src={process.env.REACT_APP_API_URL + slide.image.url} />
                      </div>
                    );
                  }
                )}
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const section = this.props.section;

    return (
      <>
        {section.settings.background_image ? (
          <Parallax
            bgImage={`${process.env.REACT_APP_API_URL + section.settings.background_image}`}
            bgImageAlt=''
            strength={200}
          >
            {this.getRow(section)}
          </Parallax>
        ) : (
          this.getRow(section)
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect<any, Props, any>(mapStateToProps)(withTranslation()(SliderPartnersModule));
