import React from 'react';
import '../../../styles/modules/centered-paragraph.less';
import { settings } from '../../../../settings';

export const BannerModule = () => {
  return (
    <img
      alt={`${settings.appName} promo banner`}
      style={{ width: '100%' }}
      src={require('../../../../assets/banner-company.jpg')}
    />
  );
};
