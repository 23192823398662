import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React, { CSSProperties } from 'react';
import { PageSection } from '../../../types';
import { RightOutlined } from '@ant-design/icons';
import '../../../styles/modules/paragraph.less';

export const ParagraphModule = (section: PageSection) => {
  const styles: CSSProperties = {};
  if (section.settings.background_color) {
    styles.backgroundColor = section.settings.background_color;
  }
  if (section.settings.text_color) {
    styles.color = section.settings.text_color;
  }

  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={`cms-module-paragraph`}
      align={'middle'}
      justify={'center'}
      style={styles}
    >
      <Col xs={{ span: 22 }} sm={18}>
        {section.settings.title && (
          <>
            {section.settings.title &&
              Object.keys(section.settings.title).length > 0 &&
              section.settings.title[section.language] !== '' && (
                <h2>{section.settings.title[section.language]}</h2>
              )}
            {section.settings.subtitle && <em>{section.settings.subtitle[section.language]}</em>}
          </>
        )}
        {section.settings.description && (
          <p dangerouslySetInnerHTML={{ __html: section.settings.description[section.language] }} />
        )}
        <div className={'cms-module-button-row'}>
          {section.settings.button &&
            section.settings.button.text &&
            section.settings.button.text[section.language] && (
              <Link to={section.settings.button.url}>
                <Button size={'large'} type={'primary'}>
                  {section.settings.button.text[section.language]} <RightOutlined />
                </Button>
              </Link>
            )}
        </div>
      </Col>
    </Row>
  );
};
