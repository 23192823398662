import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection } from '../../../types';
import { RightOutlined } from '@ant-design/icons';
import '../../../styles/modules/centered-paragraph.less';

export const CenteredParagraphModule = (section: PageSection) => {
  const styles = section.settings.background_color
    ? { backgroundColor: section.settings.background_color }
    : {};
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-centered-paragraph'}
      align={'middle'}
      justify={'center'}
      style={styles}
    >
      <Col xs={{ span: 22 }} sm={20}>
        {section.settings.title && (
          <>
            <h2>{section.settings.title[section.language]}</h2>
            {section.settings.subtitle && <em>{section.settings.subtitle[section.language]}</em>}
          </>
        )}
        <p dangerouslySetInnerHTML={{ __html: section.settings.description[section.language] }} />
        <div className={'cms-module-button-row'}>
          {section.settings.button &&
            section.settings.button.text &&
            section.settings.button.text[section.language] && (
              <Link to={section.settings.button.url}>
                <Button size={'large'} type={'primary'}>
                  {section.settings.button.text[section.language]} <RightOutlined />
                </Button>
              </Link>
            )}
        </div>
      </Col>
    </Row>
  );
};
