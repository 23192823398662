import { Button, Checkbox, Col, Drawer, Dropdown, Menu, Row, Slider, Space, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Color,
  PageSection,
  Product,
  ProductCategory,
  ProductOccasion,
  ProductStyle,
  ProductTheme,
  ProductType,
  Size
} from '../../../types';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  RightOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../../assets/icon-arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { settings } from '../../../../settings';
import { IAppState } from '../../../redux/states/app';
import { AuthState } from '../../../redux/states/user';
import { IWishListState } from '../../../redux/states/wishlist';
import { ICartState } from '../../../redux/states/cart';
import * as qs from 'query-string';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import ProductsBoxedProduct from '../products-boxed-product.component';
import moment from 'moment';
import { calculatePrice } from '../../../helpers/price.helper';
import { connect } from 'react-redux';
import { LoopBack } from '../../../redux/api';
import Swal from 'sweetalert2';
import { prerenderIsReady } from '../../../helpers/prerender-ready.helper';
import '../../../styles/products-boxed.less';

interface Props {
  app: IAppState;
  auth: AuthState;
  wishlist: IWishListState;
  section: PageSection;
  cart: ICartState;
}

const WebshopBoxedModule = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [sortBy, setSortBy] = useState<null | string>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [productCategoryFilters, setProductCategoryFilters] = useState<CheckboxValueType[]>([]);
  const [productThemeFilters, setProductThemeFilters] = useState<CheckboxValueType[]>([]);
  const [productStyleFilters, setProductStyleFilters] = useState<CheckboxValueType[]>([]);
  const [productOccasionFilters, setProductOccasionFilters] = useState<CheckboxValueType[]>([]);
  const [productTypeFilters, setProductTypeFilters] = useState<CheckboxValueType[]>([]);
  const [sizeFilters, setSizeFilters] = useState<CheckboxValueType[]>([]);
  const [colorFilters, setColorFilters] = useState<CheckboxValueType[]>([]);
  const [priceRangeFilter, setPriceRangeFilter] = useState([-1, -1]);
  const [highestPrice, setHighestPrice] = useState(-1);
  const [showSales, setShowSales] = useState(false);

  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [productOccasions, setProductOccasions] = useState<ProductOccasion[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productStyles, setProductStyles] = useState<ProductStyle[]>([]);
  const [productThemes, setProductThemes] = useState<ProductStyle[]>([]);
  const [colors, setColors] = useState<Color[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [measurementUnit, setMeasurementUnit] = useState<string>(
    props.app.country ? props.app.country.preferred_measurement_unit : 'imperial'
  );
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [path, setPath] = useState(location.key);
  const [productsPath, setProductsPath] = useState('');

  let queryParams: any = qs.parse(location.search);
  const paramKeys = Object.keys(queryParams);
  const filterNewArrivals =
    paramKeys.indexOf('filter') > -1 && queryParams['filter'] === 'new-arrivals';
  const filterSales =
    paramKeys.indexOf('filter') > -1 && queryParams['filter'] === 'sale';

  useEffect(() => {
    if (location.key !== path || productsPath === '') {
      setPath(location.key);
      setProductsPath(location.key);

      window.scrollTo(0, 0);

      clearFilters();

      new LoopBack()
        .get('/colors')
        .then((res: Color[]) => {
          setColors(res);
        })
        .catch((err) => {
          setColors([]);
        });

      new LoopBack()
        .get('/product-occasions')
        .then((res: ProductOccasion[]) => {
          const filters = extractFilterFromUrl(res, 'product-occasion');
          setProductOccasions(res);
          setProductOccasionFilters(filters.filters);
        })
        .catch((err) => {
          setProductOccasions([]);
        });

      new LoopBack()
        .get('/product-styles')
        .then((res: {data: ProductStyle[]}) => {
          setProductStyles(res.data);

          if (paramKeys.indexOf('product-style') > -1) {
            const tId = res.data.findIndex(
              (style) =>
                style.name[props.app.language.code].toLowerCase() === queryParams['product-style']
            );

            if (tId > -1) {
              const styleId = res.data[tId].id;
              setProductStyleFilters([styleId]);
            }
          }
        })
        .catch((err) => {
          setProductStyles([]);
        });

      new LoopBack()
        .get('/product-themes')
        .then((res: ProductTheme[]) => {
          const filters = extractFilterFromUrl(res, 'product-theme');
          setProductThemes(res);
          setProductThemeFilters(filters.filters);
        })
        .catch((err) => {
          setProductThemes([]);
        });

      new LoopBack()
        .get('/product-categories')
        .then((res: ProductCategory[]) => {
          setProductCategories(res);
          const categoryRestraints = getCategoryFiltersFromSettings();
          if (categoryRestraints.length > 0) {
            setProductCategoryFilters(categoryRestraints);
          } else {
            const filters = extractFilterFromUrl(res, 'product-category');
            setProductCategoryFilters(filters.filters);
          }
        })
        .catch((err) => {
          setProductCategories([]);
        });

      new LoopBack()
        .get('/product-types')
        .then((res) => {
          const filters = extractFilterFromUrl(res, 'product-type');
          setProductTypes(res);
          setProductTypeFilters(filters.filters);
        })
        .catch((err) => {
          setProductTypes([]);
        });

      new LoopBack()
        .get('/sizes')
        .then((res) => {
          setSizes(res);
        })
        .catch((err) => {
          setSizes([]);
        });

      // TODO: create seperate end point for active only products?
      let filter: any = { where: { active: true } };
      new LoopBack()
        .get(`/products?filter=${JSON.stringify(filter)}`)
        .then((res) => {
          const highestPrice = Math.max.apply(
            Math,
            res.map((product: Product) => {
              return calculatePrice(product.price, props.app.currency, props.app.country);
            })
          );
          const roundUpHighestPrice = Math.round(highestPrice / 100) * 100;
          setProducts(res);
          setHighestPrice(roundUpHighestPrice);
          setLoadingProducts(false);
          prerenderIsReady();
        })
        .catch((err) => {
          setLoadingProducts(false);
          prerenderIsReady();
        });
    }
  }, [props.section.settings]);

  const getCategoryFiltersFromSettings = () => {
    const productCategories = props.section.settings.product_categories
      ? props.section.settings.product_categories
      : [];
    return productCategories;
  };

  const extractFilterFromUrl = (
    data: { id: number; name: { [key: string]: string } }[],
    query: string
  ) => {
    let filters: { filters: any[]; subtitle: string } = { filters: [], subtitle: '' };
    const queryName = queryParams[query];

    if (queryName) {
      const filterName = queryName.split('-').join(' ');
      const filterId = data.findIndex(
        (object) =>
          object.name[settings.languages[0].code].toLowerCase() === filterName.toLowerCase()
      );

      if (filterId > -1) {
        const objectId = data[filterId].id;

        filters.filters = [objectId];
        filters.subtitle = data[filterId].name[props.app.language.code];
      } else {
        filters.filters = [-1];
        filters.subtitle = '-';

        Swal.fire({
          title: t('products:popup.occasion_not_found.title'),
          html: t('products:popup.occasion_not_found.description'),
          icon: 'warning',
          confirmButtonText: t('products:popup.occasion_not_found.ok')
        });
      }
    }
    return filters;
  };

  const areAnyFiltersSelected = () => {
    const priceRange = priceRangeFilter[1] !== -1 && priceRangeFilter[1] !== highestPrice;
    return (
      (settings.products.filter.filters.includes('product_themes') &&
        productThemeFilters.length !== 0) ||
      (settings.products.filter.filters.includes('product_types') &&
        productTypeFilters.length !== 0) ||
      (settings.products.filter.filters.includes('sizes') && sizeFilters.length !== 0) ||
      (settings.products.filter.filters.includes('colors') && colorFilters.length !== 0) ||
      (settings.products.filter.filters.includes('product_categories') &&
        productCategoryFilters.length !== 0) ||
      (settings.products.filter.filters.includes('product_styles') &&
        productStyleFilters.length !== 0) ||
      (settings.products.filter.filters.includes('product_occasions') &&
        productOccasionFilters.length !== 0) ||
      (settings.products.filter.filters.includes('price_range') && priceRange)
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSortBy('newest_first')}>
        {t('products:sorting.newest_first')}
      </Menu.Item>
      <Menu.Item onClick={() => setSortBy('low_to_high')}>
        {t('products:sorting.low_to_high')}
      </Menu.Item>
      <Menu.Item onClick={() => setSortBy('high_to_low')}>
        {t('products:sorting.high_to_low')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => setSortBy(null)}>{t('products:sorting.clear_sorting')}</Menu.Item>
    </Menu>
  );

  const truncateString = (str: string, n: number) => {
    return str.length > n ? str.substring(0, n) + '...' : str;
  };

  const formatter = (value: number | undefined) => {
    return `${props.app.currency?.symbol}${value}`;
  };

  console.log(productStyles);

  const clearFilters = () => {
    const productOccasionFilters = extractFilterFromUrl(productOccasions, 'product-occasion');
    const productStyleFilters = extractFilterFromUrl(productStyles, 'product-style');
    const productThemeFilters = extractFilterFromUrl(productThemes, 'product-theme');
    const productCategoryFilters = extractFilterFromUrl(productCategories, 'product-category');
    const productTypeFilters = extractFilterFromUrl(productTypes, 'product-type');

    setProductTypeFilters(productTypeFilters.filters);
    setProductCategoryFilters(productCategoryFilters.filters);
    setProductThemeFilters(productThemeFilters.filters);
    setProductStyleFilters(productStyleFilters.filters);
    setProductOccasionFilters(productOccasionFilters.filters);
    setColorFilters([]);
    setSizeFilters([]);
    setPriceRangeFilter([0, highestPrice]);
  };

  const getFilters = () => {
    const marks: any = {
      0: `${props.app.currency?.symbol} 0`,
      [highestPrice]: `${props.app.currency?.symbol} ${highestPrice}`
    };

    return (
      <div className={'products-filters'}>
        <div className={'products-filter'}>
          <Row gutter={[40, 40]}>
            {settings.products.filter.filters.includes('categories') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.product_categories')}
                </div>
                <Checkbox.Group
                  value={productCategoryFilters}
                  style={{ width: '100%' }}
                  onChange={(e) => setProductCategoryFilters(e)}
                >
                  <Row>
                    {productCategories.map((productCategory, pindex) => {
                      return (
                        <Col key={pindex} span={12}>
                          <Checkbox value={productCategory.id}>
                            {truncateString(productCategory.name[props.app.language.code], 30)}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
            {settings.products.filter.filters.includes('product_styles') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.product_styles')}
                </div>
                <Checkbox.Group
                  value={productStyleFilters}
                  style={{ width: '100%' }}
                  onChange={(e) => setProductStyleFilters(e)}
                >
                  <Row>
                    {productStyles.map((productStyle, pindex) => {
                      return (
                        <Col key={pindex} span={12}>
                          <Checkbox value={productStyle.id}>
                            {truncateString(productStyle.name[props.app.language.code], 30)}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
            {settings.products.filter.filters.includes('product_occasions') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.product_occasions')}
                </div>
                <Checkbox.Group
                  value={productOccasionFilters}
                  style={{ width: '100%' }}
                  onChange={(e) => setProductOccasionFilters(e)}
                >
                  <Row>
                    {productOccasions.map((productOccasion, pindex) => {
                      return (
                        <Col key={pindex} span={12}>
                          <Checkbox value={productOccasion.id}>
                            {truncateString(productOccasion.name[props.app.language.code], 30)}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
            {settings.products.filter.filters.includes('product_themes') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.product_themes')}
                </div>
                <Checkbox.Group
                  value={productThemeFilters}
                  style={{ width: '100%' }}
                  onChange={(e) => setProductThemeFilters(e)}
                >
                  <Row>
                    {productThemes.map((productTheme, pindex) => {
                      return (
                        <Col key={pindex} span={12}>
                          <Checkbox value={productTheme.id}>
                            {truncateString(productTheme.name[props.app.language.code], 30)}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
            {settings.products.filter.filters.includes('sizes') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>
                  {t('products:filters.sizes')}
                  {settings.products.filter.showUnitSwitch && (
                    <>
                      {measurementUnit === 'imperial' ? (
                        <div className={'unit-link'} onClick={() => setMeasurementUnit('metric')}>
                          {t('products:filters.show_in_cm')} <SwapOutlined />
                        </div>
                      ) : (
                        <div className={'unit-link'} onClick={() => setMeasurementUnit('imperial')}>
                          {t('products:filters.show_in_inches')} <SwapOutlined />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  value={sizeFilters}
                  onChange={(e) => setSizeFilters(e)}
                >
                  <Row>
                    {sizes.map((size) => {
                      return (
                        <Col key={size.id} xs={12} span={size.id}>
                          {measurementUnit === 'imperial' ? (
                            <Checkbox value={size.id}>{size.metric_name}</Checkbox>
                          ) : (
                            <Checkbox value={size.id}>{size.metric_name}</Checkbox>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}
          </Row>

          <Row gutter={[40, 40]}>
            {settings.products.filter.filters.includes('colors') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>{t('products:filters.colors')}</div>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  value={colorFilters}
                  onChange={(e) => setColorFilters(e)}
                >
                  <Row>
                    {colors.map((color, cindex) => {
                      return (
                        <Col key={cindex} span={12}>
                          <Checkbox value={color.id}>
                            {color.name[props.app.language.code]}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}

            {settings.products.filter.filters.includes('product_types') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>{t('products:filters.product_types')}</div>
                <Checkbox.Group
                  value={productTypeFilters}
                  style={{ width: '100%' }}
                  onChange={(e) => setProductTypeFilters(e)}
                >
                  <Row>
                    {productTypes.map((productType, pindex) => {
                      return (
                        <Col key={pindex} xs={24} sm={12}>
                          <Checkbox value={productType.id}>
                            {truncateString(
                              productType.name[props.app.language.code],
                              window.outerWidth > 992 ? 30 : 100
                            )}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Col>
            )}

            {settings.products.filter.filters.includes('sale') && (
              <Col xs={24}>
                <div className={'products-filter-label'}>{t('products:filters.sale')}</div>
                <span>Show products on sale: <Switch onChange={(e) => setShowSales(e)} disabled={filterSales} defaultChecked={filterSales} /></span>
              </Col>
            )}

            {settings.products.filter.filters.includes('price_range') && (
              <Col xs={24}>
              <div className={'products-filter-label'}>{t('products:filters.price_range')}</div>
              {highestPrice !== -1 && (
                <Slider
                  step={100}
                  tipFormatter={formatter}
                  max={highestPrice}
                  range={true}
                  onAfterChange={(e) => setPriceRangeFilter(e)}
                  marks={marks}
                  defaultValue={[0, highestPrice]}
                />
              )}
            </Col>
              )}
          </Row>
        </div>

        <Button type={'primary'} block={true} onClick={clearFilters}>
          {t('products:filters.button.clear_filters.text')}
        </Button>

        <Row>
          <Col xs={24} lg={0}>
            <Button
              style={{ marginTop: 10 }}
              block={true}
              type={'dashed'}
              onClick={() => setShowDrawer(false)}
            >
              {t('products:filters.button.hide_filters.text')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const gtagView = (listedProducts: Product[]) => {
    try {
    // @ts-ignore
    window.gtag('event', 'view_item_list', {
      items: listedProducts.map((product, index) => {
        return [
          {
            id: product.id,
            name: `${product.name[props.app.language.code]}${
              product.subtitle ? ` - ${product.subtitle[props.app.language.code]}` : ''
            }`,
            list_name: 'Webshop',
            brand: settings.appName,
            category: product.product_type_id,
            variant: product.color_id,
            list_position: index + 1,
            price: product.price
          }
        ];
      })
    });
      } catch (e) {

    }
  };

  const filterProducts = () => {
    let _filteredProducts = products;
    let minProd = products.sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));

    if (filterNewArrivals) {
      _filteredProducts = _filteredProducts.filter(
        (product) => (moment().diff(product.created, 'year') <= 1) && product.active
      );

      _filteredProducts = minProd.slice(0, 20);
    }

    if (filterSales || showSales) {
      _filteredProducts = _filteredProducts.filter(
        (product) => product.sale && product.sale > 0
      );
    }

    if (sizeFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) =>
        product.sizes.some((item) => sizeFilters.includes(item))
      );
    }

    if (colorFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) =>
        colorFilters.includes(product.color_id)
      );
    }

    if (productTypeFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) =>
        productTypeFilters.includes(product.product_type_id)
      );
    }

    if (productThemeFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) => {
        return productThemeFilters.includes(product.product_theme_id);
      });
    }

    if (productStyleFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) => {
        return productStyleFilters.includes(product.product_style_id);
      });
    }

    if (productOccasionFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) => {
        return productOccasionFilters.includes(product.product_occasion_id);
      });
    }

    if (productCategoryFilters.length > 0) {
      _filteredProducts = _filteredProducts.filter((product) => {
        return productCategoryFilters.includes(product.product_category_id);
      });
    }

    if (priceRangeFilter[1] !== -1 && priceRangeFilter[1] !== highestPrice) {
      _filteredProducts = _filteredProducts.filter((product) => {
        const calculatedPrice = calculatePrice(
          product.price,
          props.app.currency,
          props.app.country
        );
        return calculatedPrice >= priceRangeFilter[0] && calculatedPrice <= priceRangeFilter[1];
      });
    }

    if (sortBy) {
      switch (sortBy) {
        case 'newest_first':
          _filteredProducts.sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));
          break;
        case 'low_to_high':
          _filteredProducts.sort((a, b) => (a.price < b.price ? -1 : 1));
          break;
        case 'high_to_low':
          _filteredProducts.sort((a, b) => (a.price < b.price ? 1 : -1));
          break;
      }
    }

    gtagView(_filteredProducts);
    return _filteredProducts;
  };

  const filteredProducts = filterProducts();

  const getProductRows = () => {
    let cols: any = [];
    let promoCount = 0;
    let productCount = 0;
    const numOfProductsInRow = props.section.settings.products_per_row;
    let insertPromoAfterNumOfProducts = props.section.settings.products_per_row;
    const lang = props.app.language.code.toLowerCase();

    filteredProducts.forEach((product, pindex) => {
      const promo =
        props.section.settings.promos && props.section.settings.promos.length > 0
          ? props.section.settings.promos[promoCount]
          : null;
      if (productCount === insertPromoAfterNumOfProducts && promo) {
        const style = promo.image_url
          ? { backgroundImage: `url("${process.env.REACT_APP_API_URL}${promo.image_url}")` }
          : {};
        cols.push(
          <Col key={'promo-' + pindex} xs={24} lg={16} xl={numOfProductsInRow === 3 ? 16 : 12}>
            <Row
              style={style}
              className={`promo-row spacing-${props.section.settings.spacing}`}
              align={'middle'}
              justify={'center'}
            >
              <Col xs={20} sm={12}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: promo[lang].content
                  }}
                />
                {promo.button_url && promo[lang].button_text && (
                  <Link to={promo.button_url}>
                    <Button size={'large'} type={'primary'}>
                      {promo[lang].button_text} <RightOutlined />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          </Col>
        );
        if (insertPromoAfterNumOfProducts === numOfProductsInRow) {
          // 2 and 4 to alternate position,
          // 1 and 2 to outline left
          insertPromoAfterNumOfProducts += numOfProductsInRow === 3 ? 2 : 4;
        } else {
          insertPromoAfterNumOfProducts = numOfProductsInRow;
        }
        productCount = 0;
        promoCount += 1;
      }

      cols.push(
        <ProductsBoxedProduct
          spacing={props.section.settings.spacing}
          width={props.section.settings.products_per_row}
          key={pindex}
          colors={colors}
          product={product}
          index={pindex}
          measurement_unit={measurementUnit}
          productCategories={productCategories}
          productStyles={productStyles}
          productTypes={productTypes}
          sizes={sizes}
        />
      );
      productCount += 1;
    });

    return cols;
  };

  return (
    <Row className={`cms-module-webshop-boxed`}>
      <Col xs={{ span: 24 }}>
        {props.section.settings.show_categories && (
          <Row className={`products-categories-bar ${loadingProducts && ' loading'}`}>
            <Col xs={24}>
              <Space>
                {productCategories.map((category, index) => {
                  return (
                    <span>
                      <Link
                        className={`products-categories-bar-button${
                          location.pathname === category.slug && '-active'
                        }`}
                        to={category.slug}
                      >
                        {category.name['en']}
                      </Link>
                      {index !== productCategories.length - 1 && <span>&nbsp;&nbsp;|</span>}
                    </span>
                  );
                })}
              </Space>
            </Col>
          </Row>
        )}

        <Row className={'products-actions'}>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 0 }}>
            <Dropdown className={`btn-sort ${sortBy && 'active'}`} overlay={menu}>
              <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                {t('products:button.sort_by')} <CaretDownOutlined />
              </a>
            </Dropdown>
            <span className={'btn-separator'}>|</span>
            <Button
              className={`btn-filters ${areAnyFiltersSelected() && 'active'}`}
              type={'ghost'}
              onClick={() => setShowDrawer(true)}
            >
              {t('products:button.show_filters')} <CaretRightOutlined />
            </Button>
          </Col>
          <Col
            xs={{ span: 24, order: 0 }}
            sm={{ span: 12, order: 1 }}
            style={{ textAlign: 'right' }}
          >
            <Link className={'btn-go-to-cart'} to={'/cart'}>
              {t('products:button.go_to_cart')} <ArrowRight />
            </Link>
            <Drawer
              title={t('products:filters.title')}
              placement='right'
              closable={true}
              onClose={() => setShowDrawer(false)}
              open={showDrawer}
            >
              {getFilters()}
            </Drawer>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24, offset: 0 }}>
            {!loadingProducts && filteredProducts.length > 0 ? (
              <Row>{getProductRows()}</Row>
            ) : (
              <Row>
                {loadingProducts ? (
                  <span>
                    <Spin spinning={true} /> {t('products:loading_pages')}
                  </span>
                ) : (
                  <span>{t('products:no_products_found')}</span>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth,
  wishlist: state.wishlist,
  cart: state.cart
});

export default connect(mapStateToProps)(WebshopBoxedModule);
