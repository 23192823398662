import {
  ADD_CART_ITEM,
  ADD_EVENT_TO_CART,
  ADD_GIFT_CARD_TO_CART,
  CLEAR_CART,
  CLEAR_VOUCHER,
  ENABLE_GIFT_CARD_RECEIVER_EMAIL,
  REMOVE_CART_ITEM,
  REMOVE_EVENT_FROM_CART,
  REMOVE_GIFT_CARD_FROM_CART,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_GIFT_CARD_RECEIVER_EMAIL,
  VOUCHER_ERROR,
  VOUCHER_RECEIVE,
  VOUCHER_REQUEST
} from '../types';
import { CartItem, Event, GiftCard, Product, Setting } from '../../types';
import { LoopBack } from '../api';
import Swal from 'sweetalert2';
import { Dispatch } from 'react';
import { TFunction } from 'i18next';

export const checkVoucher = (voucherCode: string, t: TFunction, showPopup?: boolean) => {
  return (dispatch: Dispatch<object>) => {
    dispatch(voucherRequest());
    new LoopBack()
      .post('/vouchers/check', { code: voucherCode })
      .then((res) => {
        if (res.error) {
          dispatch(voucherError());
          if (showPopup) {
            Swal.fire({
              title: t('cart:voucher.popup.check.error.title'),
              html: t('cart:voucher.popup.check.error.description'),
              icon: 'error'
            });
          }
        } else {
          dispatch(voucherReceive(res));
          if (showPopup) {
            if (res.exists) {
              Swal.fire({
                title: t('cart:voucher.popup.check.success.title'),
                html: t('cart:voucher.popup.check.success.description'),
                icon: 'success'
              });
            } else {
              Swal.fire({
                title: t('cart:voucher.popup.check.warning.title'),
                html: t('cart:voucher.popup.check.warning.description', {
                  text: `<strong>${voucherCode}</strong>`
                }),
                icon: 'warning'
              });
            }
          }
        }
      })
      .catch((err) => {
        dispatch(voucherError());
        Swal.fire({
          title: t('cart:voucher.popup.check.error.title'),
          html: t('cart:voucher.popup.check.error.description'),
          icon: 'error'
        });
      });
  };
};

export const emptyCart = () => ({
  type: CLEAR_CART
});

export const addEventToCart = (
  event: Event,
  quantity: number,
  sessions?: object[],
  extraAmount?: number
) => ({
  type: ADD_EVENT_TO_CART,
  payload: {
    id: event.id.toString(),
    event: {
      id: event.id,
      name: event.name,
      event_type: event.location,
      price: event.price,
      slug: event.slug,
      image: event.pictures ? event.pictures[0] : null
    },
    quantity: quantity,
    // extra_amount: extraAmount? extraAmount: 0
    sessions: sessions,
    extra_amount: 0
  }
});

export const addGiftCardToCart = (
  giftCard: GiftCard,
  quantity: number,
  customReceiver: boolean,
  customText: string
) => ({
  type: ADD_GIFT_CARD_TO_CART,
  payload: {
    id: giftCard.id.toString(),
    giftCard: {
      id: giftCard.id,
      name: giftCard.name,
      price: giftCard.price,
      slug: giftCard.slug,
      image: giftCard.image
    },
    custom_receiver: customReceiver,
    custom_text: customText,
    quantity: quantity
  }
});

export const removeGiftCardFromCart = (id: string) => ({
  type: REMOVE_GIFT_CARD_FROM_CART,
  payload: id
});

export const updateGiftCardReceiverEmail = (id: string, value: string) => ({
  type: UPDATE_GIFT_CARD_RECEIVER_EMAIL,
  payload: {
    id: id,
    value: value
  }
});

export const enableGiftCardReceiverEmail = (id: string, value: boolean) => ({
  type: ENABLE_GIFT_CARD_RECEIVER_EMAIL,
  payload: { id: id, value: value }
});

export const removeEventFromCart = (id: string) => ({
  type: REMOVE_EVENT_FROM_CART,
  payload: id
});

export const addItemToCart = (
  product: Product,
  size: { id: number; price: number; name: string },
  quantity: number,
  custom_text: string | null,
  thread_color: string | null,
  color_name: { [key: string]: string } | null,
) => ({
  type: ADD_CART_ITEM,
  payload: {
    id: `${product.id}-${size.id}${custom_text ? `-${custom_text}` : ''}`,
    product: {
      id: product.id,
      name: product.name,
      product_type: product.product_type_id,
      color_id: product.color_id,
      color_name: color_name,
      price: product.price,
      slug: product.slug,
      subtitle: product.subtitle,
      image: product.pictures ? product.pictures[0] : null,
      sale: product.sale,
    },
    size: size,
    quantity: quantity,
    custom_text: custom_text,
    thread_color: thread_color
  }
});

export const updateCartItemQuantity = (quantity: number, item: CartItem) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  payload: { quantity: quantity, item: item }
});

export const removeItemFromCart = (id: string) => ({
  type: REMOVE_CART_ITEM,
  payload: id
});

const voucherRequest = () => ({
  type: VOUCHER_REQUEST
});

const voucherReceive = (data: Setting) => ({
  type: VOUCHER_RECEIVE,
  payload: data
});

const voucherError = () => ({
  type: VOUCHER_ERROR
});

export const clearVoucher = () => ({
  type: CLEAR_VOUCHER
});
