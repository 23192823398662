import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { PageSection } from '../../../types';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../styles/modules/text-with-picture-block.less';

export const TextWithPictureBlockModule = (section: PageSection) => {
  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-text-with-picture-block'}
    >
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }}>
        <div className={'text-with-picture-block'}>
          {section.settings.type === 'right_to_left' ? (
            <Row>
              <Col
                xs={{ span: 24, order: 0 }}
                xl={{ span: 14, order: 0, offset: 10 }}
                className={'text-with-picture-block-description'}
              >
                <h2>{section.settings.title[section.language]}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section.settings.description[section.language]
                  }}
                />
                <Link to={section.settings.button.url}>
                  <Button type={'primary'} style={{ float: 'right' }}>
                    {section.settings.button.text[section.language]} <ArrowRightOutlined />
                  </Button>
                </Link>
              </Col>
              <Col xs={{ span: 24, order: 1 }} xl={{ span: 14, order: 1, offset: 0 }}>
                <img src={process.env.REACT_APP_API_URL + section.settings.image.url} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                xs={{ span: 24, order: 0 }}
                xl={{ span: 14, order: 0, offset: 0 }}
                className={'text-with-picture-block-description'}
              >
                <h2>{section.settings.title[section.language]}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section.settings.description[section.language]
                  }}
                />
                <Link to={section.settings.button.url}>
                  <Button type={'primary'}>
                    {section.settings.button.text[section.language]} <ArrowRightOutlined />
                  </Button>
                </Link>
              </Col>
              <Col xs={{ span: 24, order: 1 }} xl={{ span: 14, order: 1, offset: 10 }}>
                <img src={process.env.REACT_APP_API_URL + section.settings.image.url} />
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};
