import { Country, Currency } from '../types';
import { settings } from '../../settings';

export const calculatePrice = (
  price: number,
  currency: Currency | null,
  country: Country | null,
  preventRounding: boolean = false,
) => {
  if (currency) {
    price = country ? price * (1 + country.tax_rate / 100) * currency.rate : price * currency.rate;
  }
  if (settings.roundNonBaseFiatCurries && currency?.symbol !== process.env.REACT_APP_CURRENCY_CODE && !preventRounding) {
    return Math.ceil(price)
  }
  return price;
};

export const formatPrice = (price: number, currency: Currency | null, country: Country | null, sale? : number, preventRounding: boolean = false) => {
  if (currency) {
    price = sale ? price * (1 - (sale / 100)) : price;
    price = calculatePrice(price, currency, country, preventRounding);
    return `${currency.symbol} ${price.toFixed(2).replace('.', ',')}`;
  }
  return '-';
};
