import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Lookbook, PageSection } from '../../../types';
import moment from 'moment';
import { LoopBack } from '../../../redux/api';
import '../../../styles/modules/lookbooks.less';
import { Link } from 'react-router-dom';
import { settings } from '../../../../settings';
import SkeletonComponent from '../../app/skeleton.component';

export const LookbooksModule = (section: PageSection) => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState('campaigns');
  const [lookbooks, setLookbooks] = useState<Lookbook[]>([]);

  useEffect(() => {
    const filter = { include: [{ relation: 'lookbook_sections' }] };

    new LoopBack()
      .get(`/lookbooks?filter=${JSON.stringify(filter)}`)
      .then((res) => {
        setLookbooks(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const lookbooksBySelectedCategory = lookbooks.filter((_lookbook) => _lookbook.category === category);

  return (
    <Row
      id={section.uid ? section.uid : undefined}
      key={section.id}
      className={'cms-module-lookbooks'}
    >
      <Col xs={{ span: 24 }}>
        <Row className={'lookbook-menu'} justify={'center'}>
          <div
            onClick={() => setCategory('campaigns')}
            className={`lookbook-year ${category === 'campaigns' && 'lookbook-year-active'}`}
          >
            Campaigns
          </div>
          <div
            onClick={() => setCategory('classics')}
            className={`lookbook-year ${category === 'classics' && 'lookbook-year-active'}`}
          >
            Classics
          </div>
          <div
            onClick={() => setCategory('fashion_shows')}
            className={`lookbook-year ${category === 'fashion_shows' && 'lookbook-year-active'}`}
          >
            Fashion Shows
          </div>
          {/*<div*/}
          {/*  onClick={() => setYear(2023)}*/}
          {/*  className={`lookbook-year ${year === 2023 && 'lookbook-year-active'}`}*/}
          {/*>*/}
          {/*  2023*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  onClick={() => setYear(2022)}*/}
          {/*  className={`lookbook-year ${year === 2022 && 'lookbook-year-active'}`}*/}
          {/*>*/}
          {/*  2022*/}
          {/*</div>*/}
        </Row>
        {loading ? (
          <SkeletonComponent />
        ) : lookbooksBySelectedCategory.length > 0 ? (
          <Row className={'lookbook-row'}>
            <Col xs={24}>
              {lookbooksBySelectedCategory.map((lookbook, index) => {
                return (
                  <Row key={index} className={'lookbook-single-row'} justify={'center'}>
                    <Col xs={24} lg={18}>
                      {process.env.REACT_APP_API_URL && (
                        <img src={process.env.REACT_APP_API_URL + lookbook.image} />
                      )}
                      <Row style={{ height: '100%' }} align={'middle'}>
                        <Col xs={24}>
                          <div className={'lookbook-single-row-title'}>
                            <h2>{lookbook.name['en']}</h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: lookbook.description['en']
                              }}
                            />
                            <Link to={settings.lookbook.rootUrl + lookbook.slug}>
                              <Button type={'primary'}>View Collection</Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={24}>
              <div>No lookbook found for this year</div>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
