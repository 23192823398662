import { Anchor, Button, Carousel, Col, Row } from 'antd';
import React, {useState} from 'react';
import { PageSection } from '../../../types';
import { Link } from 'react-router-dom';
import {ArrowRightOutlined, RightOutlined} from '@ant-design/icons';
import '../../../styles/modules/slider.less';
import {useWindowWidth} from "../../../helpers/use-window-width.helper";
import { Component } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


interface SliderBlock {
  image: { url: string };
  title: { [key: string]: string };
  button: { text: { [key: string]: string }; url: string };
  subtitle: { [key: string]: string };
  language: string;
}

export const SliderModule = (section: PageSection, ) => {
    const windowWidth = useWindowWidth();
    const [imageModal, setImageModal] = useState<string | null>(null);

    const getCarousel = () => {
        return (
            <Carousel
                infinite={true}
                dots={false}
                centerPadding={'10'}
                swipe={true}
                arrows={true}
                responsive={[
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: section.settings.images_per_slide ? section.settings.images_per_slide > 3 ? 3 : section.settings.images_per_slide : 1
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: section.settings.images_per_slide ? section.settings.images_per_slide > 2 ? 2 : section.settings.images_per_slide : 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: section.settings.images_per_slide ? section.settings.images_per_slide > 1 ? 1 : section.settings.images_per_slide : 1
                        }
                    }
                ]}
                slidesPerRow={windowWidth < 1200 ? 1 : section.settings.images_per_slide ? section.settings.images_per_slide : 1}
                variableWidth={windowWidth < 1200 ? false : section.settings.variable_width} autoplay={true}
                adaptiveHeight={false}
                autoplaySpeed={5000}>
                {section.settings.images.map((slide: SliderBlock, index: number) => {

                    return (
                        <div key={index} className={'slider-slide'}>


                            <img
                                onClick={() => (section.settings.zoom_on_click && windowWidth < 1024) ? setImageModal(slide.image.url) : () => void(0)}
                                alt={`${slide.title[section.language]}`}
                                src={process.env.REACT_APP_API_URL + slide.image.url}
                            />
                            <div className={'slider-content'}>
                                <Row justify={'center'} align={'middle'}>
                                    <Col xs={24} lg={8} className={'slider-title'}>
                                        <h2>{slide.title[section.language]}</h2>
                                    </Col>
                                    <Col xs={12} lg={8} className={'slider-subtitle'}>
                                        {slide.subtitle &&
                                            <p>{slide.subtitle[section.language]}</p>
                                        }
                                    </Col>
                                    {slide.button && slide.button.url &&
                                        <Col xs={12} lg={8} className={'slider-button'}>
                                            {slide.button && slide.button.url && slide.button.url.includes('#') ? (
                                                <Anchor affix={false} offsetTop={50}>
                                                    <Anchor.Link
                                                        href={slide.button.url}
                                                        title={
                                                            <span>
                                {slide.button.text[section.language]} <ArrowRightOutlined/>
                              </span>
                                                        }
                                                    />
                                                </Anchor>
                                            ) : (
                                                <Link to={slide.button.url} title={slide.button.text[section.language]}>
                                                    <Button type={'primary'}>
                                                        {slide.button.text[section.language]} <ArrowRightOutlined/>
                                                    </Button>
                                                </Link>
                                            )}
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        )
    }

    return (
        section.settings.reverse_columns ?
            <Row
                id={section.uid ? section.uid : undefined}
                key={section.id}
                className={'cms-module-slider'}
            >
                {section.settings.content_left ?
                    <Col xs={24}>
                        <Row>
                            <Col xs={{span: 24, order: 1}}
                                 lg={{span: section.settings.content_main_position === 'top' ? 24 : 12, order: 0}}>
                                {getCarousel()}

                                {section.settings.content_right &&
                                    <div dangerouslySetInnerHTML={{__html: section.settings.content_right}}/>
                                }
                            </Col>
                            <Col xs={{span: 24, order: 0}}
                                 lg={{span: section.settings.content_main_position === 'top' ? 24 : 12, order: 1}}>
                                <div dangerouslySetInnerHTML={{__html: section.settings.content_left}}/>
                            </Col>

                        </Row>
                    </Col>
                    :
                    <Col xs={24} lg={{span: 18, offset: 3}}>
                        {getCarousel()}
                    </Col>

                }

                {section.settings.button && section.settings.button.text && section.settings.button.url &&

                    <Col xs={24} className={'button-row'}>
                        <Link to={section.settings.button.url}>
                            <Button size={'large'} type={'primary'}>{section.settings.button.text[section.language]}
                                <RightOutlined/></Button>
                        </Link>
                    </Col>

                }
            </Row>
            :
            <Row
                id={section.uid ? section.uid : undefined}
                key={section.id}
                className={'cms-module-slider'}
            >
                {section.settings.content_left ?
                    <Col xs={24}>
                        <Row>
                            <Col xs={24} lg={section.settings.content_main_position === 'top' ? 24 : 12}>
                                <div dangerouslySetInnerHTML={{__html: section.settings.content_left}}/>
                            </Col>
                            <Col xs={24} lg={section.settings.content_main_position === 'top' ? 24 : 12}>
                                {getCarousel()}

                                {section.settings.content_right && section.settings.content_right.trim() !== '' &&
                                    <div dangerouslySetInnerHTML={{__html: section.settings.content_right}}/>
                                }
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col xs={24} lg={{span: 18, offset: 3}}>
                        {getCarousel()}
                    </Col>
                }

                {section.settings.button && section.settings.button.text && section.settings.button.url &&
                    <Row>
                        <Col xs={24} className={'button-row'}>
                            <Link to={section.settings.button.url}>
                                <Button size={'large'} type={'primary'}>{section.settings.button.text[section.language]}
                                    <RightOutlined/></Button>
                            </Link>
                        </Col>
                    </Row>
                }

                {imageModal &&
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        zIndex: 9999
                    }}>
                        <Button onClick={() => setImageModal(null)} style={{margin: 10}}>Close</Button>
                        <TransformWrapper centerOnInit={true} >
                            <TransformComponent contentStyle={{height: '100%'}}  wrapperStyle={{height: '100%'}}>
                                <img
                                    style={{width: '100%', height: '100%',objectFit: "contain",}}
                                    src={process.env.REACT_APP_API_URL + imageModal}/>
                            </TransformComponent>
                        </TransformWrapper>

                    </div>
                }
            </Row>
    );
};
